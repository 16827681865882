import React from 'react';
import { ModalContainer } from '@seekube-tech/ui-kit';
import styles from './styles.less';
import { LeftContent } from '@/components/ModalFullSplitedView/LeftContent';
import { RightContent } from '@/components/ModalFullSplitedView/RightContent';
import { bool, func, object } from 'prop-types';

const ModalFull = ({
  visible,
  handleOnClose,
  children
}) => {
  return (
    <ModalContainer
      full
      isClosable
      visible={visible}
      onClose={handleOnClose}
      title={<div></div>}
    >
      <div className={styles.content}>
        {children}
      </div>
    </ModalContainer>
  )
}

ModalFull.propTypes = {
  visible: bool,
  handleOnClose: func,
  children: object
}

const ModalFullSplitedView = Object.assign(ModalFull, { LeftContent, RightContent });

export default ModalFullSplitedView;

