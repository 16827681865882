import React from 'react';
import { Button, Caption1, CardInfo, ModalContainer, Subtitle2 } from '@seekube-tech/ui-kit';
import styles from './styles.less';
import { useFormatMessage } from 'react-intl-hooks';

const AtsEmptySelectionContent = ({
  onClickAction,
  isLoading
}) => {
  const t = useFormatMessage();
  
  return (
    <div className="w-full">
      <CardInfo
        isClosable={false}
      >
        <div className="w-full flex items-center justify-between gap-24">
          <div className={styles.descriptionContainer}>
            <Subtitle2 fontWeight={800} className={styles.title}>{t({ id: 'ats.title.import' })}</Subtitle2>
            <Caption1 className={styles.subtitle}>{t({ id: 'ats.subtitle.prefill' })}</Caption1>
          </div>
          <Button 
            onClick={onClickAction}
            loading={isLoading}
            disabled={isLoading}
          >
            {t({ id: 'ats.actions.chooseOffer' })}
          </Button>
        </div>
      </CardInfo>
    </div>
  )
}

export { AtsEmptySelectionContent }