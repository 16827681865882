// In edit mode, map locations that are not google objects
export const getSelectedLocation = (locations) => {
  const optionsToAdd = [];
  
  (locations || []).forEach(({ _id, name, placeId, label }) => {
    if (_id ?? placeId) {
      optionsToAdd.push({
        label: name ?? label,
        value: _id ?? placeId,
        placeId,
        _id
      })
    }
  });
  
  return optionsToAdd;
}