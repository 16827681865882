import React from 'react';
import { injectIntl } from 'react-intl';
import { any, string, bool, func, object, oneOfType, number } from 'prop-types';
import classNames from 'classnames';
import { isEmail } from 'validator';
import omit from 'lodash/omit';
import { isEmpty } from 'lodash';
import uuidv1 from 'uuid/v1';
import { Alert } from '@seekube-tech/ui-kit';

import { AutoComplete } from 'antd';
import { Input } from '@/components/Input';
import Icon from '@/components/Icon';
import styles from '@/components/Form/styles.less';

import { Mandatory } from '@/components/Form';
import messages from '../messages';

class InputMaterial extends React.PureComponent {
  static propTypes = {
    value: string,
    errorMsg: string,
    placeholder: any,
    label: oneOfType([string, object]),
    pattern: any,
    type: string,
    id: string.isRequired,
    disabled: bool,
    validations: any,
    addOnIcon: any,
    withInputErrorStyle: bool,
    applyFormatValue: func,
    hasError: bool,
    required: bool,
    isFetching: bool,
    createAlert: bool,
    showHelper: func,
    getError: bool,
    onChange: func,
    dataSource: any,
    rightContent: any,
    filterOption: func,
    intl: object,
    max: number,
    className: string
  };

  static defaultProps = {
    disabled: false,
    required: false,
    createAlert: false,
    id: 'text-box',
    placeholder: 'name',
    autoComplete: '',
    addOnIcon: null,
    rightContent: null,
    withInputErrorStyle: true,
    validAutocompleteCreateItem: true,
  };

  static getDerivedStateFromProps(props) {
    if (props.value) {
      return { hasValue: !isEmpty(props.value) };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentValue: props.value,
      hasValue: !isEmpty(props.value),
      hasFocus: false,
    };

    this.idContainer = uuidv1();
  }

  componentDidMount() {
    const {
      props: { value },
      handleValidation,
    } = this;

    if (!isEmpty(value)) {
      handleValidation(value);
    }
  }

  onFocus = (event) => {
    const {
      props: { validations },
      handleValidation,
      getHelper,
    } = this;

    const value = typeof event === 'object' ? event.currentTarget.value : event;
    this.setState({ hasFocus: true });

    if (validations !== undefined) {
      handleValidation(value);
    }

    setTimeout(() => getHelper(), 20);
  };

  onBlur = (event) => {
    const {
      props: { validations, showHelper },
      handleValidation,
    } = this;

    const value = typeof event === 'object' ? event.currentTarget.value : event;

    if (showHelper) {
      showHelper(null);
    }

    this.setState({ hasValue: Boolean(value), hasFocus: false });

    if (validations !== undefined) {
      handleValidation(value);
    }
  };

  onChange = (event) => {
    const {
      props: { validations, onChange, type },
      handleValidation,
    } = this;

    const value = typeof event === 'string' ? event : event.currentTarget.value;
    const newState = {
      hasValue: !isEmpty(value),
      currentValue: value,
    };

    if (type === 'autocomplete') { // Todo: remove because hack due to onFocus autocomplete bug on antdesign
      newState.hasFocus = true;
    }

    this.setState(newState);

    if (validations !== undefined) {
      handleValidation(value);
    }

    if (onChange) {
      onChange(event);
    }
  };

  getHelper = () => {
    const {
      props: { id, showHelper },
    } = this;

    if (showHelper) {
      showHelper(id);
    }
  };

  handleValidation = (value) => {
    const { props: { validations } } = this;
    const validationsArray = isEmpty(validations) ? [] : validations;

    let countErrors = 0;

    validationsArray.forEach((item) => {
      switch (item) { // eslint-disable-line
        case 'required':
          if (!value || !value.toString().trim().length) {
            countErrors += 1;
          }

          break;

        case 'email':
          if (!isEmail(value)) {
            countErrors += 1;
          }

          break;
      }
    });

    if (countErrors) {
      this.setState({ validationStatus: 'error' });
    } else {
      this.setState({ validationStatus: 'success' });
    }
  }

  render() {
    const {
      props: {
        addOnIcon, errorMsg, id, disabled, pattern, placeholder, dataSource, required, type, label, validations,
        withInputErrorStyle, applyFormatValue, hasError, getError, filterOption, value, isFetching, createAlert, intl, max
      },
      state: { currentValue, hasValue, validationStatus, hasFocus },
      onFocus, onBlur, onChange,
    } = this;

    const inputClasses = classNames(
      styles['fl-input'],
      hasValue && !hasError ? styles['fl-valid'] : null,
      hasValue && hasError ? styles['fl-invalid'] : null,
      isEmpty(label) ? styles.noLabel : '',
    );

    const inputContainerClasses = classNames(
      styles[validationStatus],
      styles['fl-input-container'],
      disabled ? styles['fl-disabled'] : null,
      disabled ? 'disabled' : null,
      hasFocus ? styles.hasFocus : null,
      validations !== undefined ? styles.withValidation : null,
      'input-material-wrapper',
    );

    const addOnContainerClasses = classNames(
      styles.addOn,
      hasFocus ? styles.hasFocus : null,
      hasValue ? styles.hasValue : null,
      'input-material-wrapper',
      !withInputErrorStyle ? 'no-error-style' : null,
    );

    const errMsgClasses = classNames(errorMsg ? styles['fl-error-msg'] : null, (hasError && hasValue) && (errorMsg && pattern) ? styles['fl-error-show'] : null);
    const omittedProps = ['addOnIcon', 'size', 'onChange', 'createAlert', 'withInputErrorStyle', 'isFetching', 'dataSource', 'validAutocompleteCreateItem', 'applyFormatValue', 'showHelper', 'getError', 'rightContent'];
    const valueFormatted = typeof applyFormatValue === 'function' ? { value: applyFormatValue(value) } : null;
    const input = type === 'autocomplete' ? (
      <>
        <AutoComplete
          dataSource={dataSource || []}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onClick={onFocus}
          onBlur={onBlur}
          filterOption={filterOption}
          dropdownStyle={{ left: 0, top: 0 }}
          getPopupContainer={() => document.getElementById(`dropdownAutocomplete${this.idContainer}`)}
          backfill
          {...omit(this.props, omittedProps)}
        />
      </>
    ) : (
      <Input
        maxLength={max}
        className={inputClasses}
        disabled={disabled}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        type={type}
        {...omit({ ...this.props, ...valueFormatted }, omittedProps)}
      />
    );

    return (
      <div className={this.props.className}>
        <div className={addOnContainerClasses} id={`dropdownAutocomplete${this.idContainer}`}>
          <span className="ant-input-group-wrapper">
            <span
              className={
                classNames(
                  'ant-input-wrapper',
                  'ant-input-group',
                  disabled ? 'disabled' : null,
                  hasError && withInputErrorStyle ? styles.inputErrorStyle : null
                )
              }
            >
              {addOnIcon && (
                typeof addOnIcon === 'string' ?
                  (
                    <span className="ant-input-group-addon">
                      <Icon name={addOnIcon} className={styles.inputIcon} />
                    </span>
                  )
                  : <span className="ant-input-group-addon">{addOnIcon}</span>
              )
              }

              <span className="ant-input-affix-wrapper">
                <div className={inputContainerClasses}>
                  {input}
                  {type !== 'autocomplete' ? (
                    <label className={classNames(styles['fl-input-label'], getError ? styles['fl-input-label-error'] : '')} htmlFor={id}>
                      <Mandatory visible={(validations && validations.includes('required')) || required}>
                        {label}
                      </Mandatory>
                    </label>
                  ) : ''}
                  {errorMsg && <span className={styles[errMsgClasses]}>{errorMsg}</span>}
                </div>
              </span>
              {
                !isEmpty(this.props.rightContent) ?
                  <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', right: '20px', height: '100%' }}>
                    {this.props.rightContent}
                  </div> : null
              }
            </span>
          </span>
        </div>
        {!isFetching && createAlert && !isEmpty(currentValue) && currentValue.length > 2 && hasFocus && type === 'autocomplete' && isEmpty(dataSource) && (
          <div className="dropdown-autocomplete-create-item">
            <Alert isClosable={false} color="info">
              {intl.formatMessage(messages.inputMaterialAlertMessage)}
            </Alert>
            <div className="dropdown-autocomplete-item">{`${intl.formatMessage(messages.add)} "${value}"`}</div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(InputMaterial);
