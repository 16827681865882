import React from 'react';
import styles from './styles.less';
import { Button } from '@seekube-tech/ui-kit';
import { bool, func } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

const Footer = ({
  onCancel,
  onSubmit,
  onRemove,
  canSubmit = false,
  canRemove = false,
}) => {
  const t = useFormatMessage();
  
  return (
    <div className={styles.footerSection}>
      <Button
        onClick={onRemove}
        disabled={!canRemove}
        variant="outline"
        color="error"
      >
        {t({ id: 'ats.removeLink' })}
      </Button>
      
      <div className={styles.rightActions}>
        <Button
          onClick={onCancel}
          variant="tonal"
        >
          {t({ id: 'cancel' })}
        </Button>
        <Button
          onClick={onSubmit}
          disabled={!canSubmit}
        >
          {t({ id: 'ats.modal.actions.submit' })}
        </Button>
      </div>
    </div>
  )
}

Footer.propTypes = {
  onCancel: func,
  onSubmit: func,
  canSubmit: bool
}

export {  Footer };