import React from 'react';
import { bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { percentage } from '@/utils/math';

import { Stats as ComponentStats, WithPercentage } from '@/components/Stats';
import { If } from '@/components/If';

import commonMessages from '@/messages';

import messages from './messages';
import styles from './styles.less';


const getPageStats = (stats, goals = {}) => ({
  // REGISTRATIONS
  countParticipants: {
    title: <FormattedMessage id="event.owner.statistics.stats.candidate.candidates.title" />,
    count: stats.countParticipants,
    additionalText: (
      <If condition={goals.totalParticipants}>
        <WithPercentage
          tooltip={<FormattedMessage {...messages.registrationPercentageTooltip} />}
          value={percentage(stats.countParticipants, goals.totalParticipants, 0, true)}
        />
      </If>
    ),
  },
  countParticipantsCoreTarget: {
    title: <FormattedMessage id="event.owner.statistics.stats.candidate.countParticipantsCoreTarget.title" />,
    count: stats.countParticipantsCoreTarget,
    tooltip: <FormattedMessage id="event.owner.statistics.stats.candidate.countParticipantsCoreTarget.count-tooltip" />,
    additionalText: (
      <If condition={stats.countParticipants}>
        <WithPercentage
          tooltip={<FormattedMessage id="event.owner.statistics.stats.candidate.countParticipantsCoreTarget.percentage-tooltip" />}
          value={percentage(stats.countParticipantsCoreTarget, stats.countParticipants, 0, true)}
        />
      </If>
    ),
  },
  // APPLICATIONS
  allAppointments: {
    title: <FormattedMessage {...commonMessages.total} />,
    count: stats.allAppointments,
  },
  apply: {
    title: <FormattedMessage {...messages.applicationApplyTitle} />,
    tooltip: <FormattedMessage {...messages.applicationApplyTooltip} />,
    count: stats.apply,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.applicationApplyPercentageTooltip} />} value={percentage(stats.apply, stats.countParticipants)} />,
  },
  averageApplications: {
    title: <FormattedMessage {...commonMessages.average} />,
    tooltip: <FormattedMessage {...messages.applicationAverageTooltip} />,
    count: percentage(stats.allAppointments, stats.apply * 100, 1),
  },
  // INTERVIEWS
  totalPropositions: {
    title: <FormattedMessage {...commonMessages.total} />,
    count: stats.sourcingAppointments + stats.acceptedApplications,
  },
  sourcingAppointments: {
    title: <FormattedMessage {...messages.propositionSourcingAppointmentsTitle} />,
    tooltip: <FormattedMessage {...messages.propositionSourcingAppointmentsTooltip} />,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.propositionSourcingAppointmentsPercentageTooltip} />} value={percentage(stats.countParticipantsWithProposition, stats.countParticipants)} />,
    count: stats.countParticipantsWithProposition,
  },
  sourcingAppointmentsCoreTarget: {
    title: <FormattedMessage id="event.owner.statistics.stats.candidate.sourcingAppointmentsCoreTarget.title" />,
    tooltip: <FormattedMessage id="event.owner.statistics.stats.candidate.sourcingAppointmentsCoreTarget.count-tooltip" />,
    count: stats.sourcingAppointmentsCoreTarget,
    additionalText: (
      <If condition={stats.sourcingAppointments}>
        <WithPercentage
          tooltip={<FormattedMessage id="event.owner.statistics.stats.candidate.sourcingAppointmentsCoreTarget.percentage-tooltip" />}
          value={percentage(stats.sourcingAppointmentsCoreTarget, stats.sourcingAppointments, 0, true)}
        />
      </If>
    ),
  },
  averagePropositions: {
    title: <FormattedMessage {...commonMessages.average} />,
    tooltip: <FormattedMessage {...messages.propositionAverageTooltip} />,
    count: percentage(stats.sourcingAppointments + stats.acceptedApplications, stats.sourcingAppointments * 100, 1),
  },
  // VALID APPOINTMENTS
  totalAccepted: {
    title: <FormattedMessage {...commonMessages.total} />,
    count: stats.totalAccepted,
  },
  candidateAccepted: {
    title: <FormattedMessage {...messages.candidateInterviewTitle} />,
    tooltip: <FormattedMessage {...messages.candidateInterviewTooltip} />,
    count: stats.candidateAccepted,
    additionalText: <WithPercentage tooltip={<FormattedMessage {...messages.candidateInterviewPercentageTooltip} />} value={percentage(stats.candidateAccepted, stats.countParticipants, 0, true)} />,
  },
  candidateCoreTarget: {
    title: <FormattedMessage id="event.owner.statistics.stats.candidate.candidateCoreTarget.title" />,
    tooltip: <FormattedMessage id="event.owner.statistics.stats.candidate.candidateCoreTarget.count-tooltip" />,
    count: stats.candidateCoreTarget,
    additionalText: (
      <If condition={stats.candidateAccepted}>
        <WithPercentage
          tooltip={<FormattedMessage id="event.owner.statistics.stats.candidate.candidateCoreTarget.percentage-tooltip" />}
          value={percentage(stats.candidateCoreTarget, stats.candidateAccepted, 0, true)}
        />
      </If>
    ),
  },
  averageParticipantsAppointments: {
    title: <FormattedMessage {...commonMessages.average} />,
    tooltip: <FormattedMessage {...messages.candidateInterviewAverage} />,
    count: percentage(stats.totalAccepted, stats.candidateAccepted * 100, 1),
  },
});

function Stats({ stats = {}, event: { skipAppointmentValidation, goals, enableCoreTarget } }) {
  /* Vars */

  const pageStats = getPageStats(stats, goals);

  /* Functions */

  const getRegistrationsStats = () => {
    const list = [pageStats.countParticipants];

    if (enableCoreTarget) {
      list.push(pageStats.countParticipantsCoreTarget);
    }

    return list;
  };

  // eslint-disable-next-line arrow-body-style
  const getApplicationsStats = () => {
    return [pageStats.allAppointments, pageStats.apply, pageStats.averageApplications];
  };

  const getInterviewsStats = () => {
    const list = [pageStats.sourcingAppointments];

    if (enableCoreTarget) {
      list.push(pageStats.sourcingAppointmentsCoreTarget);
    }

    return list;
  };

  const getValidAppointmentsStats = () => {
    const list = [pageStats.candidateAccepted];

    if (enableCoreTarget) {
      list.push(pageStats.candidateCoreTarget);
    }

    list.push(pageStats.averageParticipantsAppointments);

    return list;
  };

  /* Render */

  return (
    <div className={styles.participantsStats}>
      <div>
        <FormattedMessage {...messages.registrationTitle} tagName="h2" />
        <ComponentStats
          list={getRegistrationsStats()}
          containerClass={styles.noMarginBottom}
        />
      </div>
      <div>
        <FormattedMessage {...messages.applicationTitle} tagName="h2" />
        <ComponentStats
          list={getApplicationsStats()}
          containerClass={styles.noMarginBottom}
        />
      </div>

      <If condition={!skipAppointmentValidation}>

        <div>
          <FormattedMessage {...messages.propositionTitle} tagName="h2" />
          <ComponentStats
            list={getInterviewsStats()}
            containerClass={styles.noMarginBottom}
          />
        </div>
      </If>

      <If condition={skipAppointmentValidation}>
        <div>
          <FormattedMessage {...messages.interviewTitle} tagName="h2" />
          <ComponentStats
            list={getValidAppointmentsStats()}
            containerClass={styles.noMarginBottom}
          />
        </div>
      </If>
    </div>
  );
}

Stats.propTypes = {
  stats: object,
  event: object,
  enableCoreTarget: bool,
};

Stats.defaultProps = {
  enableCoreTarget: false,
};

export default React.memo(Stats);
