import styles from '@/scenes/Client/scenes/EditOffer/styles.less';
import { FormattedMessage } from 'react-intl';
import messages from '@/scenes/Client/scenes/EditOffer/components/EditOfferStep1/messages';
import React, { useState } from 'react';
import Ats from '@/features/ats/components/Ats';
import { Form } from 'react-final-form';
import { FormTextField } from '@/components/FinalFormFields/FormTextField';
import { Button, Divider, IconFileText } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { isEmpty } from 'lodash';
import { func, object } from 'prop-types';
import { useGetOfferByReferenceJobFactory } from '@/features/ats/api/useGetOfferByReferenceJobFactory';
import { OfferOwnerForm } from '@/scenes/Client/scenes/components/OfferOwnerForm';
import { OfferContractsForm } from '@/scenes/Client/scenes/components/OfferContractsForm';
import { OfferDescriptionForm } from '@/scenes/Client/scenes/components/OfferDescriptionForm';
import { OfferProfileDescriptionForm } from '@/scenes/Client/scenes/components/OfferProfileDescriptionForm';

const EditOfferStep1 = ({
  saveOffer,
  setOffer,
  savedOffer,
  authUser
}) => {
  const t = useFormatMessage();
  const isAtsEnabled = authUser?._currentOrganization?.syncAts?.enable;
  const [atsOfferImported, setAtsOfferImported] = useState(null);

  const [initialValues, setInitialValues] = useState({
    title: savedOffer?.title ?? '' ,
    description: savedOffer?.description ?? '' ,
    profileDescription: savedOffer?.profileDescription ?? '',
    _user: savedOffer?._user?._id ?? savedOffer._user ?? '',
    matching: { filters: savedOffer?.matching?.filters, DURATION: savedOffer?.matching?.DURATION }
  });
  const [userFullName, setUserFullName] = useState();

  const jfAtsOfferQuery = useGetOfferByReferenceJobFactory({
    atsReference: savedOffer?.atsReference,
    onSuccess: (data) => {
      setAtsOfferImported(data);
    }
  })

  const onSubmitAtsOffer = (offer, form) => {
    const atsContracts = offer.contracts.map(({ _id }) => _id);
    form.change('matching.filters', atsContracts);
    form.change('title', offer.title);
    form.change('description', offer.description);
    form.change('profileDescription', offer.profileDescription);
    
    const filters = [...atsContracts];
    
    if (offer.duration?._id) {
      filters.push(offer.duration?._id);
    }
    
    setInitialValues({
      ...initialValues,
      title: offer?.title || '',
      description: offer?.description || '',
      profileDescription: offer?.profileDescription || '',
      matching: { filters },
      locations: offer.locations
    });
    
    setAtsOfferImported(offer);
  }
  
  const onRemoveAtsOffer = () => {
    setAtsOfferImported(null);
  }
  
  const handleOnSubmit = (values, form, _callback, isDraft = false) => {
    const newOffer = { 
      ...values, 
      ...(!isEmpty(atsOfferImported) ? { atsReference: atsOfferImported?.atsReference } : {} )
    };
    const hasFormChanged = Object.entries(form.getState().modified).some(([_fieldName, hasChanged]) => hasChanged);
    setOffer(newOffer, hasFormChanged);
    if (isDraft) {
      return saveOffer({
        ...newOffer,
        matching: {
          ...savedOffer.matching,
          ...newOffer.matching,
          CONTRACT: newOffer.matching.filters,
          DURATION: newOffer.matching.DURATION
        }
      }, 'draft');
    }
    saveOffer(newOffer);
  };
  
  const onOpenAtsModal = (formState) => {
    setInitialValues({
      ...formState?.values
    })
  }
  
  return (<div className={styles.formContainer}>
      <h1 className={styles.title}><FormattedMessage {...messages.title} /></h1>
      <p><FormattedMessage id='client.create.offer.description.subtitle' /></p>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => {
          const state = form.getState();
          
          return (
            <>
              <Ats
                onSubmitOffer={(offer) => onSubmitAtsOffer(offer, form)}
                onRemoveOffer={onRemoveAtsOffer}
                atsOfferSelected={atsOfferImported}
                isAtsOfferLoading={jfAtsOfferQuery.isLoading}
                onOpen={() => onOpenAtsModal(state)}
                seekubeOffer={savedOffer}
              />
              <form onSubmit={handleSubmit} className={styles.inputsContainer}>
                {isAtsEnabled && <div className={styles.divider}>
                  <Divider size={400} />
                </div>}
                <FormTextField
                  leftIcon={<IconFileText size={24} />}
                  id="title"
                  name="title"
                  label={t({ id: 'offer.form.title.label' })}
                  required
                />
                <OfferOwnerForm
                  organization={savedOffer?._organization}
                  setUserFullName={setUserFullName}
                  initialValue={initialValues._user}
                />
                <OfferContractsForm 
                  organization={savedOffer?._organization}
                  atsOfferImported={atsOfferImported}
                  userFullName={userFullName}
                />
  
                <OfferDescriptionForm initialValues={initialValues} />
  
                <OfferProfileDescriptionForm initialValues={initialValues} />
  
                <div className={styles.submitContainer}>
                  <Button className="mr-6" c variant="tonal" onClick={() => handleOnSubmit(state.values, form, null, true)}>
                    {t({ id: 'save.draft' })}
                  </Button>
                  <Button type="submit" onClick={() => {}} disabled={!state.valid}>
                    {t({ id: 'next' })}
                  </Button>
                </div>
              </form>
            </>
          )
        }}
      />
  </div>)
}

EditOfferStep1.propTypes = {
  saveOffer: func,
  setOffer: func,
  savedOffer: object,
  authUser: object
}

export { EditOfferStep1 };