import React from 'react';
import styles from '../styles.less';
import { object } from 'prop-types';

const RightContent = ({
  children
}) => {
  return (
    <div className={styles.rightContainer}>
      {children}
    </div>
  )
}

RightContent.propTypes = {
  children: object
}

export { RightContent }