import React from 'react';
import { injectIntl } from 'react-intl';
import mime from 'mime-types';
import { bool, object, string, func, oneOfType } from 'prop-types';
import classnames from 'classnames';
import { Button, IconDownload } from '@seekube-tech/ui-kit';
import { isEmpty } from 'lodash';
import uuidv1 from 'uuid/v1';
import slugify from 'slugify';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Upload } from 'antd';
import request from '@/utils/request';
import { getFileExtension } from '@/utils/file';
import Icon from '@/components/Icon';
import LoadingIndicator from '@/components/LoadingIndicator';
import notification from '@/components/Notification';
import requestExternal from '@/utils/requestExternal';
import styles from './styles.less';
import messages from './messages';
const {Dragger} = Upload;

/**
 * CV
 */
class Pdf extends React.PureComponent {

  static propTypes = {
    name: string,
    editMode: bool,
    showTop: bool,
    src: oneOfType([string, object]),
    downloadSrc: oneOfType([string, object]),
    onChange: func,
    onDownloadPdf: func,
    participant: object,
    intl: object,
  };

  static defaultProps = {
    showTop: true,
  };

  /**
   * State
   */
  state = {
    cvContainerWidth: 500,
    src: this.props.src,
    isLoaded: false,
    uploadZoneIsVisible: this.props.editMode && (!this.props.src || this.props.src === ''),
    numPages: null,
    scale: 'auto',
    uploadUrl: null,
    uploadingResume: null,
  };

  /**
   * Hide upload zone if exit editMode
   *
   * @param {object} params
   * @param {boolean} params.editMode
   * @param {string} params.src
   */
  componentWillReceiveProps({ editMode, src }) {
    this.setState({
      isLoaded: false,
      src,
      uploadZoneIsVisible: editMode && (!src || src === ''),
    });
  }

  /**
   * When the PDF is loaded, save the number of pages
   *
   * @param {object} params
   * @param {number} params.numPages
   */
  handleOnDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages, isLoaded: true });
  };

  /**
   * Zoom in or Zoom out
   *
   * @param {object} e
   */
  handleZoom = (e) => {
    this.setState({ scale: e.currentTarget.value });
  };

  /**
   * generateFileName
   *
   * @description Generate a random filename for upload
   */
  generateFileName = (file, user) => {
    const ext = file.name ? file.name.substr(file.name.lastIndexOf('.') + 1) : mime.extension(file.type);
    const fileName = `${slugify(user.fullName)}-${uuidv1()}.${ext}`;

    try {
      return new File([file], fileName, { type: file.type });
    } catch (err) {
      return file;
    }
  };

  /**
   * AskAWSUrl
   *
   * @description
   * Get signedUrl from our API
   */
  askAWSUrl = (file) => {
    const { props: { participant, intl } } = this;
    const user = participant ? participant._user : null;

    if (!user) {
      return false;
    }

    if (file && file.size > 2000000) {
      notification.error(intl.formatMessage(messages.resumeErrorSize));

      return false;
    }

    const newFile = this.generateFileName(file, user);

    this.setState({
      file: newFile,
    });

    return new Promise((resolve, reject) => {
      request(`${process.env.FRONT_API_URL}/users/${user._id}/upload?fileName=${newFile.name}`)
        .then((result) => {
          if (newFile.type.includes('pdf')) {
            this.setState({
              uploadUrl: result.signedRequest,
              uploadingResume: result.url,
            }, () => resolve());
          } else {
            reject(notification.error(intl.formatMessage(messages.resumeErrorFormat)));
          }
        })
        .catch((e) => reject(e));
    });
  };

  /**
   * UploadFile
   *
   * @description
   * Upload file on AWS
   */
  uploadFile = ({ onError, onSuccess }) => {
    const { state: { uploadUrl, uploadingResume, file }, props: { onChange } } = this;

    requestExternal(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    })
      .then(() => {
        if (file.type.includes('pdf')) {
          this.setState({
            uploadUrl: null,
            resumeS3Key: uploadingResume,
            uploadingResume: null,
            src: uploadingResume,
            uploadZoneIsVisible: false,
          }, () => {
            onChange(this.state.src);
            onSuccess(null, file);
          });
        } else {
          onError();
        }
      })
      .catch(() => onError());
  };

  /**
   * Toggle zone for drag and drop a new Pdf
   */
  toggleUploadZone = () => this.setState({ uploadZoneIsVisible: !this.state.uploadZoneIsVisible });

  /**
   * Render all pages
   */
  renderAllPages = () => {
    const { state: { numPages, scale } } = this;

    return Array
      .from(new Array(numPages))
      .map((el, index) => <Page key={`page_${index + 1}`} scale={scale !== 'auto' ? parseFloat(scale) : 1.25} pageNumber={index + 1} />);
  };

  /**
   * Render
   */
  render() {
    const {
      props: { editMode, name, downloadSrc, showTop, onDownloadPdf, intl },
      state: { scale, uploadZoneIsVisible, src, isLoaded },
      renderAllPages, handleOnDocumentLoadSuccess, handleZoom, toggleUploadZone, uploadFile, uploadUrl, askAWSUrl,
    } = this;

    const fileType = src ? getFileExtension(typeof src === 'object' ? src.name : src) : '';
    const havePdf = (typeof src !== 'undefined' && fileType === 'pdf');
    const pdfViewerClassname = classnames(styles.pdfViewerContainer, havePdf ? styles.withPdf : '', scale === 'auto' ? styles.scaleAuto : null);

    return (
      <div className={classnames(styles.cvContainer, 'cvClass')}>
        {/* PDF Commands */}
        {
          showTop ? (
            <div className={styles.top}>
              <div className={styles.actions}>
                <span>{name}</span>

                {/* Button for display upload zone */}
                {
                  (editMode && havePdf && !uploadZoneIsVisible) ? (
                    <a role="button" tabIndex={0} onClick={toggleUploadZone}>
                      modifier le cv
                    </a>
                  ) : null
                }
                {/* Display PDF and close uploadZoneIsVisible */}
                {
                  (editMode && havePdf && uploadZoneIsVisible) || isEmpty(src) ? (
                    <a role="button" tabIndex={0} onClick={toggleUploadZone}>
                      annuler
                    </a>
                  ) : null
                }
              </div>

              {
                havePdf && isLoaded ? (
                  <div className={styles.pdfZoom}>
                    <select value={scale} onChange={(e) => handleZoom(e)}>
                      <option value="auto">{intl.formatMessage(messages.pdfZoom)}</option>
                      <option value="0.5">50%</option>
                      <option value="0.75">75%</option>
                      <option value="1">100%</option>
                      <option value="1.25">125%</option>
                      <option value="1.5">150%</option>
                      <option value="2.0">200%</option>
                      <option value="3.0">300%</option>
                      <option value="4.0">400%</option>
                    </select>
                  </div>
                ) : null
              }
              {
                havePdf ? (
                  <div className={styles.actions}>
                    <Button
                      imageComponentLeft={<IconDownload />}
                      variant="tonal"
                      onClick={(e) => {window.open(downloadSrc || src, '_blank'); onDownloadPdf(e);}}
                    >
                      {intl.formatMessage(messages.pdfDownload)}
                    </Button>
                  </div>
                ) : null
              }
            </div>
          ) : null
        }

        {/* PDF Viewer */}
        <div className={classnames(pdfViewerClassname, 'pdfViewerClass')} style={{ minHeight: (havePdf && !uploadZoneIsVisible) ? 600 : null }}>
          {
            uploadZoneIsVisible ? (
              <Dragger
                action={uploadUrl}
                beforeUpload={askAWSUrl}
                customRequest={uploadFile}
                showUploadList={false}
                className={styles.draggerContainer}
                accept=".pdf"
              >
                <p className="ant-upload-drag-icon">
                  <Icon name="cloud-upload" />
                </p>
                <p className="ant-upload-text">Téléchargez votre {name}</p>
                <p className="ant-upload-hint">.pdf (2MB max)</p>
              </Dragger>
            ) : (
              <Document
                file={src}
                error={intl.formatMessage(messages.pdfError, { name })}
                loading={<LoadingIndicator />}
                noData={`${intl.formatMessage(messages.pdfNone)} ${name}`}
                options={{
                  withCredentials: true
                }}
                onLoadSuccess={handleOnDocumentLoadSuccess}
                onLoadError={(e) => {
                  window.alert('Error while loading document! ' + e.message)
                }}
                className={styles.pdfDocument}
              >
                {renderAllPages()}
              </Document>
            )
          }
        </div>
      </div>
    );
  }
}

export default injectIntl(Pdf);
