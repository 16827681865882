import { useMutation } from 'react-query';
import request from '@/utils/request';

export function usePostApplication({
  onSuccess,
  onError,
  provider
}) {

  const mutationFn = (body) => {
    let requestUrl = `${process.env.FRONT_API_URL}/ats/connect/application`;

    return (
      request(requestUrl, {
        method: 'POST',
        body: JSON.stringify(body),
      })
    )
  }

  return useMutation({
    mutationFn,
    onSuccess,
    onError
  });
}
