import React from 'react';
import { object, func } from 'prop-types';
import { injectIntl } from 'react-intl';

// components
import { Form } from 'antd';
import { Body1, Button, H5 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import Icon from '@/components/Icon';
import InputMaterial from '@/components/Input/InputMaterial';
import Separator from '@/components/Separator';
import { isPasswordPolicyCompliant } from '@/utils/validations/password';
import messages from './messages';

import styles from '../../styles.less';


const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class Password extends React.PureComponent {
  static propTypes = {
    form: object,
    intl: object,
    handleSaveUserKey: func,
  };

  state = {
    passwordFieldType: 'password',
    confirmDirty: false,
  };

  componentDidMount() {
    const { props: { form: { validateFields, setFields } } } = this;
    validateFields();
    setFields({
      password: { value: null, error: false },
      newPassword: { value: null, error: false },
      confirmPassword: { value: null, error: false },
    });
  }

  /**
   * handleOnRevealPassword
   *
   * @description : Handle click when user show / hide password
   *
   */
  handleOnRevealPassword = () => {
    const { passwordFieldType } = this.state;
    this.setState({
      passwordFieldType: passwordFieldType === 'password' ? 'text' : 'password',
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { props: { intl } } = this;
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(intl.formatMessage(messages.passwordErrorDifferent));
    } else {
      callback();
    }
  };

  /**
   * validateToNextPassword
   *
   * @description : Validate password structure
   *
   */
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const { props: { handleSaveUserKey, form, intl } } = this;

    form.validateFields((err, values) => {
      if (!err) {
        // Check if new email is different of current email
        handleSaveUserKey({
          password: values.password,
          newPassword: values.newPassword,
          notificationParams: {
            success: {
              message: ` ${intl.formatMessage(messages.informationSuccess)}`,
              kind: 'info',
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
            error: {
              message: ` ${intl.formatMessage(messages.passwordFieldError)}`,
              style: {
                bottom: '5%',
                top: 'inherit',
              },
            },
          },
        });
      }
    });
  };

  render() {
    const {
      props: { intl, form: { getFieldDecorator, getFieldsError, isFieldTouched, getFieldError } },
      state: { passwordFieldType, formIsTouched },
      handleOnRevealPassword, handleOnSubmit, handleConfirmBlur, compareToFirstPassword, validateToNextPassword,
    } = this;

    const passwordError = isFieldTouched('newPassword') && getFieldError('newPassword');

    return (
      <>
        <Form className={styles.emailFormWrapper} onSubmit={handleOnSubmit}>
          <H5 className="mb-20">{intl.formatMessage(messages.passwordChange)}</H5>
          <Separator className="hidden-mobile" height={20} />
          <FormItem>
            <div className={styles.passwordWrapper}>
              <button tabIndex={-1} type="button" className={styles.passwordReveal} onClick={handleOnRevealPassword}>
                {passwordFieldType === 'password' ? (
                  <Icon name="icon-hide" />
                ) : (
                  <Icon name="icon-seen" />
                )}
              </button>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: intl.formatMessage(messages.passwordMandatory) },
                ],
              })(
                <InputMaterial
                  addOnIcon="password-lock"
                  label={intl.formatMessage(messages.currentPassword)}
                  placeholder={intl.formatMessage(messages.placeholderText)}
                  type={passwordFieldType}
                />
              )}
            </div>
          </FormItem>

          <FormItem>
            <div className={styles.passwordWrapper}>
              <button tabIndex={-1} type="button" className={styles.passwordReveal} onClick={handleOnRevealPassword}>
                {passwordFieldType === 'password' ? (
                  <Icon name="icon-hide" />
                ) : (
                  <Icon name="icon-seen" />
                )}
              </button>
              {getFieldDecorator('newPassword', {
                rules: [
                  { required: true, message: intl.formatMessage(messages.mandatoryField) },
                  { validator: (_, value) => isPasswordPolicyCompliant(value), message: ' ' },
                  { validator: validateToNextPassword },
                ],
              })(
                <InputMaterial
                  addOnIcon="password-lock"
                  label={intl.formatMessage(messages.newPassword)}
                  placeholder={intl.formatMessage(messages.placeholderText)}
                  type={passwordFieldType}
                />
              )}
            </div>
          </FormItem>
          <FormItem className="mb-20">
            <div className={styles.passwordWrapper}>
              <button tabIndex={-1} type="button" className={styles.passwordReveal} onClick={handleOnRevealPassword}>
                {passwordFieldType === 'password' ? (
                  <Icon name="icon-hide" />
                ) : (
                  <Icon name="icon-seen" />
                )}
              </button>
              {getFieldDecorator('confirmPassword', {
                rules: [
                  { required: true, message: intl.formatMessage(messages.placeholderText) },
                  { validator: compareToFirstPassword },
                ],
              })(
                <InputMaterial
                  addOnIcon="password-lock"
                  label={intl.formatMessage(messages.confirmPasswordLabel)}
                  placeholder={intl.formatMessage(messages.placeholderText)}
                  type={passwordFieldType}
                  onChange={() => this.setState({ formIsTouched: true })}
                  onBlur={handleConfirmBlur}
                />
              )}
            </div>
          </FormItem>
          <Body1 color={colors.warning['500']}>
            {intl.formatMessage({ id: 'password.error.policy.bis' })}
          </Body1>
          <Body1 color={passwordError ? colors.error['500'] : colors.neutral['400']}>
            {intl.formatMessage({ id: 'password.error.policy' }, { countMin: process.env.FRONT_APP_PASSWORD_MIN_LENGTH, countMax: process.env.FRONT_APP_PASSWORD_MAX_LENGTH })}
          </Body1>
          <Separator height={10} />
          <Button
            type="submit"
            disabled={hasErrors(getFieldsError()) || !formIsTouched}
          >
            {intl.formatMessage(messages.save)}
          </Button>
          <Separator height={10} />
        </Form>
      </>
    );
  }
}

export default Form.create()(injectIntl(Password));
