import { IconContract, Select } from '@seekube-tech/ui-kit';
import { Field, useForm, useFormState } from 'react-final-form';
import React, { useMemo } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import useValidations from '@/utils/validations/useValidations';
import { string, array } from 'prop-types';

const OfferSpreadingContractsInput = ({
  initialValue,
  name,
  contractsList,
}) => {
  const t = useFormatMessage();
  const form = useForm();
  const formState = useFormState();
  const authUser = useSelector(getAuthUser)?.toJS();
  const { isFilled } = useValidations();
  
  const options = useMemo(() => contractsList?.map((contract) => ({
     value: contract._id,
     label: contract[`label_${authUser.locale}`] || contract.label
   })) ?? [], contractsList);
  
  const onChange = (val) => {
    form.change(name, val);
  }

  const [prefix, contract] = name.split('.');
  const value = formState.values?.[prefix]?.[contract];
  
  return (
    <Field
      name={name}
      id={name}
      label={t({ id: 'contracts' })}
      value={value}
      initialValue={initialValue}
      validate={(value) => isFilled(value)}
    >
      {() => (
        <Select
          id={name}
          label={t({ id: 'contracts' })}
          name={name}
          options={options}
          leftIcon={<IconContract size={24} />}
          value={value || []}
          onChange={onChange}
          multiple
          required
        />
      )}
    </Field>
  )
}

OfferSpreadingContractsInput.propTypes = {
  initialValue: string,
  name: string,
  contractsList: array,
}

export { OfferSpreadingContractsInput }