import React from 'react';
import {
  UserCard
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointment/components/AppointmentDetails';
import { isEmpty } from 'lodash';
import { Matching } from '@/components/ParticipantCards/components/components/Matchings/components';
import { Tag } from '@seekube-tech/ui-kit';
import Separator from '@/components/Separator';
import { Typography } from '@seekube-tech/ui';
import { Matchings, SearchRow } from '@/components/ParticipantCards/components/components';
import { useFormatMessage } from 'react-intl-hooks';
import { sliceLongText } from '@/components/ModalAtsImport/helpers';
import styles from './styles.less';
import ModalFullSplitedView from '@/components/ModalFullSplitedView';
import { object } from 'prop-types';

const RightSideCandidateSummary = ({
  participantOffers,
  participant,
  authUser
}) => {
  const t = useFormatMessage();

  if (!participant?._user) {
    return null;
  }

  return (
    <ModalFullSplitedView.RightContent>
      <Typography className={styles.title} variant="h4">{t({ id: 'ats.exportCandidate.candidateInfos' })}</Typography>
      <UserCard
        user={participant?._user}
      />

      {!isEmpty(participantOffers) ? (
        <Matching
          name={t({ id: 'candidate.jobdating.offers' })}
          icon="tag"
          str={(<>{participantOffers.map((o) => <Tag variant="outline"
                                                     color="neutral">{sliceLongText(o.title)}</Tag>)}</>)}
        />
      ) : null}

      <Matching
        name={t({ id: 'ats.importModal.candidate.mail' })}
        icon="mail"
        str={participant?._user.username}
      />

      <Matching
        name={t({ id: 'ats.importModal.candidate.phone' })}
        icon="phone"
        str={participant?._user.phone}
      />

      <Separator height={32} />
      <Typography variant="h4">{t({ id: 'ats.importModal.candidate.criteriaTitle' })}</Typography>
      <Separator height={24} />
      <SearchRow participant={participant} authUser={authUser} />
      <Matchings
        participant={participant}
        authUser={authUser}
      />
    </ModalFullSplitedView.RightContent>
  )
}

RightSideCandidateSummary.propTypes = {
  participantOffers: object,
  participant: object,
  authUser: object
}

export { RightSideCandidateSummary };