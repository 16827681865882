import React, { useEffect } from 'react';
import { object, any, func, bool } from 'prop-types';
import moment from 'moment';
import useValidations from '@/utils/validations/useValidations';
import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { toJS } from '@/utils';
import { getCriterionLabel } from '../utils';
import { FormAutocompleteField } from '@/components/FinalFormFields/FormAutocompleteField';
import { useFormState } from 'react-final-form';
import { IconCalendar } from '@seekube-tech/ui-kit';

function AvailabilityField({ criterion, endAt, disabled = false, required = true }) {
  const { isFilled } = useValidations();
  const formState = useFormState();

  const getOptions = () => {
    const date = moment(endAt).toDate();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    return Array.from(Array(12).keys(), (item) => {
      const now = item > 0 ? moment(firstDay).add(item, 'M') : moment(firstDay);

      return {
        index: item,
        value: now.unix().toString(),
        label: now.format('MMMM YYYY'),
      };
    });
  };
  
  return (
    <div>
      <FormAutocompleteField
        name="date"
        disabled={disabled}
        label={getCriterionLabel(criterion)}
        leftIcon={<IconCalendar />}
        required={required}
        validate={value => isFilled(value)}
        options={getOptions()}
      />
    </div>
  );
}

AvailabilityField.propTypes = {
  criterion: object,
  endAt: any.isRequired,
  showHelper: func,
  authUser: object,
  disabled: bool
};

AvailabilityField.defaultProps = {
  criterion: {},
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(AvailabilityField));
