import { useQuery } from 'react-query';
import request from '@/utils/request';
import { setUserConnectionStatus } from '@/utils/user';

export function useGetLoginAuto({ onSuccess = () => {} }) {
  const requestUrl = `${process.env.FRONT_API_URL}/auth/me`;
  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['user-auth'],
    queryFn,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (response) => {
      setUserConnectionStatus(true, response);
      onSuccess(response);
    },
  });
  return query;
}
