import { useMutation, useQueryClient } from "react-query";
import { map } from "lodash";
import request from "@/utils/request";
import { objectToParams } from "@/utils/url";

export function useUpdateOrganization({
  organizationId,
  onSuccess = () => {},
  onError = () => {},
}) {
  const queryClient = useQueryClient();

  const mutationFn = ({ organization, syncExponents }) => {
    const params = objectToParams({ syncExponents });
    const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}?${params}`;

    return request(requestUrl, {
      method: "PATCH",
      body: JSON.stringify(organization),
    });
  };

  return useMutation({
    mutationFn,
    onSuccess: async (organization) => {
      queryClient.setQueryData("organization", () => organization);
      onSuccess();
    },
    onError: async (err) => {
      const error = await err;
      onError(error);
    },
  });
}
