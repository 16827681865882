import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { object, array, func } from 'prop-types';
import { filter, find, isEmpty, get, size, forEach } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { eventActions } from '@/store/event';
import { userSelectors } from '@/store/user';
import { notificationActions } from '@/store/notification';
import { withAuth } from '@/containers/RequiredAuth';
import { getId, getIds } from '@/utils/global';
import { toJS } from '@/utils';
import styles from './styles.less';
import { FormStep2 } from '@/scenes/Client/scenes/OffersSpreading/components/Step2/components/form/Form';
import { Body1, H2 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

const Step2 = ({
  offers,
  form,
  handleNext,
  exponents,
  getEvents,
  handleActions,
  sendNotification,
  eventContracts = []
}) => {
  const t = useFormatMessage();
  const eventId = window.localStorage.getItem('eventSelected');
  const exponentUsers = filter(find(exponents, (exp) => getId(exp._event) === eventId)?.users, (exp) => !isEmpty(exp._user));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    getEvents({ eventsId: getIds(JSON.parse(window.localStorage.getItem('offersSelected'))) });
  }, [offers]);

  useEffect(() => {
    checkIfBtnIsDisabled();
  }, []);

  const handleSubmit = (values) => {
    handleNext(offers.map((off) => ({ ...off, _user: findInExponentUser(values[getId(off)]._user), matching: { ...off.matching, CONTRACT: values[getId(off)]._contract } })));
  };

  const findInExponentUser = (userId) => {
    const exponent = exponentUsers.find((exponent) => {
      return getId(exponent._user) === userId
    });
    
    return exponent?._user;
  }

  const checkIfBtnIsDisabled = (userId, offerId) => {
    if (isEmpty(userId)) {
      forEach(offers,
        (offer) => {
          if (isEmpty(get(find(exponentUsers, (expU) => getId(expU._user) === getId(offer._user)), '_user._id'))) {
            setIsButtonDisabled(true);
            return false;
          }
          return true;
        }
      );
    } else {
      form.setFieldsValue({ [`${offerId}._user`]: userId });

      const fieldsValues = form.getFieldsValue();
      const areFieldsValid = Object.values(fieldsValues).filter(values => values).every(({ _user, _contract }) => !isEmpty(_user) && (isEmpty(eventContracts) || !isEmpty(_contract)));

      setIsButtonDisabled(!areFieldsValid);
    }
  };

  const changeContracts = (offerId, name, values) => {
    form.setFieldsValue({ [name]: values });
    checkIfBtnIsDisabled(form.getFieldValue(`${offerId}._user`), offerId);
  }

  return (
    <div className={styles.step2Container}>
      <div className={styles.header}>
        <H2>{t({ id: 'recruiter.offers.spread.select.recruiters.title' }, { count: size(offers) })}</H2>
        <Body1 className={styles.subtitle}>{t({ id: 'recruiter.offers.spread.select.recruiters.subtitle' }, { count: size(offers) })}</Body1>
      </div>

      <div className={styles.recruiterContainer}>
        <FormStep2 
          changeContracts={changeContracts}
          offers={offers}
          handleActions={handleActions}
          sendNotification={sendNotification}
          checkIfBtnIsDisabled={checkIfBtnIsDisabled}
          eventContracts={eventContracts}
          exponentUsers={exponentUsers}
          isButtonDisabled={isButtonDisabled}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

Step2.propTypes = {
  offers: array,
  intl: object,
  form: object,
  handleNext: func,
  handleActions: func,
  exponents: array,
  getEvents: func,
  sendNotification: func,
  eventContracts: array
};

const mapStateToProps = createStructuredSelector({
  users: userSelectors.getUsersOrganization,
});


const mapDispatchToProps = {
  getEvents: eventActions.getEvents,
  sendNotification: notificationActions.sendNotification,
};


const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  withAuth(),
  injectIntl,
)(toJS(Step2));
