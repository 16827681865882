import React from 'react';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { CardInfo, Link1 } from '@seekube-tech/ui-kit';
import { FormattedHTMLMessage } from 'react-intl';
import Icon from '@/components/Icon';
import { trackRecruiterClickedViewKeyDates } from '@/utils/analytics';
import styles from '@/scenes/Event/scenes/Candidate/scenes/JobDating/containers/SceneAlerts/styles.less';

const HybridClosingDatesInfos = ({ authUser, href, hybridDateToDisplay }) => {
  const t = useFormatMessage();

  return (
    <CardInfo
      isClosable={false}
      title={t({ id: 'recruiter.infoCard.hybrid.interaction.available.title' })}
      description={<CardDescription hybridDateToDisplay={hybridDateToDisplay} />}
      icon={<Icon style={{ width: '100%', height: '100%' }} name="illuHourglass" />}
      visible
      action={
        <Link1
          onClick={() => trackRecruiterClickedViewKeyDates({ authUser })}
          href={href} target="_blank">{t({ id: 'see.keyDates' })}
        </Link1>
      }
    />
  );
}

const CardDescription = ({ hybridDateToDisplay }) => (
  <div className="ml-6">
    <span className={hybridDateToDisplay?.physical?.isPast ? styles.pastDate : styles.bulletDescription}>
      <FormattedHTMLMessage
        id="recruiter.infoCard.hybrid.interaction.available.description.physical"
        values={{
          date: hybridDateToDisplay.physical.date.format('DD MMMM'),
          hour: hybridDateToDisplay.physical.date.format('H[h]mm'),
        }}
      />
    </span>
    <br />
    <span className={hybridDateToDisplay.virtual.isPast ? styles.pastDate : styles.bulletDescription}>
      <FormattedHTMLMessage
        id="recruiter.infoCard.hybrid.interaction.available.description.virtual"
        values={{
          date: hybridDateToDisplay.virtual.date.format('DD MMMM'),
          hour: hybridDateToDisplay.virtual.date.format('H[h]mm'),
        }}
      />
    </span>
  </div>
);

CardDescription.propTypes = {
  hybridDateToDisplay: object,
}

HybridClosingDatesInfos.propTypes = {
  hybridDateToDisplay: object,
  authUser: object,
  href: string,
}

export { HybridClosingDatesInfos }