import React from 'react';
import { InputText } from '@seekube-tech/ui-kit';
import { Field, FormSpy, useForm } from 'react-final-form';
import { any, bool, func, string } from 'prop-types';
import { isEmpty } from 'lodash';
import useValidations from '@/utils/validations/useValidations';
import { getFieldError } from '@/utils/forms/final-form/helpers';

export const FormTextField = ({
  name,
  id,
  label,
  placeholder,
  defaultValue,
  validate,
  leftIcon,
  required,
  showErrorOnMount = true,
  disabled
}) => {
  const form = useForm();
  const error = getFieldError(form.getState().errors, name);
  const { isFilled } = useValidations();
  const shouldShowError = showErrorOnMount || form.getState().modified[name];
  
  const validation = (value) => {
    const isFilledError = isFilled(value);
    
    if (required && !isEmpty(isFilledError)) {
      return isFilledError;
    }
    if (validate) {
      return validate(value);
    }
  }
  
  const onChange = (event) => {
    form.change(name, event.target.value);
  }
  
  return (
    <FormSpy subscription={{values: true}}>
      {() => (
        <Field
          name={name}
          id={id}
          label={label}
          placeholder={placeholder}
          defaultValue={defaultValue}
          validate={validation}
        >
          {props => (
              <InputText
                {...props}
                value={props.input.value}
                onChange={onChange}
                leftIcon={leftIcon}
                required={required}
                color={(error && shouldShowError && props.meta.modified) ? 'error' : 'neutral'}
                message={(props.meta.modified && shouldShowError) ? error : ''}
                disabled={disabled}
            />
            )}
        </Field>
      )}
    </FormSpy>
  );
}

FormTextField.propTypes = {
  name: string,
  id: string,
  label: string,
  placeholder: string,
  defaultValue: any,
  validate: func,
  leftIcon: any,
  required: bool
}