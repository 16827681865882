import React from 'react';
import { object, bool } from 'prop-types';
import { FormSpy } from 'react-final-form';
import useValidations from '@/utils/validations/useValidations';
import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { toJS } from '@/utils';
import { CRITERION_KEY } from '../../../utils/constants';
import { getCriterionLabel } from '../utils';
import { FormAutocompleteField } from '@/components/FinalFormFields/FormAutocompleteField';
import { IconHourglass } from '@seekube-tech/ui-kit';

function DurationField({ criterion, disabled = true, required = true }) {
  const { isFilled, isMin, isMax } = useValidations();
  const label = getCriterionLabel(criterion);

  const handleValidate = (value, allValues) => {
    if (isDisabled(allValues?.CONTRACT) === false) {
      if (criterion.modules.offer.choiceMin) {
        return (
          isFilled(value) ||
          isMin(value, criterion.modules.offer.choiceMin) ||
          isMax(value, criterion.modules.offer.choiceMax)
        );
      }
      return value && isMax(value, criterion.modules.offer.choiceMax);
    }
    return null;
  }

  const isDisabled = (contractValues = []) => {
    return (
      contractValues.length === 1 &&
      contractValues[0] === '598826added2cd00b2ad666a'
    );
  }

  return (
    <FormSpy
      subscription={{ values: true }}
      render={({values}) => (
        <FormAutocompleteField 
          name={CRITERION_KEY.duration}
          label={label}
          required={(isDisabled(values?.CONTRACT) === false) && required}
          validate={(value) => handleValidate(value, values)}
          options={criterion._choices.map(({ _id, ...duration }) => ({ value: _id, ...duration }))}
          multiple
          disabled={disabled || isDisabled(values?.CONTRACT)}
          leftIcon={<IconHourglass />}
        />
      )}
    />
  );
}

DurationField.propTypes = {
  criterion: object,
  disabled: bool,
  required: bool
};

DurationField.defaultProps = {
  criterion: {},
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(DurationField));
