import { Rate } from 'antd';
import { Alert, Divider, H2, InputText, Textarea } from '@seekube-tech/ui-kit';
import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import Ats from '@/features/ats/components/Ats';
import styles from './styles.less';
import { useGetOfferByReferenceJobFactory } from '@/features/ats/api/useGetOfferByReferenceJobFactory';
import { isEmpty } from 'lodash';
import ModalFullSplitedView from '@/components/ModalFullSplitedView';
import { bool, func, object } from 'prop-types';

export const LeftSideAtsSelection = ({
  syncAts,
  isError,
  handleClose,
  handleSubmit,
  defaultReviewer,
  authUser,
  offer
}) => {
  const t = useFormatMessage();
  const [atsOfferSelected, setAtsOfferSelected] = useState(null);

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [reviewer, setReviewer] = useState(!isEmpty(defaultReviewer) ? defaultReviewer : authUser.fullName);

  const { isLoading } = useGetOfferByReferenceJobFactory({
    atsReference: offer?.atsReference,
    onSuccess: (data) => {
      setAtsOfferSelected(data);
    }
  });

  return (
    <ModalFullSplitedView.LeftContent
      onSubmit={() => handleSubmit({ ...atsOfferSelected, rating, comment, reviewer })}
      handleClose={handleClose}
      isLoading={isLoading}
      actionLabel={t({ id: 'ats.importModal.cta' })}
      canSubmit={!isEmpty(atsOfferSelected) && !isLoading}
    >
      <div className={styles.ats}>
        <H2>{`${t({ id: 'ats.importModal.title' })} ${t({ id: `ats.provider.${syncAts?.provider}` })}`}</H2>

        <Divider size='100%' />

        <Ats
          onSubmitOffer={setAtsOfferSelected}
          atsOfferSelected={atsOfferSelected}
          isAtsOfferLoading={isLoading}
          onRemoveOffer={() => setAtsOfferSelected(null)}
        />

        {syncAts?.provider === 'smartrecruiters' && (
          <>
            <div className={styles.rating}>
              {t({ id: 'ats.importModal.review' })}
              <Rate onChange={setRating} value={rating} />
            </div>

            <InputText
              name="reviewer"
              id="reviewer"
              value={reviewer}
              onChange={setReviewer}
              label={t({ id: 'ats.importModal.reviewer' })}
            />

            <Textarea
              name="comment"
              id="comment"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              label={t({ id: 'ats.importModal.comment' })}
            />
          </>
        )}
        {
          isError && (
            <Alert color="error">
              {t({ id: 'ats.importModal.error.title' })}<br />
              {t({ id: 'ats.importModal.error.contact.support' })}
            </Alert>
          )
        }
      </div>
    </ModalFullSplitedView.LeftContent>
  )
}

LeftSideAtsSelection.propTypes = {
  syncAts: object,
  isError: bool,
  handleClose: func,
  handleSubmit: func,
  defaultReviewer: object,
  authUser: object,
  offer: object
}