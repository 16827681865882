import { CollapseItem } from '@/features/ats/components/AtsModalContent/Body/Filters/CollapseItem';
import React from 'react';
import { func, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

const Menu = ({
  facets,
  onSelectFilter,
  onSelectDurationFilter,
  filters
}) => {
  const t = useFormatMessage();
  
  return (
    <div>
      <CollapseItem
        name="contracts"
        label={t({ id: 'ats.modal.filters.contract' })}
        values={facets?.['contracts'] ?? []}
        onSelectFilter={onSelectFilter}
        selectedFilters={filters['contracts']}
      />
      <CollapseItem
        name="locations"
        label={t({ id: 'ats.modal.filters.location' })}
        values={facets?.['locations'] ?? []}
        onSelectFilter={onSelectFilter}
        selectedFilters={filters['locations']}
      />
      <CollapseItem
        label={t({ id: 'ats.modal.filters.duration' })}
        values={facets?.['durations'] ?? []}
        onSelectFilter={onSelectDurationFilter}
        selectedFilters={Object.keys(filters['durations'])}
      />
    </div>)
}

Menu.propTypes = {
  facets: object,
  onSelectFilter: func,
  onSelectDurationFilter: func,
  filters: object
}

export { Menu };