import React, { useState } from 'react';
import styles from './styles.less';
import { IconChevronDown, IconChevronUp, IconSearch, InputText, Tag } from '@seekube-tech/ui-kit';
import { CollapseContent } from '@/features/ats/components/AtsModalContent/Body/Filters/CollapseContent';

const CollapseItem = ({
  name,
  label,
  values,
  onSelectFilter,
  selectedFilters,
}) => {
  const [open, setOpen] = useState(false);
  
  const visibleFilters = Object.entries(values).filter(([filterName]) => selectedFilters.includes(filterName)).map(([filterName]) => filterName);
  
  return (
    <div className={styles.container}>
      <div className={styles.itemContainer} onClick={() => setOpen(!open)}>
        <div className={styles.leftContent}>
          {label}
          {visibleFilters.length > 0 && <Tag variant="tonal" color="neutral">{visibleFilters.length}</Tag>}
        </div>
        {open ? <IconChevronUp className={styles.chevron} size={24} /> : <IconChevronDown className={styles.chevron} size={24} />}
      </div>
      <CollapseContent 
        open={open} 
        searchable 
        values={values}
        selectedFilters={visibleFilters}
        onSelectFilter={(value) => onSelectFilter(name, value)}
        label={label}
      />
    </div>
  )
}

CollapseItem.propTypes = {}

export { CollapseItem };