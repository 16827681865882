import { Checkbox, CheckboxList, IconSearch, InputText, Tag } from '@seekube-tech/ui-kit';
import React, { useState } from 'react';
import { array, bool, func, string, object } from 'prop-types';
import styles from './styles.less';
import { useFormatMessage } from 'react-intl-hooks';
import { getLocale } from '@/utils/localStorage';

const CollapseContent = ({
  open,
  searchable,
  values,
  onSelectFilter,
  selectedFilters,
  label
}) => {
  const t = useFormatMessage();
  const [search, setSearch] = useState();
  const locale = getLocale();

  if (!open) {
    return null;
  }

  const filtersValues = Object.entries(values)
  .filter(([_name, { label }]) => label.includes(search) || !search);

  return (
    <div className={styles.itemContent}>
      {searchable &&
        <InputText
          id={`search-${label}`}
          size="small"
          placeholder={t({ id: 'search.search' })}
          leftIcon={<IconSearch size={16} />}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      }
      <CheckboxList layout="vertical">
        {filtersValues.map(([itemName, itemContent], index) => (
          <div className={styles.checkboxList} key={`item-${index}-key`}>
            <Checkbox
              id={`${itemContent.label}-${index}`}
              name={`${itemContent.label}-${index}`}
              label={itemContent[`label_${locale}`] ?? itemContent.label}
              onChange={() => onSelectFilter(itemName)}
              checked={selectedFilters.includes(itemName)}
              disabled={!itemContent.count}
            />
            <Tag variant="outline" color="neutral">
              {itemContent.count}
            </Tag>
          </div>
        ))}
      </CheckboxList>
    </div>
  )
}

CollapseContent.propTypes = {
  open: bool,
  searchable: bool,
  values: object,
  onSelectFilter: func,
  selectedFilters: array,
  label: string
}

export { CollapseContent };