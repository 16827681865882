import React, { useRef, useState } from 'react';
import styles from './styles.less';
import { InputSearch, Pagination } from '@seekube-tech/ui-kit';
import { AtsOfferItem } from '@/features/ats/components/AtsOfferItem';
import { useGetOffersJobFactory } from '@/features/ats/api/useGetOffersJobFactory';
import LoadingIndicator from '@/components/LoadingIndicator';
import { debounce } from 'lodash';
import { func, object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Menu } from '@/features/ats/components/AtsModalContent/Body/Filters/Menu';

const Body = ({
  atsOffer,
  onSelectOffer
}) => {
  const t = useFormatMessage();
  const [searchInput, setSearchInput] = useState();
  const [params, setParams] = useState({ page: 1, search: searchInput });
  const [filters, setFilters] = useState({
    contracts: [],
    locations: [],
    durations: []
  });
  const { data: jobFactoryOffers, isLoading } = useGetOffersJobFactory({
    page: params.page,
    search: params.search,
    filters
  });

  const setDebounceSearch = useRef(debounce((search) => {
    setParams({ page: 1, search });
  }, 500));

  const handleSearch = (search) => {
    setSearchInput(search);
    setDebounceSearch.current(search);
  }

  const onSelectFilter = (filterName, value) => {
    let newFilters = {};

    if (!filters[filterName]?.includes(value)) {
      newFilters = { ...filters, [filterName]: [...filters[filterName], value] };
    } else {
      newFilters = { ...filters, [filterName]: [...(filters[filterName].filter((val) => val !== value))] };
    }
    setFilters(newFilters);
  }

  const onSelectDurationFilter = (filterName, value) => {
    const { facets } = jobFactoryOffers;

    if (!facets) {
      return;
    }

    const duration = facets?.['durations']?.[value];
    let newFilters = { ...filters };

    if (newFilters?.durations?.[duration.label]) {
      delete newFilters?.durations?.[duration.label];
    } else {
      newFilters = {
        ...newFilters,
        durations: {
          ...newFilters.durations,
          [duration.label]: duration.filters.join(',')
        }
      }
    }

    setFilters(newFilters);
  }

  return (
    <div className={styles.atsListContent}>
      <div className={styles.filter}>
        <div className={styles.top}>
          <p>{t({ id: 'filters' })}</p>
          <button onClick={() => setFilters({
            contracts: [],
            locations: [],
            durations: []
          })}>
            {t({ id: 'reset' })}
          </button>
        </div>
        <Menu
          facets={jobFactoryOffers?.facets}
          filters={filters}
          onSelectFilter={onSelectFilter}
          onSelectDurationFilter={onSelectDurationFilter}
        />
      </div>
      <div className={styles.results}>
        <div>
          <InputSearch
            color="neutral"
            id="init-ats-search"
            label={t({ id: 'ats.modal.search.label' })}
            name="atsSearch"
            required={false}
            onInputChange={handleSearch}
            inputValue={searchInput}
            value=""
          />
        </div>
        <div className={styles.offers}>
          {
            isLoading ? <LoadingIndicator /> :
              jobFactoryOffers?.docs?.map((offer) => (
                <AtsOfferItem
                  key={`ats-offer-${offer?.atsReference}`}
                  selectable
                  offer={offer}
                  selected={atsOffer?.atsReference === offer.atsReference}
                  onSelectOffer={onSelectOffer}
                />
              ))
          }
          <Pagination
            className="flex justify-center"
            current={jobFactoryOffers?.page}
            pageSize={jobFactoryOffers?.limit}
            total={jobFactoryOffers?.total}
            onChange={(page) => setParams({ ...params, page })}
          />
        </div>
      </div>
    </div>
  )
}

Body.propTypes = {
  atsOffer: object,
  onSelectOffer: func
}

export { Body };