import React, { useState } from "react";
import { object } from "prop-types";
import { useFormatMessage } from "react-intl-hooks";
import { Button, H4, IconBuilding, InputText } from "@seekube-tech/ui-kit";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Bloc, BlocHeader } from "@/components/Bloc";
import styles from "@/scenes/Admin/scenes/Organizations/scenes/Organization/scenes/Details/styles.less";
import { API_CUSTOM_ERRORS } from "@/utils/constants";
import { useUpdateOrganization } from "@/queries/organizations/useUpdateOrganization";
import { sendNotification } from '@/store/notification/actions';

const UpdateOrganizationName = ({ organization }) => {
  const t = useFormatMessage();
  const [name, setName] = useState(organization?.name);
  const [ops, setOps] = useState(organization?.ops || "");
  const [businessManager, setBusinessManager] = useState(
    organization?.businessManager || ""
  );
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const { mutate: updateOrga } = useUpdateOrganization({
    organizationId: organization._id,
    onSuccess: () => {
      setErrorMessage('');
      dispatch(sendNotification({
        message: t({ id: "toaster.edit.success" }),
        kind: "info",
        style: {
          bottom: "10%",
          top: "inherit",
        },
      }));
    },
    onError: (error) => {
      if (
        error?.statusCode === 409 &&
        error?.error === API_CUSTOM_ERRORS.conflict
      ) {
        setErrorMessage(
          t({ id: "event.recruiter.preparation.stand.edit.existError" })
        );
        return dispatch(
          notificationActions.sendNotification({
            message: t({
              id: "event.recruiter.preparation.stand.edit.existError",
            }),
            kind: "error",
            style: {
              bottom: "10%",
              top: "inherit",
            },
          })
        );
      }
      if (error?.error) {
        setErrorMessage(
          t({ id: "event.recruiter.preparation.stand.edit.existError" })
        );
        return dispatch(
          notificationActions.sendNotification({
            message: `Request failed : ${error?.statusCode}`,
            kind: "error",
            style: {
              bottom: "10%",
              top: "inherit",
            },
          })
        );
      }
      return null;
    },
  });

  const onSubmit = () => {
    if (!name) {
      return setErrorMessage(t({ id: "form.mandatory" }));
    }
    updateOrga({
      organization: {
        name,
        ops,
        businessManager,
      },
      syncExponents: false,
    });
  };

  return (
    <div className={styles.updateOrgaContainer}>
      <Bloc>
        <BlocHeader className={styles.heading}>
          <H4>{t({ id: "about" })}</H4>
        </BlocHeader>
        <div>
          <InputText
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            label={t({
              id: "admin.organizations.modal.input.organization.name",
            })}
            placeholder={t({
              id: "admin.organizations.modal.input.organization.name",
            })}
            required={true}
            leftIcon={<IconBuilding />}
            message={errorMessage}
            color={errorMessage ? "error" : "neutral"}
          />
          <InputText
            className="mt-20"
            value={businessManager}
            onChange={(e) => setBusinessManager(e.target.value)}
            leftIcon={null}
            label={t({ id: "orga.business.manager" })}
          />
          <InputText
            className="mt-20"
            value={ops}
            onChange={(e) => setOps(e.target.value)}
            leftIcon={null}
            label={t({ id: "orga.ops" })}
          />
        </div>

        <Button className="mb-1" onClick={onSubmit}>
          {t({ id: "save" })}
        </Button>
      </Bloc>
    </div>
  );
};

UpdateOrganizationName.propTypes = {
  organization: object,
};

export default UpdateOrganizationName;
