import WysiwygEditor from '@/components/WysiwygEditor';
import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useFormatMessage } from 'react-intl-hooks';
import useValidations from '@/utils/validations/useValidations';

const OfferDescriptionForm = () => {
  const formState = useFormState();
  const form = useForm();
  const { isWysiwygFilled } = useValidations();
  const t = useFormatMessage();

  const onChange = (newValue) => {
    if (typeof newValue === 'string') {
      form.change('description', newValue);
    }
  }
  
  const validate = (value) => {
    return isWysiwygFilled(value);
  }

  return (
    <Field
      name={'description'}
      id={'description'}
      validate={validate}
    >
      {() => (
        <div>
          <div className="ant-form-item-label">
            <label className="ant-form-item-required">
              {t({ id: 'client.create.offer.description.label' })}
            </label>
          </div>
          <WysiwygEditor
            initialValue={formState.initialValues['description']}
            onChange={onChange}
            required
          />
        </div>
      )}
    </Field>
  )
}

export { OfferDescriptionForm };