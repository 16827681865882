import React from 'react';
import styles from '../styles.less';
import { Button } from '@seekube-tech/ui-kit';
import { bool, func, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';

const LeftContent = ({
  handleClose,
  canSubmit,
  isLoading,
  onSubmit,
  actionLabel,
  children
}) => {
  const t = useFormatMessage();
  
  return (
    <div className={styles.leftContainer}>
      {children}
      <div className={styles.actions}>
        <Button onClick={handleClose} variant="outline" color="neutral">
          {t({ id: 'cancel' })}
        </Button>

        <Button 
          loading={isLoading} 
          disabled={!canSubmit}
          onClick={onSubmit}
        >
          {actionLabel}
        </Button>
      </div>
    </div>
  )
}

LeftContent.propTypes = {
  handleClose: func,
  canSubmit: bool,
  isLoading: bool,
  onSubmit: func,
  actionLabel: string,
  children: object
}

export { LeftContent }