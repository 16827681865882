import React, { useEffect, useState } from 'react';
import { Alert, Button, ModalContainer } from '@seekube-tech/ui-kit';
import { AtsEmptySelectionContent } from './AtsEmptySelectionContent';
import { isEmpty } from 'lodash';
import { AtsOfferItem } from '@/features/ats/components/AtsOfferItem';
import { useFormatMessage } from 'react-intl-hooks';
import { Footer } from '@/features/ats/components/AtsModalContent/Footer';
import { Title } from '@/features/ats/components/AtsModalContent/Title';
import { Body } from '@/features/ats/components/AtsModalContent/Body';
import styles from './styles.less';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import { useUpdateOffer } from '@/queries/offers/useUpdateOffer';

const Ats = ({
  onSubmitOffer,
  onRemoveOffer,
  atsOfferSelected,
  isAtsOfferLoading,
  onOpen,
  seekubeOffer
}) => {
  const authUser = useSelector(getAuthUser)?.toJS();
  const t = useFormatMessage();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [atsOffer, setAtsOffer] = useState(atsOfferSelected);

  useEffect(() => {
    setAtsOffer(atsOfferSelected);
  }, [atsOfferSelected]);
  
  const { mutate: removeAtsLink } = useUpdateOffer({
    eventSlug: seekubeOffer?._event?.slug,
    organizationId: seekubeOffer?._organization?._id,
    onSuccess: () => {
      setIsModalOpened(false);
      onRemoveOffer();
  }})
  
  if (!authUser._currentOrganization?.syncAts?.enable) {
    return null;
  }
  
  const handleSubmit = () => {
    setIsModalOpened(false);
    onSubmitOffer(atsOffer);
  }
  
  const handleOpen = () => {
    setIsModalOpened(true);
    onOpen?.();
  }
  
  const handleRemoveAtsLink = () => {
    if (seekubeOffer?._id) {
      return removeAtsLink({ atsReference: null, offerId: seekubeOffer._id });
    }
    setIsModalOpened(false);
    onRemoveOffer?.();
  }

  return (
    <div className="w-full">
      {isEmpty(atsOfferSelected) ?
        <AtsEmptySelectionContent
          onClickAction={handleOpen}
          isLoading={isAtsOfferLoading}
        /> :
        <AtsOfferItem
          offer={atsOfferSelected}
          action={
            <Button
              color="primary"
              type="button"
              variant="tonal"
              onClick={handleOpen}
            >
              {t({ id: 'ats.actions.modifyOffer' })}
            </Button>
          }
        />
      }

      <ModalContainer
        onClose={() => setIsModalOpened(false)}
        isClosable
        visible={isModalOpened}
        width={1200}
        title={<Title showWarning={!isEmpty(atsOffer)} />}
      >
        <Alert className={styles.warning} color="warning">
          {t({ id: 'ats.modal.warning'})}
        </Alert>
        <Body
          onSelectOffer={setAtsOffer}
          atsOffer={atsOffer}
        />
        <Footer
          onCancel={() => setIsModalOpened(false)}
          onSubmit={handleSubmit}
          canSubmit={!isEmpty(atsOffer)}
          onRemove={handleRemoveAtsLink}
          canRemove={!isEmpty(atsOfferSelected)}
        />
      </ModalContainer>
    </div>
  )
}

Ats.propTypes = {}

export default Ats;
