import React, { useEffect, useState } from 'react';
import { bool, func, object, array, int, any } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button, Alert, Divider } from '@seekube-tech/ui-kit';
import { createStructuredSelector } from 'reselect';
import { isEmpty, get } from 'lodash';
import { Modal } from 'antd';
import { toJS } from '@/utils';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { authSelectors } from '@/store/auth';
import { offerActions } from '@/store/offer';
import { eventSelectors } from '@/store/event';
import Icon from '@/components/Icon';
import Separator from '@/components/Separator';
import ModalBig from '@/components/ModalBig';
import MatchingHelp from '@/components/MatchingHelp';
import Html from '@/components/Html';
import { If } from '@/components/If';
import CreateOfferForm from './components/CreateOfferForm';
import InputHelper from './components/InputHelper';
import styles from './styles.less';
import messages from '../../messages';
import { useFormatMessage } from 'react-intl-hooks';
import { useGetExponent } from '@/queries/exponent/useGetExponent';
import { useGetOfferByReferenceJobFactory } from '@/features/ats/api/useGetOfferByReferenceJobFactory';
import {
  CRITERION_KEY
} from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/utils/constants';

const CreateOfferModal = ({
  isOpen,
  onClose,
  authUser,
  currentEvent,
  postOffer,
  onPreview,
  patchOffer,
  offer,
  exponent,
  disableRegisterOffer,
  maxOffers,
  publishedOffers,
  warningOfferLimit,
  onSubmit,
  isOwner,
}) => {
  const [hasConfirmModal, setHasConfirmModal] = useState(false)
  const [currentHelper, setCurrentHelper] = useState(null)
  const [currentOfferValues, setCurrentOfferValues] = useState(null)
  const [offerFormHeight, setOfferFormHeight] = useState('auto')
  const t = useFormatMessage();
  const [currentOffer, setCurrentOffer] = useState({ ...offer });
  const [atsOfferSelected, setAtsOfferSelected] = useState(null);
  const jfAtsOfferQuery = useGetOfferByReferenceJobFactory({
    atsReference: offer.atsReference,
    onSuccess: (data) => {
      setAtsOfferSelected(data);
    }
  })

  useEffect(() => {
    setCurrentOffer({ ...offer });
  }, [offer]);

  const { data: exponentWithUsers } = useGetExponent({
    eventSlug: currentEvent.slug,
    exponentId: exponent._id,
    lean: false,
  });

  const getOfferFormHeight = (height) => (setOfferFormHeight(height));

  const hasHelperContent = (id, title) => {
    setCurrentHelper(id !== null ? { id, title } : null)
  }

  const getCurrentHelpContent = () => {
    return currentHelper ? (
      <div>
        <InputHelper id={currentHelper ? currentHelper.id : null} title={currentHelper ? currentHelper.title : null}
                     isVisible={currentHelper !== null} />

        <MatchingHelp
          className={styles.matchingHelp}
          criteria={currentEvent._criteria}
          matching={currentOfferValues ? currentOfferValues.matching : null}
          context="offer"
          text={t(messages.offerFormCompatible)}
        />
      </div>
    ) : (
      <If condition={get(currentEvent, 'descriptionCandidatesTarget')}>
        <div>
          <InputHelper id="default" title={t(messages.titleHelpDefault)} isVisible />
          <div className={styles.defaultHelpText}>
            <Html value={get(currentEvent, 'descriptionCandidatesTarget')} />
          </div>
        </div>
      </If>
    );
  }


  const openConfirmModal = () => {
    setHasConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setHasConfirmModal(false);
    onClose();
    setAtsOfferSelected(null);
  };

  const handleClose = (confirmedRequired = true) => {

    if (confirmedRequired) {
      openConfirmModal();
    } else {
      onClose();
      setAtsOfferSelected(null);
    }
  };

  const trackData = (values) => {
    const customFilters = currentEvent._criteria.filter((item) => item.enable && item.modules.offer.isVisible);
    let countChoices = 0;
    const formValues = values;

    customFilters.forEach(({ fieldName, modules }) => {
      if (modules.offer.choiceMin === 0) {
        if (Number.isNaN(parseInt(fieldName, 2)) === false) {
          countChoices += formValues?.[`custom${fieldName}`]?.length || 0;
        } else {
          countChoices += formValues?.[fieldName]?.length || 0;
        }
      }
    });

    return {
      user: authUser,
      event: currentEvent,
      name: values.status === 'published' ? ANALYTICS_RECRUITER.PUBLISHED_OFFER : ANALYTICS_RECRUITER.DRAFTED_OFFER,
      properties: {
        'Matching criteria': customFilters.length > 0,
        'Matching Criteria Quantity': customFilters.length,
        'Matching Selections Quantity': countChoices,
        'My Offer': formValues._user === authUser._id,
        'Edited recruiter': !isEmpty(offer) && offer._user && values._user !== ((offer._user._id && offer._user._id.toString()) || offer._user),
      },
    };
  }

  const updateOffer = (values) => {
    const formValues = values;

    if (onSubmit) {
      onSubmit({ ...formValues, _id: offer._id }, () => {
        track(trackData(values))
      });
    } else {
      patchOffer({
        offerId: offer._id,
        offerParams: formValues,
        _event: currentEvent._id,
        notificationParams: {
          success: {
            message: t({ id: 'event.recruiter.preparation.offer.form.notification.success.submit' }),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: track(trackData(values)),
      });
    }
  }

  const createOffer = (values) => {
    const formValues = values;
    if (values._user === undefined) {
      formValues._user = authUser._id;
    }
    if (onSubmit) {
      onSubmit(formValues, () => {
        track(trackData(values))
      });
    } else {
      postOffer({
        offerParams: {
          ...formValues,
          _organization: authUser._currentOrganization._id,
        },
        eventId: currentEvent._id,
        notificationParams: {
          success: {
            message: t(messages.offerFormNotificationSuccessAddition),
            kind: 'info',
            style: {
              bottom: '5%',
              top: 'inherit',
            },
          },
          error: true,
        },
        callback: track(trackData(values)),
      });
      setCurrentOfferValues(null)
    }
  }


  const handleOnSubmit = (values) => {
    if (!isEmpty(offer) && offer._id) {
      updateOffer({ ...values, atsReference: atsOfferSelected?.atsReference ?? null });
    } else {
      createOffer({ ...values, atsReference: atsOfferSelected?.atsReference ?? null });
    }
    handleClose(false);
  };

  const getUsers = () => {
    return (exponentWithUsers ? exponentWithUsers.users.filter((item) => item._user).map((item) => item._user) : [])
  }

  if (isEmpty(authUser)) {
    return null;
  }
  
  const getEventContracts = (event) => {
    const eventContractCriteria = event._criteria.find(({ key }) => key === CRITERION_KEY.contract);
    const availableContractCriteria = eventContractCriteria._choices.filter(({ enable }) => 
      enable).map(({ _id }) => _id);
    
    return availableContractCriteria;
  }

  const handleAtsOffer = (offer) => {
    const availableEventContracts = getEventContracts(currentEvent);
    const atsContracts = offer.contracts.filter(({ _id }) => availableEventContracts.includes(_id)).map(({ _id }) => _id);
    
    setAtsOfferSelected(offer);
    
    const availableDurationCriteria = currentEvent._criteria.find((criterion) => criterion.key === CRITERION_KEY.duration)?._choices || [];
    const visibleDurationCritetia = availableDurationCriteria.map(({ _id }) => _id);

    setCurrentOffer({
      title: offer.title,
      description: offer.description,
      profileDescription: offer.profileDescription,
      createdAt: offer.createdAt,
      matching: { filters: [...atsContracts] },
      DURATION: visibleDurationCritetia.includes(offer.duration._id) ? [offer.duration._id] : [],
      CONTRACT: atsContracts,
      locations: offer.locations.map((location) => ({
        name: location.formattedAdress,
        placeId: location.gmapsPlaceId,
        value: location.gmapsPlaceId,
        coordinates: location.geometry.coordinates
      }))
    })
  }

  return (
    <ModalBig
      isOpen={isOpen}
      onClose={handleClose}
      sideContent={getCurrentHelpContent}
      offerFormHeight={offerFormHeight}
    >
      <Modal
        visible={hasConfirmModal}
        footer={false}
        maskClosable
        width={470}
        className="customConfirm"
        onCancel={closeConfirmModal}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={closeConfirmModal}>
          <Icon name="close" className="modal-close-icon" />
        </a>

        <h4
          className="ant-confirm-title">{['draft', 'expired'].includes(offer?.status) ? t(messages.offerFormWarningClose) : t(messages.offerPublishedFormUpdate)}</h4>

        <Separator height={30} />

        <div className="confirm-actions">
          <Button onClick={closeConfirmModal}>{t(messages.exit)}</Button>
        </div>
      </Modal>

      <div className="modalBigWrapper">
        <h1 className="mainTitle">{offer === null ? t(messages.offerFormAdd) : t(messages.offerFormUpdate)}</h1>
        {isOwner && offer?.status === 'published' &&
          <Alert>{t({ id: 'owner.modal.offer.alert.edit' })}</Alert>}
        <div className={styles.offerWrapper}>
          {isOpen && (
            <>
              <CreateOfferForm
                handleAtsOffer={handleAtsOffer}
                onRemoveAtsOffer={() => setAtsOfferSelected(null)}
                atsOfferSelected={atsOfferSelected}
                isAtsOfferLoading={jfAtsOfferQuery.isLoading}
                event={currentEvent}
                user={authUser}
                offer={currentOffer}
                users={getUsers()}
                onSubmit={handleOnSubmit}
                onOpen={onPreview}
                getOfferFormHeight={getOfferFormHeight}
                showHelper={hasHelperContent}
                disableRegisterOffer={disableRegisterOffer}
                publishedOffers={publishedOffers}
                maxOffers={maxOffers}
                warningOfferLimit={warningOfferLimit}
                isOwner={isOwner}
              />
            </>
          )}
        </div>
      </div>
    </ModalBig>
  );
}

CreateOfferModal.propTypes = {
  isOpen: bool,
  onClose: func,
  authUser: object,
  currentEvent: object,
  postOffer: func,
  onPreview: func,
  patchOffer: func,
  offer: object,
  exponent: object,
  intl: object,
  disableRegisterOffer: bool,
  maxOffers: int,
  publishedOffers: array,
  warningOfferLimit: any,
  onSubmit: any,
  isOwner: bool,
}

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  currentEvent: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  postOffer: offerActions.postOffer,
  patchOffer: offerActions.patchOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(toJS(CreateOfferModal));
