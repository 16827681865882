import React from 'react';
import { ItemCard, IconBriefcase, IconMapPin } from '@seekube-tech/ui-kit';
import styles from './styles.less';
import { useFormatMessage } from 'react-intl-hooks';
import { getLocale } from '@/utils/localStorage';
import { bool, boolean, func, object } from 'prop-types';
import moment from 'moment';

const AtsOfferItem = ({
  action,
  selectable = false,
  offer,
  selected,
  onSelectOffer
}) => {
  const locale = getLocale();
  const t = useFormatMessage();
  const published = moment(offer.createdAt).format('DD/MM/YYYY');
  const { 
    contracts,
    title,
    atsReference,
  } = offer;
  const location = `${offer.locations[0]?.city}, ${offer.locations[0]?.country}`;

  return (
    <ItemCard
      bottomLeftElement={
        <div className="flex gap-16">
          <div>
            {contracts.map((contract) => (
              <div className="flex items-center gap-4 text-neutral-400 font-medium" key={`contract-${contract._id}`}>
                <IconBriefcase
                  size={16}
                  className="text-neutral-300" 
                /> 
                {contract[`label_${locale}`] || contract.label}
              </div>
              ))
            }
          </div>
          <div className="flex items-center gap-4 text-neutral-400 font-medium">
            <IconMapPin size={16} className="text-neutral-300" /> {location}
          </div>
        </div>}
      bottomRightElement={<div className="text-neutral-400">{t({ id: 'publishedAt' })} {published}</div>}
      selectable={selectable}
      topRightElement={action}
      selected={selected}
      onSelect={() => onSelectOffer(offer)}
    >
      <span className={styles.atsOfferTitleContainer}>
        <span className="mr-8">{title}</span>
        <span className={styles.atsId}>#{atsReference}</span>
      </span>
    </ItemCard>
  )
}

export { AtsOfferItem };

AtsOfferItem.propTypes = {
  action: func,
  selectable: bool,
  offer: object,
  selected: boolean,
  onSelectOffer: func
}
