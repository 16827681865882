import { Body1 } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import Checkbox from '@/components/Checkbox';
import Html from '@/components/Html';

const FormItem = Form.Item;

export const Cgu = ({ form }) => {
  const t = useFormatMessage();

  return (
    <>
      <FormItem className="mb-20">
        <div className="mb-20 leading-[15px]">
          {form.getFieldDecorator('cgu', {
            rules: [{ required: true, message: t({ id: 'form.mandatory' }) },
            ],
            validateTrigger: 'onSubmit',
          })(
            <Checkbox validations={['required']}>
              <Body1 color={colors.neutral['500']} className="inline">
                <Html value={t({ id: 'cgu' })} />
              </Body1>
            </Checkbox>
          )}
        </div>
      </FormItem>
    </>
  )
}
