import React, { useState } from 'react';
import styles from './styles.less';
import { getId } from '@/utils/global';
import { Tooltip } from '@/components/Tooltip';
import { Body2, Button, Subtitle1 } from '@seekube-tech/ui-kit';
import { Contracts, Locations } from '@/helpers/criterion';
import { get } from 'lodash';
import { useFormatMessage } from 'react-intl-hooks';
import { useGetOfferByReferenceJobFactory } from '@/features/ats/api/useGetOfferByReferenceJobFactory';
import { useUpdateOffer } from '@/queries/offers/useUpdateOffer';

const OfferHeader = ({
  offer
}) => {
  const t = useFormatMessage();
  
  if (!offer) {
    return null;
  }
  
  const [atsOfferSelected, setAtsOfferSelected] = useState(null);
  
  const { mutate: removeAtsLink, isLoading: isRemoveLinkLoading } = useUpdateOffer({
    eventSlug: offer._event?.slug,
    organizationId: offer._organization?._id,
    onSuccess: () => {
      setAtsOfferSelected(null);
    }})
  
  const { isLoading } = useGetOfferByReferenceJobFactory({
    atsReference: offer.atsReference,
    onSuccess: (data) => {
      setAtsOfferSelected(data);
    }
  })
  
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <a href={`/client/offer-edit?offerId=${getId(offer)}&preview=true`} target="_blank">
          <Tooltip
            title={t({ id: 'recruiter.offers.spread.select.recruiter.tooltip.preview' })}
            color="dark"
            placement="bottom"
          >
            <Subtitle1 fontWeight={800} className={styles.title}>
              {offer.title}
            </Subtitle1>
          </Tooltip>
        </a>
        {atsOfferSelected &&
          <div className={styles.atsOffer}>
            <Body2>{t({ id: 'ats.spreading.atsOffer' })}</Body2>:
            <Subtitle1 fontWeight={800} className={styles.title}>
              {atsOfferSelected?.title}
            </Subtitle1>
            <Body2>{offer.atsReference}</Body2>
          </div>
        }
        <div className={styles.offerInformations}>
          <Contracts matching={offer.matching} _criteria={get(offer, '_event._criteria')} />
          <Locations locations={offer.locations} />
        </div>
      </div>
      {atsOfferSelected &&
        <Button 
          loading={isLoading || isRemoveLinkLoading} 
          disabled={isLoading || isRemoveLinkLoading} 
          className={styles.deleteLink} 
          variant="outline" 
          size="small" 
          color="neutral"
          onClick={() => removeAtsLink({ atsReference: null, offerId: offer._id })}
        >
          {t({ id: 'ats.removeLink' })}
        </Button>
      }
    </div>
  )
}

export { OfferHeader };