import React from 'react';
import styles from './styles.less';
import { Alert, Body1, H4 } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';

const Title = () => {
  const t = useFormatMessage();
  
  return (
    <div className={styles.titleSection}>
      <div>
        <H4>{t({ id: 'ats.modale.title' })}</H4>
        <Body1 className={styles.subTitle}>{t({ id: 'ats.modale.subtitle' })}</Body1>
      </div>
    </div>
  )
}

export { Title };