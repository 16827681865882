import React, { useEffect, useRef, useState } from 'react';
import { object } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import Wrapper from '@/components/Wrapper';
import { useDispatch } from 'react-redux';
import { authLoginSSOAdmin, authLoginSuccess } from '@/store/auth/actions';
import { Body1, IconLoader, IconLoader2, Loader } from '@seekube-tech/ui-kit';
import styles from './styles.less';
import LoadingIndicator from '@/components/LoadingIndicator';
import { useGetLoginAuto } from '@/queries/login/useGetLoginAuto';
import { push } from 'connected-react-router';

const RedirectAuth = ({
}) => {
  const t = useFormatMessage();
  const dispatch = useDispatch();

  useGetLoginAuto({
    onSuccess: (user) => {
      dispatch(authLoginSuccess({ user }));
      dispatch(push(window.atob(location.query.redirect_uri)));
    }
  });

  return (
    <Wrapper size="large">
      <div className={styles.container}>
        <IconLoader2 className='animate-spin'/>
        <Body1>Vous allez être redirigé dans quelques instants...</Body1> 
      </div>
    </Wrapper>
  )
}

export default RedirectAuth;