import { trackCandidateClickedOnCardToSignup } from '@/utils/analytics';
import { isEmpty } from 'lodash';

export const onClickLandingCard = (authUser, event, from, postParticipant) => {
  const userProvider = window.localStorage.getItem('user_provider');
  
  if (!authUser || isEmpty(userProvider)) {
    return;
  }
  
  trackCandidateClickedOnCardToSignup({ from });
  
  postParticipant({
    eventId: event._id,
    slug: event.slug,
    toPost: {
      _event: event._id,
      userProvider: JSON.parse(userProvider),
    },
  });
}