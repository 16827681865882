import React from 'react';
import { bool, func, object } from 'prop-types';
import { Field, useForm, useFormState } from 'react-final-form';
import { createStructuredSelector } from 'reselect';
import { authSelectors } from '@/store/auth';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LOCALES } from '@/utils/constants';
import { toJS } from '@/utils';
import { getCriterionLabel } from '../utils';
import { Select } from '@seekube-tech/ui-kit';
import Icon from '@/components/Icon';

function PositionField({ criterion, showHelper, authUser, disabled = false, required = true }) {
  const formState = useFormState();
  const form = useForm();
  const label = getCriterionLabel(criterion);

  /**
   * Transform options for Tree field
   * @returns {Array<Object>}
   */
  const getOptions = () => {
    const { _choices } = criterion;

    const choices = _choices.filter((c) => c?.enable);

    let hashMap = {};
    const children = [];

    choices.forEach((choice) => {
      if (choice._parent) {
        children.push(choice);
      } else {
        hashMap = {
          ...hashMap,
          [choice._id]: {
            disabled: true,
            label: authUser.locale === LOCALES.en && choice.label_en ? choice.label_en : choice.label,
            value: choice._id,
            children: [],
          },
        };
      }
    });

    children.forEach((child) => {
      if (hashMap[child._parent]) {
        hashMap[child._parent].children.push({
          disabled: child.enable === false,
          label: authUser.locale === LOCALES.en && child.label_en ? child.label_en : child.label,
          value: child._id,
        });
      }
    });

    return Object.values(hashMap);
  };
  
  const onChange = (value) => {
    form.change(criterion.key, value);
  }

  return (
    <div>
      <Field
        name={criterion.key}
        id={criterion.key}
      >
        {() => (
          <Select
            disabled={disabled}
            name={criterion.key}
            id={criterion.key}
            placeholder={label}
            multiple
            value={formState.values?.[criterion.key] ?? []}
            label={label}
            required={required}
            options={getOptions()}
            onChange={onChange}
            leftIcon={<Icon name={criterion.icon} style={{ width: '24px', height: '24px' }} />}
          />
          )}
      </Field>
    </div>
  );
}

PositionField.propTypes = {
  criterion: object,
  showHelper: func,
  authUser: object,
  disabled: bool
};

PositionField.defaultProps = {
  criterion: {},
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser
})

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withConnect
)(toJS(PositionField));
