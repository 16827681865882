import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { add } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { eventSelectors } from '@/store/event';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { toJS } from '@/utils';
import { reduceWithOperator } from '@/utils/math';

import { Iframe } from '@/components/Iframe';

import Stats from './components/Stats';

import { refresh } from '../../requests';
import { authSelectors } from '@/store/auth';

const ParticipantsScene = ({
  event,
  stats: { exponentsActivitiesStats, exponentsStats, appointmentsStats, participantsStats },
}) => {
  useEffect(() => {
    refresh({ eventId: event.slug });
  }, []);
  

  const applications = reduceWithOperator(exponentsActivitiesStats, 'applications', add);
  const informal1to1 = reduceWithOperator(exponentsActivitiesStats, 'informal1to1', add);

  const eventStats = {
    allAppointments: add(applications, informal1to1),
    averageApplications: 0,
    sourcingAppointments: reduceWithOperator(exponentsActivitiesStats, 'sourcingAppointments', add),
    sourcingAppointmentsCoreTarget: reduceWithOperator(exponentsActivitiesStats, 'sourcingAppointmentsCoreTarget', add),
    acceptedApplications: reduceWithOperator(exponentsActivitiesStats, 'acceptedApplications', add),
    totalPropositions: 0,
    averagePropositions: 0,
    averageParticipantsAppointments: 0,
  };

  return (
    <div>
      <Stats stats={{ ...exponentsStats, ...appointmentsStats, ...participantsStats, ...eventStats }} event={event} />
      <Iframe src={`https://redash.sawkiube.fr/embed/query/44/visualization/91?api_key=GjRWZQ54fNP6ww9TzHCsVw2ohc8Giv5W28CqFHn7&p_eventId=${encodeURIComponent(event.slug)}&hide_parameters&hide_header&hide_link`} />
    </div>
  );
};

ParticipantsScene.propTypes = {
  event: object,
  stats: object,
};

const mapStateToProps = createStructuredSelector({
  event: eventSelectors.getCurrentEvent,
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(ParticipantsScene);
