import { useQuery } from 'react-query';

import request from '@/utils/request';

export function useGetOrganizationUserContracts({ organizationId, userId, ...options }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${userId}/offers/contracts`

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['organizations', organizationId, 'user', userId, 'offerContracts'],
    queryFn,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options
  });

  return query;
}
