import { isEmpty } from 'lodash';
import { checkRegex } from '@/utils/checkRegex';

export const isPasswordPolicyCompliant = (value) => {
  const password = value;

  const matchMinCharacters = !isEmpty(password) && password.length >= process.env.FRONT_APP_PASSWORD_MIN_LENGTH && password.length <= process.env.FRONT_APP_PASSWORD_MAX_LENGTH;
  const matchSpecialCharacters = checkRegex(/[#?!@$%^&*+-]/, password);
  const matchNumber = checkRegex(/\d/, password);
  const matchUpperCase = checkRegex(/[A-Z]/, password);
  const matchLowerCase = checkRegex(/[a-z]/, password);

  return matchMinCharacters && matchSpecialCharacters &&
    matchNumber && matchUpperCase && matchLowerCase;
};
