import React from "react";
import { useFormatMessage } from "react-intl-hooks";
import { string } from "prop-types";
import { IconDownload, Button } from "@seekube-tech/ui-kit";

const ExportBtn = ({ slug }) => {
  const t = useFormatMessage();

  const handleExportRecruitersNotReady = () =>
    window.open(
      `${process.env.FRONT_EXPORT_API_URL}/events/${slug}/export/recruiters-not-ready?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`,
      "_blank"
    );

  return (
    <Button
      variant="outline"
      color="neutral"
      imageComponentLeft={<IconDownload size={16} />}
      onClick={handleExportRecruitersNotReady}
    >
      {t({ id: "event.owner.exponents.export.btn" })}
    </Button>
  );
};

ExportBtn.propTypes = {
  slug: string,
};

export { ExportBtn };
