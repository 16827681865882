import { useFormatMessage } from 'react-intl-hooks';
import { isArray, toString } from 'lodash';

import email from './email';
import filled from './filled';
import max from './max';
import min from './min';
import url from './url';
import phoneNumber from '@/utils/validations/isPhoneNumber';
import { isWysiwygFieldCompleted } from '@/components/WysiwygEditor';

function useValidations() {
  /* Vars */

  const t = useFormatMessage();

  /* Functions */

  const isEmail = (value, message) => email(value) === false ?
    (message || t({ id: 'form.error.email.format' })) : null;

  const isFilled = (value, message) => filled(value) === false ?
    (message || t({ id: 'form.error.required' })) : null;
  
  const isWysiwygFilled = (value, message) => isWysiwygFieldCompleted(value) === false ?
    (message || t({ id: 'form.error.required' })) : null;

  const isMax = (value, number, message) => max(value, number) === false ?
    (message || t({ id: 'form.error.max' }, { count: number })) : null;

  const isMin = (value, number, message) => min(value, number) === false ?
    (message || t({ id: 'form.error.min' }, { count: number })) : null;

  const isUrl = (value, message) => url(value) === false ?
    (message || t({ id: 'form.error.url.format' })) : null;

  const isMissingInOptions = (values, choices, message) => {
    let exist = false;

    if (isArray(values)) {
      values.forEach((val) => {
        if (choices.findIndex((c) => toString(c._id) === toString(val)) > -1) {
          exist = true;
        }
      })
    } else if (choices.findIndex((c) => toString(c._id) === toString(values)) > -1) {
      exist = true;
    }

    if (exist === false) {
      return message || t({ id: 'form.error.required' })
    }

    return null;
  }
  
  const isPhoneNumber = (value, message) => phoneNumber(value) === false ?
      (message || t({ id: 'form.error.phone.format' })) : null;

  /* Return */

  return {
    isEmail,
    isFilled,
    isWysiwygFilled,
    isMax,
    isMin,
    isUrl,
    isMissingInOptions,
    isPhoneNumber
  };
}

export default useValidations;
