import { IconContract, Select } from '@seekube-tech/ui-kit';
import { Field, useForm, useFormState } from 'react-final-form';
import React, { useEffect } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useGetOrganizationUserContracts } from '@/queries/contracts/useGetOrganizationUserContracts';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';
import { isEmpty } from 'lodash';
import useValidations from '@/utils/validations/useValidations';
import { string, object } from 'prop-types';

const OfferContractsForm = ({
  organization,
  atsOfferImported,
  userFullName,
  initialValue,
  name = 'matching.filters',
  userFieldName = '_user',
  userValue
}) => {
  const t = useFormatMessage();
  const form = useForm();
  const formState = useFormState();
  const userId = userValue ?? formState.values[userFieldName];
  const authUser = useSelector(getAuthUser)?.toJS();
  const { data: contracts, isLoading } = useGetOrganizationUserContracts({
    organizationId: organization?._id ?? organization,
    userId,
    enabled: !isEmpty(userId)
  });
  const shouldDisableContracts = !userId;
  
  const isContractUnselectable = atsOfferImported && isEmpty(formState.values['_user']);
  let options = [];
  const contractsToMap = (isContractUnselectable ? atsOfferImported.contracts : contracts?.contracts ?? []);

  useEffect(() => {
    if (!isLoading && contracts?.countEvents === 0) {
      form.change(name, []);
    }
  }, [contracts]);
  
  const { isFilled } = useValidations();
  
  options = contractsToMap?.map((contract) => ({
   value: contract._id,
   label: contract[`label_${authUser.locale}`] || contract.label
 })) ?? [];
  
  const onChange = (val) => {
    form.change(name, val);
  }
  
  const hasFieldError = () => {
    if (!isEmpty(formState.errors?.matching?.filters) && formState.modified?.matching?.filters) {
      return true
    }
    if (userId && !isLoading && !contracts?.countEvents) {
      return true;
    }
  }
  
  const getFieldErrorMessage = () => {
    if (!hasFieldError()) {
      return null;
    }
    if (userId && !isLoading && !contracts?.countEvents) {
      return t({ id: 'offer.create.recruiter.no.contract' }, { fullName: userFullName });
    }
    return formState.errors?.matching?.filters;
  }

  const [prefix, contract] = name.split('.');
  const value = formState.values?.[prefix]?.[contract];
  
  return (
    <Field
      name={name}
      id={name}
      label={t({ id: 'contracts' })}
      value={value}
      initialValue={initialValue}
      validate={(value) => isFilled(value)}
    >
      {() => (
        <Select
          id={name}
          label={t({ id: 'contracts' })}
          name={name}
          options={options}
          leftIcon={<IconContract size={24} />}
          value={value || []}
          onChange={onChange}
          multiple
          disabled={shouldDisableContracts}
          color={hasFieldError() ? 'error' : 'neutral'}
          message={getFieldErrorMessage()}
          required
        />
      )}
    </Field>
  )
}

OfferContractsForm.propTypes = {
  organization: object,
  atsOfferImported: object,
  userFullName: string
}

export { OfferContractsForm }