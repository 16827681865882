import React from 'react';
import { object, func, oneOf, oneOfType, node, bool, any, array } from 'prop-types';
import classnames from 'classnames';
import set from 'lodash/set';
import { isEmpty, isEqual, isFunction, get, find } from 'lodash';
import {
  PARTICIPANT_ROLE,
  USER_ROLE,
  ANALYTICS_RECRUITER,
  APPOINTMENT_ORIGIN,
} from '@/utils/constants'
import { CONTEXTS_ALL, getId } from '@/utils/global';
import { track } from '@/utils/analytics';

// Components
import { Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import Sticky from 'react-sticky-el';
import moment from 'moment/moment';
import Highlighter from 'react-highlight-words';

import { IconsHybrid } from '@/components/IconsHybrid';
import { getCdnUrl } from '@/components/ImgCdn';
import { H2 } from '@/components/Title';
import ParticipantScoring from '@/components/ParticipantScoring';
import Card from '@/components/Card';
import Pdf from '@/components/Pdf';
import { AvatarCandidate } from '@seekube-tech/ui-kit';
import { If } from '@/components/If';

import { getCurrentRole } from '@/store/role/helpers';
import { getParticipantResumeUrl } from "@/helpers/resume";
import { ExternalMedia } from '@/components/ParticipantCards/components/components/ExternalMedia';
import {
  Description,
  Resume,
  Contacts, Links,
  Avatar,
  Stars,
  Matchings,
  Appointment,
  Activity,
  OffersApplied,
  SearchRow,
  Informal1To1Message,
} from '../components'

// Styles & Translations
import messages from './messages';
import styles from './styles.less';



class BigParticipantCard extends React.Component {
  static propTypes = {
    participantActionBtnDisabled: object,
    participant: object,
    viewBy: object,
    onSave: func,
    onDownload: func,
    onDisplay: func,
    from: oneOf(['agenda']),
    currentUser: object,
    appointment: any,
    actions: oneOfType([node, func]),
    displayRating: bool,
    isNew: bool,
    isVisited: bool,
    isHybrid: bool,
    noActions: bool,
    highlightWords: array,
    offers: array,
    interactions: array,
    handleSaveUser: func,
    context: oneOf(CONTEXTS_ALL),
    intl: object,
    functions: object,
    exponent: object,
  };

  static defaultProps = {
    participant: {},
    viewBy: {},
    noActions: false,
    isHybrid: false,
    isNew: false,
    isVisited: false,
    applications: [],
    highlightWords: [],
    currentUser: {},
  };

  state = {
    participant: this.props.participant,
    viewBy: this.props.viewBy,
    viewByTheSameUser: (!isEmpty(this.props.participant) && !isEmpty(this.props.participant._user) && !isEmpty(this.props.viewBy)) ? this.props.participant._user._id === this.props.viewBy._id : false,
    viewByARecruiter: false,
    displayRating: false,
    currentParticipantActionBtnDisabled: {},
  };

  /**
   * Save in the state the participant, in case user cancel edit
   *
   * @param {object} participant
   * @param {object} viewBy
   */
  componentWillReceiveProps({ participant, viewBy }) {
    if (this.props.participant && participant) {
      this.setState({
        participant,
        viewBy,
        viewByTheSameUser: (!isEmpty(participant) && !isEmpty(participant._user) && !isEmpty(viewBy)) ? participant._user._id === viewBy._id : false,
        viewByARecruiter: (!isEmpty(participant) && !isEmpty(viewBy)) ?
          (!isEmpty(viewBy._currentOrganization) || viewBy.roles.includes(PARTICIPANT_ROLE.recruiter) || viewBy.roles.includes(USER_ROLE.SEEKUBE) || viewBy.roles.includes(USER_ROLE.RECRUITER))
          : false,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props: { participant, viewBy, appointment, highlightWords, interactions, offers } } = this;

    if ((participant && participant._user && viewBy && participant._user._id === viewBy._id) || (!isEqual(highlightWords, nextProps.highlightWords) || (!isEqual(participant.visitBy, nextProps.participant.visitBy)))) {
      return true;
    }

    if (!isEmpty(participant) && !isEmpty(nextProps.participantActionBtnDisabled) &&
      !isEqual(nextProps.participantActionBtnDisabled, nextState.currentParticipantActionBtnDisabled) &&
      nextProps.participantActionBtnDisabled[participant._id] === true) {
      this.setState({ currentParticipantActionBtnDisabled: nextProps.participantActionBtnDisabled });

      return true;
    }

    if (participant && !participant._user && nextProps.participant && nextProps.participant._user) {
      return true;
    }

    return (appointment && nextProps.appointment && (appointment.status !== nextProps.appointment.status || appointment._owner !== nextProps.appointment._owner)) || participant._id !== nextProps.participant._id || JSON.stringify(interactions) !== JSON.stringify(nextProps.interactions) || JSON.stringify(offers) !== JSON.stringify(nextProps.offers);
  }


  /**
   * Handle changes from differents child components and send to the parent the new object
   *
   * @param {string} key
   *  path of the key to update in participant object
   */
  handleOnChange = (key) => (value) => {
    const { state: { participant, viewByTheSameUser }, props: { onSave, handleSaveUser } } = this;
    // Only the same user can edit his profile
    if (!viewByTheSameUser) {
      return;
    }

    // Create a copy of participant and update values
    const newParticipant = { ...participant };
    set(newParticipant, key, value);

    // Patch participant
    const patchParams = {
      participantParams: newParticipant,
      eventId: participant._event._id,
      participantId: participant._id,
    };

    // Update the current state
    this.setState({ participant: newParticipant }, () => {
      if (key === '_user.resumeUrl') {
        handleSaveUser({
          userId: participant._user.id,
          userParams: {
            resumeUrl: value,
          },
        });
      } else {
        onSave(patchParams);
      }
    });
  };

  /**
   * Handle changes from differents child components and send to the parent the new object
   *
   * @param {string} key
   *  path of the key to update in user object
   */
  handleOnUpdateUserFields = (key) => (value) => {
    const { props: { handleSaveUser }, state: { participant } } = this;
    const userParams = {};

    set(userParams, key, value);

    const user = {
      userId: participant._user._id,
      userParams,
    };

    handleSaveUser(user);
  };

    handleOnDownloadPdf = () => {
    const { currentUser, participant, onDownload } = this.props;

    if (typeof onDownload === 'function') {
      onDownload(participant);
    }

    track({
      name: ANALYTICS_RECRUITER.DOWNLOADED_CV,
      user: currentUser,
      event: participant._event,
      properties: {
        roles: getCurrentRole(currentUser.roles, currentUser._currentOrganization._id)?._role?.key,
      },
    });
  };

  handleOnScore = (score, appointment, withMessage) => {
    const { props: { functions }, state: { participant } } = this;
    const scoring = { score, appointmentId: getId(appointment) };
    const statusSorted = ['score0', 'score1', 'score2'];

    if (score === 0 && withMessage) {
      functions.openModal(null, appointment, participant, 'negativeScoring');
    } else {
      functions.moveParticipant(null, participant, statusSorted[score], scoring);
    }
  };

  render() {
    const {
      props: {
        currentUser, actions, viewBy, displayRating, appointment, onDisplay, highlightWords, isHybrid,
        context, noActions, intl, isNew, isVisited, interactions, exponent,
      },
      state: { participant },
      handleOnChange, handleOnUpdateUserFields,
      handleOnDownloadPdf,
    } = this;


    // Display the component only when we participanthave all informations
    if (isEmpty(participant) || isEmpty(participant._user)) {
      return null;
    }
    const viewByARecruiter = !isEmpty(participant) && !isEmpty(viewBy) && !isEmpty(viewBy._currentOrganization);

    const score = appointment && (appointment._organizationUser._id === this.props.viewBy._id) ? appointment.rating : null;
    const emptyUser = {
      pictureUrl: '',
      firstName: '',
      lastName: '',
      title: '',
      fullName: '',
      positions: [],
      educations: [],
      links: [],
      resumeUrl: '',
    };

    const _user = participant._user ? participant._user : emptyUser;
    const interactionAuthUser = find(interactions, (interaction) => interaction.enable && ((currentUser._id === get(interaction, '_recruiter._id')) || (currentUser._id === get(interaction, '_owner._id'))));
    const interactionEnable = find(interactions, (interaction) => interaction.enable);

    const renderDescription = () => (participant._user.description ? <Description description={participant._user.description} /> : null);
    const renderExternalMedia = () => <ExternalMedia participant={participant} />;

    const renderResume = () => (
      <Resume
        size="big"
        positions={_user.positions}
        educations={_user.educations}
        highlightWords={highlightWords}
      />
    );

    const renderLinks = () => (
      <Links
        links={_user.links}
        onChange={handleOnUpdateUserFields('links')}
      />
    );

    const cv = (!isEmpty(_user.resumeUrlLight) || !isEmpty(_user.resumeUrl) || !isEmpty(participant._user.resumeS3Key)) ? (
      <Pdf
        name="CV"
        downloadSrc={getParticipantResumeUrl(participant, participant._event.slug, false)}
        src={getParticipantResumeUrl(participant, participant._event.slug, true)}
        onChange={handleOnChange('_user.resumeUrlLight')}
        onDownloadPdf={handleOnDownloadPdf}
        showTop
        participant={participant}
      />) : '';

    const renderActivity = () => <Activity interactions={interactions} organization={currentUser._currentOrganization} participant={participant} />;
    const renderPostulationOffers = () => <OffersApplied offers={this.props.offers} />;
    const renderAppointment = () => {
      if ((interactionAuthUser && interactionAuthUser.type === 'application-auto') || ['refused', 'canceled'].includes(get(appointment, 'status'))) return '';
      return interactionAuthUser || appointment ? <Appointment className={styles.appointment} context={context} appointment={(interactionAuthUser && interactionAuthUser._appointment) || appointment} authUser={currentUser} /> : '';
    };
    const body = (
      <div className={styles.bodyContainer}>
        {/* Resume */}
        <>
          <div className={classnames(styles.resumeContainer, noActions ? styles.onBoarding : '')}>
            {renderExternalMedia()}
            {renderDescription()}
            {renderResume()}
          </div>
          {renderLinks()}
        </>
      </div>
    );

    const isConfirmedContext = context === 'confirmed';
    const isConfirmedOrUnconfirmedContext = context === 'confirmed' || 'unconfirmed';
    const displayHybridIcons = isHybrid && (location.pathname.includes('owner') || exponent?.keyMomentFormats?.length > 1);
    const formatParticipant = participant.keyMomentFormats?.length > 1 ? 'all' : participant.keyMomentFormats?.[0];
    const displayScoring = interactionEnable && isConfirmedContext && moment(interactionEnable._appointment?.date).add(15, 'minutes') <= moment()

    return (
      <Card
        containerClassName={styles.candidateProfileContainer}
        contentClassName={classnames(styles.noPadding, styles.content)}
        borderPosition="none"
        size="big"
      >
        <div className={styles.leftSide}>
          {location.pathname.indexOf('/checking') === -1 ?
            <Sticky stickyClassName={styles.stickyLeft} holderCmp="span" scrollElement=".ant-modal-wrap">
              <div className={styles.topContainer} style={{ paddingBottom: '0' }}>
                {/* Left */}
                <div className={styles.avatarWrapper}>
                  <AvatarCandidate
                    size={130}
                    user={participant._user}
                    pictureUrl={getCdnUrl(participant._user.pictureUrl, 130) ?? ''}
                    componentBottom={displayHybridIcons && <IconsHybrid name={participant?._user?.shortName} hybridFormat={formatParticipant} />}
                  />
                </div>

                {/* Right */}
                <div className={classnames(styles.candidateContainer, 'candidateContainer')}>
                  <div className={styles.nameContainer}>
                    {/* FirstName */}
                    <div>
                      <H2 className={classnames(styles.name, isVisited ? styles.visited : '')}>
                        <Highlighter
                          highlightClassName="highlightText"
                          searchWords={highlightWords}
                          autoEscape
                          textToHighlight={_user.fullName}
                        />
                        {displayRating && score > 0 ? <Stars disabled value={score} count={3} /> : ''}
                      </H2>

                      <h4 className={styles.title}>
                        {_user.title && _user.title.length > 0 ?
                          <Highlighter
                            highlightClassName="highlightText"
                            searchWords={highlightWords}
                            autoEscape
                            textToHighlight={_user.title}
                          />
                          : '-'}</h4>

                      {/* Contacts */}
                      {(!isEmpty(_user.contact) || !isEmpty(_user.phone)) && (
                        <Contacts
                          containerClassName={styles.contactInfosContainer}
                          onChange={handleOnChange}
                          appointmentStatus={appointment ? appointment.status : ''}
                          email={_user.username}
                          phone={_user.contact ? _user.contact.phone : _user.phone}
                          displayContacts={!!noActions}
                          onDisplay={onDisplay}
                        />
                      )}

                      {isNew && (context === 'applications') && <div className={styles.isNew}>{intl.formatMessage(messages.isNewApplications)}</div>}
                      {isNew && (context === 'sourcing') && <div className={styles.isNew}>{intl.formatMessage(messages.isNewSourcing)}</div>}
                    </div>
                  </div>
                </div>

                {/* Actions */}
                <div className={styles.actionsContainer}>
                  {isFunction(actions) ? actions() : actions}
                </div>

              </div>
            </Sticky>
            : <div className={styles.topContainer} style={{ paddingBottom: 0 }}>
              {/* Left */}
              <div className={styles.avatarWrapper}>
                <Avatar
                  pictureUrl={participant._user ? participant._user.pictureUrl : ''}
                  onChange={handleOnUpdateUserFields('pictureUrl')}
                  viewByARecruiter={viewByARecruiter}
                  objectId={participant._user._id}
                  user={participant._user}
                />
              </div>

              {/* Right */}
              <div className={styles.candidateContainer}>
                <div className={styles.nameContainer}>
                  {/* FirstName */}
                  <div>
                    <H2 className={classnames(styles.name, isVisited ? styles.visited : '')}>
                      <Highlighter
                        highlightClassName="highlightText"
                        searchWords={highlightWords}
                        autoEscape
                        textToHighlight={_user.fullName}
                      />
                      {displayRating && score > 0 ? <Stars disabled value={score} count={3} /> : ''}
                    </H2>
                    <h4 className={styles.title}>
                      {_user.title && _user.title.length > 0 ?
                        <Highlighter
                          highlightClassName="highlightText"
                          searchWords={highlightWords}
                          autoEscape
                          textToHighlight={_user.title}
                        />
                        : '-'}</h4>

                    {/* Contacts */}
                    {(!isEmpty(_user.contact) || !isEmpty(_user.phone)) && (
                      <Contacts
                        onChange={handleOnChange}
                        handleUpdateUserFields={handleOnUpdateUserFields}
                        appointmentStatus={appointment && appointment.status ? appointment.status : ''}
                        email={_user.username}
                        phone={_user.contact ? _user.contact.phone : _user.phone}
                        displayContacts={!!noActions}
                        onDisplay={onDisplay}
                      />
                    )}

                    {isNew && context === 'applications' && <div className={styles.isNew}>{intl.formatMessage(messages.isNewApplications)}</div>}
                    {isNew && context === ['sourcing'] && <div className={styles.isNew}>{intl.formatMessage(messages.isNewSourcing)}</div>}
                  </div>
                </div>
              </div>

              {/* Actions */}
              <div className={styles.actionsContainer}>
                {isFunction(actions) ? actions() : actions}
              </div>
            </div>}

          {/* Search */}
          { !noActions && appointment?.origin === APPOINTMENT_ORIGIN.informal1to1 && <Informal1To1Message {...appointment} />}

          {!noActions && <SearchRow participant={participant} authUser={currentUser} />}
          <Row gutter={16}>
            <Col span={12}>
              <Matchings
                participant={participant}
                highlightWords={highlightWords}
                authUser={currentUser}
              />
            </Col>
          </Row>

          {body}
          {cv}
        </div>
        { !noActions && viewBy._currentOrganization && (
          <div className={classnames(styles.rightSide, styles[context])}>
            <Sticky scrollElement=".ant-modal-wrap" topOffset={30} stickyClassName={styles.stickyRight}>
              <If condition={(!participant._event.skipAppointmentValidation || participant._event.areInteractionsClosed) && displayScoring && !isEmpty(viewBy._currentOrganization) && interactionAuthUser}>
                <div className={styles.scoreHeaderContainer}>
                  <ParticipantScoring
                    {...participant}
                    appointment={((interactionAuthUser && interactionAuthUser._appointment) || appointment)}
                    className={styles.scoring}
                    context="bigParticipantCard"
                    onScore={this.handleOnScore}
                    withInfo={false}
                  />
                </div>
              </If>
              {!participant._event.areInteractionsClosed && isConfirmedOrUnconfirmedContext && renderAppointment()}
            </Sticky>
            {participant._event.areInteractionsClosed && isConfirmedOrUnconfirmedContext && renderAppointment()}
            {renderActivity()}
            {renderPostulationOffers()}
          </div>
        )}
      </Card>
    );
  }
}

export default injectIntl(BigParticipantCard);
