import React from 'react';
import { string, func } from 'prop-types';
import { Caption1, Body1, IconAlertTriangle } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import classnames from 'classnames';
import { useFormatMessage } from 'react-intl-hooks'
import { SLOT_TYPE } from '@/utils/constants'

import useStyles from './styles';

const PickSlotType = ({ slotType, handleChangeType, event, user }) => {
  const classes = useStyles();
  const t = useFormatMessage();

  return (
    <>
    <div
      role="button"
      tabIndex={0}
      onClick={() => handleChangeType(SLOT_TYPE.INTERVIEW)}
      className={classnames(classes.root,
        { [classes.selected]: slotType !== SLOT_TYPE.INFORMAL1TO1 } )}
    >
      <Body1 color={slotType !== SLOT_TYPE.INFORMAL1TO1 ? colors.primary['500']: colors.neutral['500']}>
        <span style={{ fontWeight: 700 }}>{t({ id: 'interview.pl'}, { count: 0 })}</span>
      </Body1>
      <Caption1 color={colors.neutral['400']}>
        {t({ id: 'calendar.settingsSlot.pickType.interview.description' })}
      </Caption1>
    </div>
    <div
      role="button"
      tabIndex={0}
      onClick={() => handleChangeType(SLOT_TYPE.INFORMAL1TO1)}
      className={classnames(classes.root,
        { [classes.selected]: slotType === SLOT_TYPE.INFORMAL1TO1 } )}
    >
      <Body1 color={slotType === SLOT_TYPE.INFORMAL1TO1 ? colors.primary['500']: colors.neutral['500']}>
        <span style={{ fontWeight: 700 }}>{event.modules.informal1to1[user.locale === 'fr' ? 'label_fr' : 'label_en']}</span>
      </Body1>
      <Caption1 color={colors.neutral['400']}>
        {t({ id: 'calendar.settingsSlot.pickType.informal1to1.description' })}
      </Caption1>
    </div>
      {
        slotType === SLOT_TYPE.INFORMAL1TO1 && (
          <Caption1 className="text-warning-600">
            <IconAlertTriangle size={16} className="mr-4 relative bottom-2" />
            {t({ id: 'calendar.settingsSlot.pickType.informal1to1.warning' })}
          </Caption1>
        )
      }
    </>
  )
}

PickSlotType.propTypes = {
  slotType: string,
  handleChangeType: func,
}

export { PickSlotType }
