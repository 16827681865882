import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import { withAuth } from '@/containers/RequiredAuth';
import { NewEventsScene } from '@/scenes/Events/scenes/NewEvents';
import { ArchiveEvents } from '@/scenes/Events/scenes/ArchiveEvents';
import { useGetCheckCandidateOnboardings } from '@/queries/users/useGetCheckCandidateOnboardings';
import { useSelector } from 'react-redux';
import { getAuthUser } from '@/store/auth/selectors';

const EventsScene = ({ match }) => {
  const authUser = useSelector(getAuthUser)?.toJS();
  const history = useHistory();
  
  const checkCandidateOnboardingsQuery = useGetCheckCandidateOnboardings({
    user: authUser,
    onSuccess: ({ currentOnboardings, countOnboardingsCompleted }) => {
      const recentActiveParticipation = currentOnboardings?.[0];

      if (recentActiveParticipation && countOnboardingsCompleted === 0) {
        const { slug } = recentActiveParticipation._event

        history.replace(`/${slug}/candidate/preparation/onboarding/profile`);
      }
    }
  });

  useEffect(() => {
    localStorage.setItem('switch_quit_url', '');
  })

  return (
    <Switch>
      <Route
        path={`${match.path}/archive`}
        render={(props) =>
          !checkCandidateOnboardingsQuery.isLoading && <ArchiveEvents authUser={authUser} {...props} />}
      />
      <Route
        path={`${match.path}`}
        render={(props) =>
          !checkCandidateOnboardingsQuery.isLoading && <NewEventsScene authUser={authUser} {...props} />}
      />
    </Switch>
  );
};

EventsScene.propTypes = {
  match: object,
};

export default compose(
  withAuth(),
)(EventsScene);

