import React from 'react';
import { H4, Subtitle1 } from '@seekube-tech/ui-kit';
import { object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Row } from 'antd';
import { getLocale } from '@/utils/localStorage';
import styles from '@/scenes/Event/containers/Landing/components/Contents/components/Informal1to1s/styles.less';
import useInfiniteGetLandingConferences from '@/queries/landing/useInfiniteGetLandingConferences';
import {
  ShowMoreButton
} from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Exponents/scenes/Exponent/components/ShowMoreButton';
import CardConferences from '@/scenes/Event/containers/Landing/components/Contents/components/Conferences/CardConferences';


const Conferences = ({ event, redirectionHref }) => {
  const t = useFormatMessage();

  const conferencesInfiniteQuery = useInfiniteGetLandingConferences({
    eventId: event._id
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <H4>{event.modules?.live[`labelMultiple_${getLocale()}`]}</H4>
        <Subtitle1 className={styles.subtitle}>
          {t({ id: 'landing.conferences.subtitle' })}
        </Subtitle1>
      </div>

      <Row gutter={[24, 24]}>
        <CardConferences
          conferences={conferencesInfiniteQuery}
          redirection={redirectionHref}
          target="_blank"
        />
      </Row>
      <ShowMoreButton entityInfiniteQuery={conferencesInfiniteQuery} />
    </div>
  );
}

Conferences.propTypes = {
  event: object,
  redirectionHref: string,
}

export { Conferences };
