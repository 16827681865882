import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import GoogleMapLoader from 'react-google-maps-loader';
import useValidations from '@/utils/validations/useValidations';
import Icon from '@/components/Icon';
import { getCriterionLabel } from '../utils';
import { FormAutocompleteField } from '@/components/FinalFormFields/FormAutocompleteField';
import {
  getSelectedLocation
} from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/components/CriteriaFields/components/helpers/locations';

function MobilityField({ criterion, googleMaps, showHelper, disabled = true, required = true }) {
  const { isFilled } = useValidations();
  const formState = useFormState();
  const [options, setOptions] = useState(getSelectedLocation(formState.values?.locations));
  const timeout = useRef();
  const form = useForm();

  const getGoogleMapAddress = (address) => {
    if (googleMaps) {
      const autoCompleteService = new googleMaps.places.AutocompleteService();
      const formatedSelectedLocations = getSelectedLocation(formState.values?.locations);
      const actualSelectedElementsIds = formatedSelectedLocations.map(({ value }) => value);
      
      if (autoCompleteService) {
        autoCompleteService.getPlacePredictions(
          {
            input: address,
            location: new googleMaps.LatLng(0, 0),
            radius: 20,
            types: ['(cities)'],
            componentRestrictions: { country: '' },
          },
          (googleSuggests) => {
            const formatedResults = googleSuggests
              .filter((suggest) =>
                suggest.types.includes('sublocality') === false &&
                suggest.types.includes('postal_code') === false &&
                !actualSelectedElementsIds.includes(suggest.place_id)
              )
              .map((suggest) => ({
                label: suggest.description,
                value: suggest.place_id,
                placeId: suggest.place_id,
              }));

            return setOptions([...formatedSelectedLocations, ...formatedResults]);
          },
        );
        setOptions(formatedSelectedLocations);
      }
    }
  };

  useEffect(() => {
    if (googleMaps) {
      getGoogleMapAddress('Paris, France');
    }
  }, [googleMaps, formState.values.locations]);
  
  // Input sends values as string[] so we need to map it with our real values.
  const onChangeMobility = (newValues) => {
    form.change('locations', options.filter(({ value }) => newValues.includes(value)));
  }

  const handleInputChange = (search) => {
    if (search.length >= 3) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      timeout.current = setTimeout(() => {
        getGoogleMapAddress(search);
      }, 500)
    }
  };
  
  return (
    <div>
      <FormAutocompleteField 
        name="locations"
        disabled={disabled}
        label={getCriterionLabel(criterion)}
        leftIcon={<Icon name={criterion.icon} style={{ width: '24px', height: '24px' }} />}
        required={required}
        validate={value => isFilled(value)}
        options={options}
        onChange={onChangeMobility}
        formatValue={(value) => value?.map((val) => val._id ?? val?.value ?? val)}
        asynchronousSearch={handleInputChange}
        multiple
      />
    </div>
  );
}

MobilityField.propTypes = {
  criterion: object,
  googleMaps: object,
  showHelper: func,
  disabled: bool
};

MobilityField.defaultProps = {
  criterion: {},
  googleMaps: {},
};

export default GoogleMapLoader(MobilityField, {
  libraries: ['places'],
  key: process.env.FRONT_GOOGLE_MAPS_API_KEY,
});
