import React, { useEffect, useState } from 'react';
import { object, bool, func, oneOf, array } from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { isEmpty, find, get, isUndefined, isNull } from 'lodash';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { compose } from 'redux';
import moment from 'moment';
import Sticky from 'react-sticky-el';
import classnames from 'classnames';
import { Grid } from '@seekube-tech/ui';
import { Row, Col, Modal } from 'antd';
import { Tag, Body2, Button, H4 } from '@seekube-tech/ui-kit';
import { createStructuredSelector } from 'reselect';
import { useFormatMessage } from 'react-intl-hooks';
import { Typography } from '@seekube-tech/ui';
import { withRouter } from 'react-router-dom'
import { toJS } from '@/utils';
import { track, trackCandidateClickedViewKeyDates } from '@/utils/analytics';
import Separator from '@/components/Separator';
import Wrapper from '@/components/Wrapper';
import UserCard from '@/components/UserCard';
import Icon from '@/components/Icon';
import { OfferCardParticipant } from '@/components/OfferCard';
import AvatarWithDate from '@/components/AvatarWithDate';
import calendarBlank from '@/assets/images/calendar_blank.svg';
import calendarBlanken from '@/assets/images/calendar_blank_en.svg';
import { APPOINTMENT_MEDIUMS } from '@/utils/global';
import { offerActions, offerSelectors } from '@/store/offer';
import { eventSelectors } from '@/store/event';
import { exponentSelectors } from '@/store/exponent';
import { authSelectors } from '@/store/auth';
import OrganizationLayout from '@/components/OrganizationLayout';
import { getStoredUtmParams } from '@/utils/url';
import { getAvailableActions } from '@/store/availableActions/selectors';
import { ANALYTICS_CANDIDATE, APPOINTMENT_STATUS, EVENT_TYPE } from '@/utils/constants';
import { Lives } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/Lives';
import AppointmentUnconfirmedHelp from '@/scenes/Event/scenes/Candidate/scenes/JobDating/components/AppointmentUnconfirmedHelp';
import { getParticipantLimits } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Jobs/request';
import AddToCalendar from '@/components/AddToCalendar';
import styles from './styles.less';
import messages from './messages';
import { phases } from './helpers';
import CancelAppointmentModal
  from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Appointments/components/CancelAppointmentModal';
import { appointmentActions } from '@/store/appointment';

const OfferPreview = (props) => {
  const t = useFormatMessage();
  const [showCancelApplicationBtn, setShowCancelApplicationBtn] = useState(false);
  const [confirmCancelApplicationModalIsVisible, setConfirmCancelApplicationModalIsVisible] = useState(false);
  const [limitsApplicationModalIsOpen, setLimitsApplicationModalIsOpen] = useState(false);


  const {
    authUser,
    event,
    context,
    offer,
    currOff,
    intl,
    match,
    participant,
    interactions,
    onApply,
    onCancelApply,
    onShowExponent,
    getOffer,
    handleClosePreview,
    isPreview = false,
    goTo,
    availableActions,
    refuseAppointment,
  } = props;
  const applyLabel = event?.modules?.offer?.[`applyLabel_${intl.locale}`];
  const redirectUrl = `/auth/signup/candidate?redirect=${match.url}&utm_source=${getStoredUtmParams().utmSource}`
  const isMobile = window.innerWidth <= 768;
  const [applicationLimits, setApplicationLimits] = useState()
  const [refuseModalVisible, setRefuseModalVisible] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState()
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false)
  const [interaction, setInteraction] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isEmpty(offer) && match.params?.jobID) {
      getOffer({ eventId: match.params.eventSlug, offerId: match.params.jobID });
    }
    if (participant && event) {
      getParticipantLimits({event, participant}).then((res) => {
        setApplicationLimits(res)
      })
    }
  }, [])

  useEffect(() => {
    setAppointmentStatus(availableActions.bookAppointment.getStatus({event, role: 'candidate', participant, interaction, interactions, appointment, currentOffer, applicationLimits }));
    setButtonIsDisabled(availableActions.bookAppointment.isDisabled({event, role: 'candidate', participant, exponentKeyMomentFormats: currentOffer?.keyMomentFormats}))
  }, [applicationLimits, interaction])

  useEffect(() => {
    setInteraction(currentEvent?.skipAppointmentValidation ?
      find(interactions, (interaction) => get(interaction, '_recruiter')?._id === offer?._user?.id && interaction.type !== 'informal1to1') :
      find(interactions, (interaction) => get(interaction, '_offers').find((off) => off?._id === offer?._id && interaction.type !== 'informal1to1')))
  }, [interactions])

  if (context === 'OfferAdmin' && isEmpty(offer)) return null;

  const currentOffer = currOff || offer;
  const currentEvent = currOff?._event || event || {};
  const organization = props.organization || offer._organization;

  const appointment = interaction?._appointment || null;
  let eventParams = {};
  const countSlotsAvailable = availableActions.bookAppointment.getCountSlotsAvailables({event, participant, offer: currentOffer});

  const getEventParams = () => {
    if (!appointment) {
      return null;
    }
    let description = '';
    const timeslot = appointment._timeslot;
    const location = appointment.settings.medium === 'physical' ? appointment.settings.location : '';
    const startDate = timeslot ? moment(timeslot.beginAt): null;
    const endDate = timeslot ? moment(timeslot.beginAt).add(timeslot.duration, 'minutes') : null;

    if (appointment._timeslot?.medium === 'phone') {
      description = t({ id: 'add.to.calendar.description.phone'}, { recruiter_name: appointment._organizationUser.fullName });
    } else if (appointment._timeslot?.medium === 'visio') {
      description = t({ id: 'rdv.auto.calendar.description.visio' }, { visio_url: `${appointment.settings.candidateUrl}` });
    }

    return {
      title: t({ id: 'rdv.auto.calendar.title'}, { recruiter_name: appointment._organizationUser.fullName }),
      description,
      location,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      startTime: startDate.format('LT'),
      endTime: endDate.format('LT')
    }
  }

  if (!isEmpty(interaction) && appointment?._timeslot) {
    const startDate = moment(appointment._timeslot.beginAt);
    const endDate = moment(appointment._timeslot.beginAt).add(appointment._timeslot.duration, 'minutes');

    eventParams = {
      title: intl.formatMessage(messages.appointmentWith, { recruiter: appointment._user.fullName }),
      description: `${intl.formatMessage(messages.appointmentWith, { recruiter: appointment._user.fullName, event: currentEvent.name })}.\n${intl.formatMessage(messages.resumeUrl, { url: appointment._user.resumeUrl })}`,
      location: appointment.settings.medium,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      startTime: startDate.format('LT'),
      endTime: endDate.format('LT'),
    };
  }

  const getPageProperties = phases(currentEvent, appointmentStatus, match)

  const showWaitingList = currentOffer &&
    !buttonIsDisabled &&
    currentEvent.skipAppointmentValidation &&
    countSlotsAvailable === 0 &&
    appointmentStatus !== 'waiting-list'


  const appointmentMedium = (medium) => {
    switch (medium) {
      case 'physical':
        return appointment.settings.location;
      default:
        return intl.formatMessage(messages[medium]) || 'NC';
    }
  };

  const handleCancelApply = () => {
    if (applicationLimits?.enableLimitApplications) {
      setLimitsApplicationModalIsOpen(true)
    }
    else {
      onCancelApply();
      setConfirmCancelApplicationModalIsVisible(false);
    }
  }


  const handleRefuseAppointmentChange = (messageCandidate) => {

    if (!isEmpty(interaction) && authUser && participant) {
      refuseAppointment({
        appointmentId: appointment._id,
        messageCandidate,
        eventSlug: currentEvent.slug,
        eventId: currentEvent._id,
        event: currentEvent,
        userId: authUser._id,
        timeslotId: !isUndefined(appointment._timeslot) && !isNull(appointment._timeslot) ? appointment._timeslot._id : null,
        currentUser: authUser,
        callback: () => {
          track({
            name: ANALYTICS_CANDIDATE.CANCEL_APPLICATION_FOR_JOB,
            user: authUser,
            event,
            properties: {
              company: interaction._organization.name,
            },
          });
        },
      });
    }
  };

  const renderAppointmentButton = () => {
    // when it's a recruiter or owner, always show an enable button
    if (context !== undefined) {
      return (
        <Button
          type="primary"
          size="large"
        >
          {intl.formatMessage({ id: 'event.candidate.jobdating.offer.takeAppointment' })}
        </Button>
      )
    }
    switch (appointmentStatus) {
      case APPOINTMENT_STATUS.PENDING:
        return (
          <Button
            variant="outline"
            onClick={() => event?.skipAppointmentValidation ? setRefuseModalVisible(showCancelApplicationBtn) : setConfirmCancelApplicationModalIsVisible(showCancelApplicationBtn)}
            onMouseEnter={() => setShowCancelApplicationBtn(true)}
            onMouseLeave={() => setShowCancelApplicationBtn(false)}
          >
            {showCancelApplicationBtn ? (
              <div>
                {intl.formatMessage({ id: 'cancel.appointment' })}
              </div>
            ) : (
              <div>
                {intl.formatMessage({ id: 'pending' })}
              </div>
            )}
          </Button>
        );

      case APPOINTMENT_STATUS.NO_DATE:
        return (
          <Button
            type="primary"
            size="large"
            onClick={() => goTo(`/${participant._event.slug}/candidate/jobdating/appointment/${interaction._appointment._id}`)}
          >
            {intl.formatMessage({ id: 'event.candidate.appointments.card.chooseSlot' })}
          </Button>
        );

      case APPOINTMENT_STATUS.NO_SLOT:
        return (
          <Button disabled>
            {intl.formatMessage(messages.noSlot)}
          </Button>
        );

      case APPOINTMENT_STATUS.SKIP:
        return (
          <Button onClick={onApply}>
            {intl.formatMessage(messages.takeAppointment)}
          </Button>
        );

      case APPOINTMENT_STATUS.REFUSED:
        return (
          <Button disabled>
            {intl.formatMessage(messages.appointmentRefused)}
          </Button>
        );

      case APPOINTMENT_STATUS.DISABLED:
        return (
          <Button disabled>
            {getPageProperties.button.text}
          </Button>
        );

      case APPOINTMENT_STATUS.ALREADY_INTERACTION:
        return (
          <Button onClick={onApply} disabled>
            {currentEvent?.skipAppointmentValidation ?
              t({ id: 'book' }) :
              t({ id: 'event.candidate.jobdating.offer.applicationModal.apply' })
            }
          </Button>
        );

      default:
        return (
          <Button onClick={onApply} size="large">
            {!isEmpty(applyLabel) ? applyLabel : intl.formatMessage(messages.apply)}
          </Button>
        );
    }
  };

  const handleSubmitLimitApplication = () => {
    onCancelApply();
    setLimitsApplicationModalIsOpen(false); 
    setConfirmCancelApplicationModalIsVisible(false)
  }

  return (
    <Wrapper size="full" style={{ marginTop: '-20px' }}>
      <OrganizationLayout
        organization={currentOffer._organization}
        event={event}
        cover={currentOffer.organizationProfile?.cover}
        pictureUrl={currentOffer.organizationProfile?.pictureUrl}
      >
        <Row gutter={0} id="organizationLayout">
          <Col xs={12} sm={12} md={7} lg={7}>
            <div className="offerInfos">
              <OfferCardParticipant
                event={currentEvent}
                offer={currentOffer}
                organization={organization}
                participant={participant}
                hideOrganizationPicture
                showOrganizationDescription
                handleApply={onApply}
                handleShowExponent={onShowExponent}
              />
              {currentOffer._user && !isEmpty(currentOffer._user.description) ? (<UserCard titlePrefix={intl.formatMessage(messages.aboutOf)} user={currentOffer._user} type="full" />) : ''}
              <Separator height={30} />
              {appointmentStatus === null ? (<Button onClick={onApply} className="hidden-mobile"><FormattedMessage {...messages.apply} /></Button>) : ''}
            </div>
          </Col>
          <Col xs={12} md={5} lg={5}>
            {/** we need a 130px top offset because of the fixed nav */}
            <Sticky disabled={isMobile} boundaryElement="#organizationLayout" hideOnBoundaryHit={false} topOffset={-130}>
              <div className={classnames('offerInfos', styles.fixedOffers)}>
                <UserCard className="transparentBackground" user={currentOffer._user} />

                {appointmentStatus !== APPOINTMENT_STATUS.ACCEPTED && appointmentStatus !== APPOINTMENT_STATUS.WAITING_LIST && (
                  <div className={styles.calendar}>
                    <img src={intl.locale === 'fr' ? calendarBlank : calendarBlanken} alt="Calendar" />
                    {renderAppointmentButton()}
                  </div>
                )}

                {appointmentStatus === APPOINTMENT_STATUS.ALREADY_INTERACTION && (
                  <Grid container direction="row" spacing={1} justifyContent="center">
                    <Grid item>
                      <Icon name="warning-circle" className={styles.icon} />
                    </Grid>

                    <Grid item>
                      <Typography variant="caption1" color="warning[500]">
                        {t({ id: 'candidate.jobdating.informal1to1.alreadyInteraction' })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {appointmentStatus === APPOINTMENT_STATUS.ACCEPTED && appointment && appointment._timeslot && (
                  <div className={styles.appointment}>
                    <Separator height={20} />
                    <p>{intl.formatMessage(messages.appointmentSectionTitle)}</p>
                    <div className={styles.appointmentData}>
                      <div className={styles.appointmentDay}>
                        <AvatarWithDate
                          src={currentOffer.organizationProfile?.pictureUrl}
                          alt={interaction._organization.name}
                          date={appointment._timeslot.beginAt}
                        />
                      </div>
                      <div className={styles.appointmentSettings}>
                        <ul>
                          <li><Icon name="clock" /> {moment(appointment._timeslot.beginAt).format('HH[h]mm')}</li>
                          <li><Icon name={APPOINTMENT_MEDIUMS[appointment.settings.medium].icon} /> {appointmentMedium(appointment.settings.medium)}</li>
                          <li><Icon name="calendar" /> {appointment._timeslot.duration}min</li>
                        </ul>
                      </div>
                    </div>

                    <Separator height={25} />

                    {appointment.status === APPOINTMENT_STATUS.UNCONFIRMED && currentEvent.type !== EVENT_TYPE.jpo ? (
                      <AppointmentUnconfirmedHelp
                        date={currentEvent.keyDates.hunt ? currentEvent.keyDates.hunt.beginAt : currentEvent.keyDates.jobfair.endAt}
                      />) : (
                        <AddToCalendar
                          params={getEventParams()}
                          variant="fill"
                          className="flex items-center justify-center"
                        />
                      )}
                    <Separator height={105} />
                  </div>
                )}
                {
                  appointmentStatus === APPOINTMENT_STATUS.DISABLED && buttonIsDisabled && (
                    <Typography variant="body1">
                      {t({ id: 'candidate.offer.applications-disabled.description' })}
                      <br />

                      <Typography
                        variant="link1"
                        target="_blank"
                        component="a"
                        href={authUser ? `${window.location.origin}/${match.params.eventSlug}/candidate/keydates` : redirectUrl}
                        onClick={() => trackCandidateClickedViewKeyDates({ authUser })}
                      >
                        {t({ id: 'see.keyDates' })}
                      </Typography>
                    </Typography>
                  )
                }

                {
                  appointmentStatus !== APPOINTMENT_STATUS.ALREADY_INTERACTION && showWaitingList && (!applicationLimits?.enableLimitApplications || (applicationLimits?.enableLimitApplications && applicationLimits?.remainingApplications > 0)) && (
                    <div className={styles.waitingList}>
                      <p className="help">{intl.formatMessage(messages.waitingListTitle)}</p>
                      <a role="button" onClick={onApply} tabIndex={0}>{intl.formatMessage(messages.waitingListCta)}</a>
                    </div>
                  )}

                {appointmentStatus === APPOINTMENT_STATUS.WAITING_LIST && (
                  <div className={styles.waitingList}>
                    <h3>{intl.formatMessage(messages.waitingListOkTitle)}</h3>
                    <Tag color="primary">{intl.formatMessage(messages.waitingListOkTag)}</Tag>
                    <p className="help">{intl.formatMessage(messages.confirmationLightText, { recruiter: currentOffer._user.firstName })}</p>
                  </div>
                )}

                {(appointmentStatus === undefined || appointmentStatus === APPOINTMENT_STATUS.SKIP) && applicationLimits?.enableLimitApplications && applicationLimits?.remainingApplications &&
                  (
                    <Body2 className={styles.limitsMessage}>{t({id: 'applications.remaining.offer.preview'}, {count: applicationLimits?.remainingApplications})} <a href='https://intercom.help/seekube/fr/articles/6870978-le-nombre-de-candidatures-est-limite' target="_blank">{t({id: 'know.more'})}</a></Body2>
                  )
                }

                {appointmentStatus === APPOINTMENT_STATUS.DISABLED && !buttonIsDisabled && applicationLimits?.enableLimitApplications && applicationLimits?.remainingApplications === 0 &&
                  (
                    <Body2 className={styles.limitsMessage}>{t({id: 'reach.max.applications'}, {count: applicationLimits?.maxApplications})} <a href='https://intercom.help/seekube/fr/articles/6870978-le-nombre-de-candidatures-est-limite' target="_blank">{t({id: 'know.more'})}</a></Body2>
                  )
                }
              </div>
            </Sticky>
          </Col>
        </Row>

        {isPreview && context !== 'OfferAdmin' && (
          <div className={styles.fixedFooter} style={{ justifyContent: 'center' }}>
            {context === 'offerEdit' ?
              <div className={styles.fixedFooterActions} style={{ textAlign: 'center' }}>
                <Button className={styles.action} onClick={handleClosePreview}>
                  {intl.formatMessage(messages.backEdit)}
                </Button>
              </div>
              :
              <Row gutter={0}>
                <Col xs={12} sm={12} md={7} lg={7}>
                  <div className="flex justify-center mb-20">
                    <Button onClick={handleClosePreview}>
                      {intl.formatMessage(messages.back)}
                    </Button>
                  </div>
                </Col>
              </Row>
            }
          </div>
        )}
        {!isPreview && (
          <Modal
            visible={confirmCancelApplicationModalIsVisible}
            footer={false}
            maskClosable
            width={470}
            className="customConfirm"
            onCancel={() => setConfirmCancelApplicationModalIsVisible(false)}
          >
            <a role="button" tabIndex={0} className="modal-close" onClick={() => setConfirmCancelApplicationModalIsVisible(false)}>
              <Icon name="close" className="modal-close-icon" />
            </a>

            <h4 className="ant-confirm-title">
              <span role="img" aria-label="warning">⚠️ </span><FormattedHTMLMessage {...messages.cancelApplicationModal} />
            </h4>

            <Separator height={15} />

            <div className="confirm-actions">
              <Button variant="tonal" onClick={() => setConfirmCancelApplicationModalIsVisible(false)}>{intl.formatMessage(messages.cancelModal)}</Button>
              <Button type="primary" onClick={handleCancelApply}>{intl.formatMessage(messages.btnConfirmModal)}</Button>
            </div>
          </Modal>
        )}
        {!isPreview && appointment && (
          <CancelAppointmentModal
            visible={refuseModalVisible}
            onVisibleChange={() => { setRefuseModalVisible(false); }}
            appointment={appointment}
            handleCancelMessage={(message) => {
              handleRefuseAppointmentChange(message);
            }}
          />
        )}
        {!isPreview && (
          <Modal
            visible={limitsApplicationModalIsOpen}
            footer={false}
            maskClosable
            width={470}
            className="customConfirm"
            onCancel={() => onCancelApply() }
          >
            <H4>
              <FormattedHTMLMessage id="application.canceled" />
            </H4>
            <Body2 className={styles.descriptionModal}>
              {t({id:'applications.remaining'}, {count: applicationLimits?.remainingApplications + 1})}
            </Body2>
            <div className="confirm-actions">
              <Button onClick={() => handleSubmitLimitApplication()}>
                <FormattedHTMLMessage id='nice.understood' />
              </Button>
            </div>
          </Modal>
        )}
      </OrganizationLayout>
      <Wrapper size="large" className={styles.lives}>
        <Lives
          organization={organization}
          event={currentEvent}
          style={{ marginTop: '50px' }}
        />
      </Wrapper>
    </Wrapper>);
}

OfferPreview.propTypes = {
  authUser: object,
  event: object,
  context: oneOf(['OfferCard', 'OfferEdit', 'OfferAdmin']),
  offer: object,
  currOff: object,
  organization: object,
  intl: object,
  match: object,
  participant: object,
  interactions: array,
  onApply: func,
  onCancelApply: func,
  onShowExponent: func,
  getOffer: func,
  handleClosePreview: func,
  isPreview: bool,
  goTo: func,
  availableActions: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  event: eventSelectors.getCurrentEvent,
  exponents: exponentSelectors.getExponents,
  exponent: exponentSelectors.getCurrentExponent,
  pagination: offerSelectors.getOfferPagination,
  offer: offerSelectors.getParticipantSelectedOffer,
  availableActions: getAvailableActions,
});

const mapDispatchToProps = {
  goTo: replace,
  getOffer: offerActions.getOffer,
  refuseAppointment: appointmentActions.refuseAppointment,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(toJS(injectIntl(OfferPreview)));
