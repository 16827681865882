import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetOffersJobFactory({ page, search = '', filters }) {
  const contracts = encodeURIComponent(filters.contracts.join(','));
  const locations = encodeURIComponent(filters.locations.join(','));
  const durationsFilter = [];
  
  for (let key in filters.durations) {
    const elem = filters.durations[key];
    
    durationsFilter.push(elem);
  }
  
  const durations = encodeURIComponent(durationsFilter.join(','));
  
  const requestUrl = 
    `${process.env.FRONT_API_URL}/features/ats/recruiter/offers-jobfactory?limit=50&page=${page}&search=${search}&contractIds=${contracts}&locationIds=${locations}&durations=${durations}`

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['ats', 'offers', 'jobfactory', search, 'page', page, filters],
    queryFn,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  });

  return query;
}
