import React, { useEffect, useState } from 'react';
import { object, func, array, any, bool, number, oneOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import { Link, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { isEmpty, omit, isArray, get, find, includes, map } from 'lodash';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Modal, Row } from 'antd';
import { IconDownload, Button, H3, H5, Pagination, IconInfo, Tooltip, TooltipTrigger, TooltipContent } from '@seekube-tech/ui-kit';
import ModalV2 from '@/components/ModalV2';
import { useGetExponentsUsers } from '@/queries/exponent/useGetExponentsUsers';
import { actionActions } from '@/store/action';
import { participantSelectors } from '@/store/participant';
import { interactionSelectors, interactionActions } from '@/store/interaction';
import { authSelectors } from '@/store/auth';
import { eventSelectors } from '@/store/event';
import { offerActions, offerSelectors } from '@/store/offer';
import { criterionActions, criterionSelectors } from '@/store/criterion';
import { exponentSelectors } from '@/store/exponent';
import { sectorActions, sectorSelectors } from '@/store/sector';
import { withLayout } from '@/containers/Layout';
import { trackFilterOffer, trackViewOfferClick } from '@/utils/analytics';
import { getId } from '@/utils/global';
import { queryStringToObject, objectToParams } from '@/utils/url';
import { toJS } from '@/utils/index';
import LoadingIndicator from '@/components/LoadingIndicator';
import Icon from '@/components/Icon';
import { If } from '@/components/If';
import Html from '@/components/Html';
import MobileMenu from '@/containers/Layout/containers/MobileMenu';
import { SeparatorParticipationExpired } from '@/scenes/Event/scenes/Candidate/scenes/JobDating/scenes/Jobs/components';
import { EVENT_FORMAT } from '@/utils/constants';
import MobileHeader from '@/containers/Layout/containers/MobileHeader';
import CardOffer from '@/components/CardOffer';
import { isParticipatingToAllHybridFormats } from '@/helpers/participant/isParticipatingToAllHybridFormats';
import { getEventPhases, isInteractionClosed } from '@/store/availableActions/utils/event';
import SearchEngine from '../../components/SearchEngine';
import MatchingForm from '../../../../containers/Matching';
import DiscoverInformal1to1 from './components/DiscoverInformal1to1';
import messages from './messages';
import styles from '../../../../../../components/StaticOffers/styles.less';
import checkParticipant from '../../../../../../../../utils/checkParticipant';
import { useGetParticipantLimits } from '@/queries/participants/useGetParticipantLimits';

const Jobs = ({
  participant,
  context = 'participant',
  event,
  interactions,
  match,
  getParticipantOffers,
  getOffersByMatching,
  getSectors,
  postAction,
  getInteractions,
  pagination,
  offers,
  defaultCriteria,
  totalOffers,
  allSectors,
  sectors,
  location,
  facets,
  history,
  getDefaultCriteria,
  authUser,
  intl,
  participantOffersLastSearch
}) => {

  const t = useFormatMessage()

  const [isFetching, setIsFetching] = useState(true)
  const [matchingTmp, setMatchingTmp] = useState(null)
  const [countOffers, setCountOffers] = useState(null)
  const [countSearchOffers, setCountSearchOffers] = useState(null)
  const [modalSpecificityVisible, setModalSpecificityVisible] = useState(false)
  const [matchingModalIsVisible, setMatchingModalIsVisible] = useState(false)
  const [facetSectors, setFacetSectors] = useState(null)
  const [search, setSearch] = useState({ keywords: '', ...location.query })
  const [facetLocations, setFacetLocations] = useState(null)
  const [queryParams, setQueryParams] = useState({ page: 1, ...location.query, })

  const { data } = useGetExponentsUsers({
    eventId: event._id,
    search: { keywords: '' },
    page: 1,
    limit: 10,
  });

  const { data: applicationLimits } = useGetParticipantLimits({
    event,
    participant,
  });

  useEffect(() => {
    if (!countOffers) {
      setCountOffers(pagination.total);
    }

    setCountSearchOffers(pagination.total);

    if (facetLocations === null && facets && facets['locations.name']) {
      setFacetLocations(facets['locations.name']);
    }

    if (facetSectors === null && sectors && sectors.length > 0) {
      setFacetSectors(sectors.sort((a, b) => b.get('name') < a.get('name')));
    }

    if (participant && matchingTmp === null && participant.matching) {
      setMatchingTmp(participant.matching)
    }
  }, [offers])

  useEffect(() => {
    if (isEmpty(defaultCriteria)) {
      getDefaultCriteria();
    }

    if (isEmpty(allSectors)) {
      getSectors();
    }

    if (context === 'owner') {
      getOffers('', 1, 0, {}, {}, null, false);
    }
  }, [])

  useEffect(() => {
    if (context === 'owner') return;
    const checkStatus = checkParticipant(participant, event);

    if ((!authUser || !participant) && typeof history === 'function' && match.isExact) {
      history.push(`/${match.params.eventSlug}/candidate/preparation/onboarding`);
    } else if (checkStatus !== 'ok' && participant.userProvider?.utmSource !== 'hellowork') {
      history.push(`/${match.params.eventSlug}/candidate/preparation/onboarding/profile`);
    } else if (event && !event.withOfferModule) {
      history.push(`/${match.params.eventSlug}/candidate/jobdating/exponents`);
    } else if (!isEmpty(participantOffersLastSearch) && participantOffersLastSearch.matchingTmp) {
        getOffers(participantOffersLastSearch.search, 1, 0, {}, participantOffersLastSearch.matchingTmp, null, false);
      } else if (!isEmpty(participant)) {
        getParticipantOffers({
          eventId: event.slug,
          participantId: participant._id,
          offset: 0,
          page: 1,
          limit: pagination.pageSize,
          search: {},
          params: { noUpdateParticipant: true, search: null, withInitialFacet: true, matchingTmp: participant.matching },
          facetKey: null,
          callback: () => {
            setIsFetching(false);
          },
        });
      }
  }, [participant?._id]);

  const getOffers = (search, page, offset, params, matchingTmp, facetKey = null, track = false) => {

    const participantParams = { noUpdateParticipant: true, search };

    if (matchingTmp) participantParams.matchingTmp = matchingTmp;
    const sectorSelected = sectors.find((sector) => params.sector && params.sector.includes(sector.get('_id').toString()));

    if (context === 'owner') {
      getOffersByMatching({
        page,
        limit: pagination.pageSize,
        search,
        matching: matchingTmp,
        facetKey,
        callback: () => {
          setIsFetching(false);
        },
      });
    } else {
      getParticipantOffers({
        eventId: event.slug,
        participantId: participant._id,
        offset,
        page,
        limit: pagination.pageSize,
        search,
        params: participantParams,
        facetKey,
        callback: (facet, offersIds) => {
          setIsFetching(false);
          getInteractions({
            eventSlug: event.slug,
            body: {
              users: [participant._user._id],
              offers: [offersIds],
            },
          });
          if (track) {
            trackFilterOffer({
              user: authUser,
              event,
              countOffers: facet.status ? facet.status.published : 0,
              params,
              localization: isArray(params.localization) ? params.localization.join(', ') : params.localization,
              size: params.size,
              sector: params.sector && sectorSelected ? sectorSelected.get('name') : '',
            });
          }
        },
      });
    }

    return true;
  };

  const handleOpenOffer = (offer) => {
    const currentUrl = window.location.href;

    if (context === 'participant' && authUser) {
      postAction({
        actionParams: {
          name: 'CANDIDATE_VISIT_OFFER',
          _user: authUser._id,
          _organization: offer._organization._id,
          _event: event._id,
          participantId: participant._id,
          data: { offer: offer._id, context: 'offers' },
        },
        callback: () => {
          trackViewOfferClick({
            event,
            user: authUser,
            currentUrl,
            company: offer._organization.name,
          });
        },
      });
    }
  };


  const handleOnChangePagination = (pagination) => {
    const location = { ...history.location };

    location.query = queryStringToObject(location.search);

    if (context === 'owner') {
      processQueryChange({ page: pagination }, matchingTmp);
    } else {
      processQueryChange({ ...participantOffersLastSearch.search, page: pagination }, participantOffersLastSearch.matchingTmp);
    }

    window.scrollTo(0, 0);
  };

  /**
   * Handle change
   *
   * @param {object} newParams
   */
  const processQueryChange = (newParams, matchingTmp, facetKey) => {

    const location = { ...history.location };

    location.query = queryStringToObject(location.search);

    const queryMap = isEmpty(location.query) ? Map(newParams) : Map(location.query);
    const newMapParams = queryMap.merge(newParams);

    location.query = newMapParams.toJS();
    location.search = objectToParams(location.query);
    setQueryParams(location.query)
    setSearch(location.query)
    setMatchingTmp(matchingTmp);

    const currentPage = location.query.page ? parseInt(location.query.page, 10) : 0;
    const offset = currentPage === 1 || location.query.page === undefined ? 0 : (currentPage * 20) - 20;
    const search = omit(location.query, ['page']);

    history.push(location);

    getOffers(search, location.query.page, offset, newParams, matchingTmp, facetKey, true);
  };

  const handleOnCloseModal = () => {
    setMatchingModalIsVisible(false);
  };

  const handleOnShowModal = () => {
    setMatchingModalIsVisible(true);
  };

  const closeSpecificityModal = () => {
    setModalSpecificityVisible(false);
  };

  const openSpecificityModal = () => {
    setModalSpecificityVisible(true);
  };

  const isSeparatorParticipationExpiredVisible = (participantIndex) => {
    const breakpointParticipantWithInteractionExpired = participant?.isInteractionExpired;
    const eventPhases = getEventPhases(event);
    const bothInteractionAreClosed = isInteractionClosed(eventPhases, EVENT_FORMAT.PHYSICAL) && isInteractionClosed(eventPhases, EVENT_FORMAT.VIRTUAL);

    return (
      participantIndex === breakpointParticipantWithInteractionExpired &&
      context === 'sourcing' &&
      isParticipatingToAllHybridFormats(authUser?.keyMomentFormats) &&
      !bothInteractionAreClosed);
  }


  const renderOffers = (isMobile) => {
    if (offers.length === 0) {
      return '';
    }

    const breakpointOfferWithParticipationExpired = offers.findIndex(off => off.isParticipationExpired);
    // Only for Hybrid event


    return (
      <Row gutter={[32, 32]}>
        {
          offers.map((offer, i) => {
              const organizationProfile = offer.organizationProfile ?? offer._organization.profile;

              return (
                <Col span={12} key={offer._id}>
                  {event.format === EVENT_FORMAT.HYBRID && i === breakpointOfferWithParticipationExpired && isSeparatorParticipationExpiredVisible(offer._id) &&
                    <SeparatorParticipationExpired
                      event={event}
                      context="offer"
                    />
                  }
                  <Link
                    target={isMobile ? '' : '_blank'}
                    key={`link${i.toString()}-${offer._id}`}
                    to={context !== 'participant' ? `/${event.slug}/owner/offers/${offer._id}/preview` : `/${event.slug}/candidate/jobdating/jobs/${offer._id}`}
                    onClick={() => handleOpenOffer(offer)}
                    style={{ display: 'block', padding: 0, cursor: 'pointer' }}
                  >
                    <CardOffer
                      key={`${i.toString()}-${offer._id}`}
                      offer={offer}
                      event={event}
                      interaction={find(interactions, (interaction) => includes(map(interaction._offers, (off) => getId(off)), getId(offer)))}
                      cover={`https://aqfjyrmoen.cloudimg.io/width/${600}/x/${organizationProfile?.cover}`}
                      avatar={offer.organizationProfile.pictureUrl}
                      context={context}
                      participant={participant}
                    />
                  </Link>
                </Col>
              );
            }
          )}
      </Row>)
  };

  if (context === 'participant' && !participant) {
    return '';
  }

  const isMobile = window.innerWidth < 768;

  if (isFetching) {
    return (<LoadingIndicator />);
  }

  const handleExport = () => {
    const exportUrl = `${process.env.FRONT_EXPORT_API_URL}/events/${event.slug}/export/offers?apiKey=41a1289e-fee1-430e-a849-5b2b7ed23f1a`;

    window.open(exportUrl, '_blank');
  };

  return (
    <div>
      <div className={styles.jobsContainer}>
        <div className={styles.jobsHeader}>
          <div className="show-mobile">
            {applicationLimits?.enableLimitApplications && applicationLimits?.maxApplications !== 0 &&
              <div className={styles.applicationsNumberContainer}>
                {t({ id: 'participants.results.applications2' })}: {applicationLimits?.countApplications}/{applicationLimits?.maxApplications}
                <> - <a href='https://intercom.help/seekube/fr/articles/6870978-le-nombre-de-candidatures-est-limite' target='_blank'>{t({ id: 'know.more' })}</a></>
              </div>
            }
            <SearchEngine
              search={search}
              locations={facetLocations}
              onSearch={(search, matchingTmp, facetKey) => { processQueryChange(search, matchingTmp, facetKey); }}
              sectors={facetSectors}
              defaultSectors={allSectors}
              event={event}
              intl={intl}
              onShowMatchingModal={() => handleOnShowModal()}
              context={context === 'participant' ? 'offer' : 'owner'}
              countOffers={countSearchOffers}
              totalOffers={totalOffers}
              authUser={authUser}
              participantOffersLastSearch={participantOffersLastSearch}
            />
          </div>

          {context === 'owner' ?
            <span className={styles.ownerHeader}>
              <div className={styles.pageTitle}>
                <H3>{intl.formatMessage({ id: 'event.owner.offers.title' }, { count: totalOffers })}</H3>

                <If condition={get(event, 'goals.offersMessage')}>
                  <a tabIndex={0} role="button" onClick={openSpecificityModal}>{intl.formatMessage({ ...messages.seeSpecificity })}</a>
                </If>
              </div>
              <div className={styles.actions}>
                <Button
                  variant="outline"
                  color="neutral"
                  imageComponentLeft={<IconDownload size={16} />}
                  onClick={handleExport}
                >
                  <FormattedMessage id="event.owner.opportunities.export.btn" />
                </Button>
              </div>
            </span>
            :
            <div className={styles.searchTitleContainer}>
              <h1 className="mainTitle"><FormattedMessage {...messages.title} values={{ count: countSearchOffers }} /></h1>
              <>
                {applicationLimits?.enableLimitApplications && applicationLimits?.maxApplications !== 0 && !isMobile &&
                  <div className={styles.applicationsNumberContainer}>
                    {t({ id: 'participants.results.applications2' })}: {applicationLimits?.countApplications}/{applicationLimits?.maxApplications}
                    <Tooltip placement="left" enableFocus>
                      <TooltipTrigger><span> <IconInfo size={16} /></span></TooltipTrigger>
                      <TooltipContent>{t({ id: 'tooltip.limits.candidate' })} <a href='https://intercom.help/seekube/fr/articles/6870978-le-nombre-de-candidatures-est-limite' target='_blank'>{t({ id: 'know.more' })}</a></TooltipContent>
                    </Tooltip>
                  </div>
                }
              </>
            </div>}

          {!isMobile ? (
            <div className="hidden-mobile">
              <SearchEngine
                search={search}
                locations={facetLocations}
                onSearch={(search, matchingTmp, facetKey) => { processQueryChange(search, matchingTmp, facetKey); }}
                sectors={facetSectors}
                defaultSectors={allSectors}
                event={event}
                onShowMatchingModal={() => handleOnShowModal()}
                intl={intl}
                context={context === 'participant' ? 'offer' : 'owner'}
                countOffers={countSearchOffers}
                totalOffers={totalOffers}
                authUser={authUser}
                participantOffersLastSearch={participantOffersLastSearch}
              />
            </div>
          ) : null}
        </div>
      </div>

      {countSearchOffers > 0 ? (
        <>
          <div className={styles.jobsContainer}>
            {renderOffers(isMobile)}
          </div>

          <div className="pagination-container">
            <Pagination
              className="flex justify-center mb-40 w-full"
              current={queryParams.page ? parseInt(queryParams.page, 10) : 1}
              pageSize={pagination.pageSize}
              total={pagination.total || 0}
              onChange={handleOnChangePagination}
            />
            {event.modules.informal1to1?.enable && data.docs.length > 0 &&
              <DiscoverInformal1to1 event={event} role={context} />}
          </div>
        </>
      ) : (
        <>
          {
            countSearchOffers === null ? (
              <LoadingIndicator />
            ) : (
              event.modules.informal1to1?.enable && data.docs.length > 0 && <DiscoverInformal1to1 event={event} role={context} />
            )
          }
        </>
      )}

      <ModalV2
        visible={matchingModalIsVisible}
        footer={false}
        className="confirmationModal"
        onClose={handleOnCloseModal}
      >
        <H5 className="mb-20">
          {intl.formatMessage({ id: "event.candidate.jobdating.jobs.updateMyCriteria" })}
        </H5>

        <MatchingForm
          context="jobs"
          authUser={authUser}
          event={event}
          defaultCriteria={defaultCriteria}
          participant={participant}
          onOk={handleOnCloseModal}
          onCancel={handleOnCloseModal}
        />
      </ModalV2>
      <Modal
        visible={modalSpecificityVisible}
        footer={false}
        className="confirmationModal"
        onCancel={closeSpecificityModal}
      >
        <a role="button" tabIndex={0} className="modal-close" onClick={closeSpecificityModal}>
          <Icon name="close" className="modal-close-icon" />
        </a>
        <div className={styles.specificityModal}>
          <Html value={get(event, 'goals.offersMessage')} />
        </div>
      </Modal>
      <MobileHeader event={event} />
      <MobileMenu
        displayInformal={event.modules?.informal1to1?.enable}
        displayLive={event.modules.live && event.modules.live.participantEnable}
        liveLabel={event.modules.live && event.modules.live.labelMultiple}
      />
    </div >
  );
}

Jobs.propTypes = {
  participant: object,
  context: oneOf(['owner', 'participant']),
  event: object,
  interactions: array,
  match: object,
  matching: object,
  getParticipantOffers: func,
  getOffersByMatching: func,
  getSectors: func,
  postAction: func,
  getInteractions: func,
  pagination: object,
  offers: array,
  defaultCriteria: array,
  totalOffers: number,
  allSectors: array,
  sectors: array,
  location: object,
  isLoading: bool,
  history: object,
  selectedOffer: any,
  deleteParticipantSelectedOffer: func,
  push: func,
  getDefaultCriteria: func,
  authUser: object,
  intl: object,
  facets: object,
  participantOffersLastSearch: object,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  participant: participantSelectors.getCurrentParticipant,
  event: eventSelectors.getCurrentEvent,
  offers: offerSelectors.getOffers,
  facets: offerSelectors.getFacets,
  allSectors: sectorSelectors.getAllSectors,
  sectors: offerSelectors.getSectors,
  pagination: offerSelectors.getOfferPagination,
  selectedOffer: offerSelectors.getParticipantSelectedOffer,
  totalOffers: offerSelectors.getTotalOffers,
  interactions: interactionSelectors.getInteractions,
  defaultCriteria: criterionSelectors.getDefaultCriteria,
  participantOffersLastSearch: offerSelectors.getParticipantOffersLastSearch,
  participantExponentsLastSearch: exponentSelectors.getParticipantExponentsLastSearch,
});

const mapDispatchToProps = {
  push,
  getParticipantOffers: offerActions.getParticipantOffers,
  getOffersByMatching: offerActions.getEventOffersByMatching,
  getInteractions: interactionActions.getInteractions,
  postAction: actionActions.postAction,
  getSectors: sectorActions.getSectors,
  applyOffer: offerActions.applyOffer,
  getDefaultCriteria: criterionActions.getDefaultCriteria,
  deleteParticipantSelectedOffer: offerActions.deleteParticipantSelectedOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withLayout({ size: 'default', withHeader: false, navType: 'candidate' }),
  withRouter,
  withConnect,
  injectIntl,
)(toJS(Jobs));
