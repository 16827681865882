import React, { useState } from 'react';
import { InputSearch } from '@seekube-tech/ui-kit';
import { Field, useForm, useFormState } from 'react-final-form';
import { any, bool, func, string } from 'prop-types';
import { isEmpty } from 'lodash';
import useValidations from '@/utils/validations/useValidations';
import { getFieldError } from '@/utils/forms/final-form/helpers';
import styles from '@/components/SearchEvents/Engine/Desktop/styles.less';

export const FormAutocompleteField = ({
  name,
  id,
  label,
  placeholder,
  defaultValue,
  validate,
  leftIcon,
  required,
  showErrorOnMount = true,
  disabled,
  options,
  multiple = false,
  onChange,
  formatValue,
  asynchronousSearch
}) => {
  const form = useForm();
  const formState = useFormState();
  const error = getFieldError(formState.errors, name);
  const { isFilled } = useValidations();
  const shouldShowError = showErrorOnMount || formState.modified[name];
  const [search, setSearch] = useState();
  const optionsToRender = asynchronousSearch ? options : options.filter(({ label }) => label.includes(search) || isEmpty(search));
  
  const validation = (value) => {
    const isFilledError = isFilled(value);
    
    if (required && !isEmpty(isFilledError)) {
      return isFilledError;
    }
    if (validate) {
      return validate(value);
    }
  }
  
  const handleChange = (selected) => {
    onChange ? onChange(selected) : form.change(name, selected);
  }
  
  const handleSearch = (search) => {
    if (asynchronousSearch) {
      asynchronousSearch(search);
    }
    setSearch(search);
  }
  
  return (
    <Field
      name={name}
      id={id}
      label={label}
      placeholder={placeholder}
      validate={validation}
      defaultValue={defaultValue}
    >
      {(props) => (
        <InputSearch
          autocomplete
          inputValue={search}
          value={formatValue?.(formState.values?.[name]) ?? formState.values?.[name]}
          onChange={handleChange}
          onInputChange={handleSearch}
          name={name}
          id={name}
          label={label}
          required={required}
          className={`${styles.customSearch} ${styles.left}`}
          color={(error && shouldShowError && props.meta.modified) ? 'error' : 'neutral'}
          message={(props.meta.modified && shouldShowError) ? error : ''}
          options={options}
          filteredOptions={optionsToRender}
          multiple={multiple}
          disabled={disabled}
          leftIcon={leftIcon}
        />
      )}
    </Field>
  );
}

FormAutocompleteField.propTypes = {
  name: string,
  id: string,
  label: string,
  placeholder: string,
  defaultValue: any,
  validate: func,
  leftIcon: any,
  required: bool
}