import { useQuery } from 'react-query';
import request from '@/utils/request';

export function useGetOfferByReferenceJobFactory({ atsReference, onSuccess }) {
  
  const requestUrl = 
    `${process.env.FRONT_API_URL}/features/ats/recruiter/offers-jobfactory/${atsReference}`

  const queryFn = () => request(requestUrl, { method: 'GET' });

  const query = useQuery({
    queryKey: ['ats', 'offer', 'jobfactory', 'reference', atsReference],
    queryFn,
    refetchOnWindowFocus: false,
    enabled: !!atsReference,
    onSuccess
  });

  return query;
}
