import { useFormatMessage } from 'react-intl-hooks';
import { Field, useForm, useFormState } from 'react-final-form';
import { Body1, IconUser, InputSearch, Link1 } from '@seekube-tech/ui-kit';
import { LINKS } from '@/utils/constants';
import React, { useState } from 'react';
import { getLocale } from '@/utils/localStorage';
import useValidations from '@/utils/validations/useValidations';
import { array, object, string } from 'prop-types';
import styles from '@/components/SearchEvents/Engine/Desktop/styles.less';
import { SelectUserOption } from '@/components/SelectUserOption';

const OfferSpreadingOwnerInput = ({
  name,
  initialValue,
  usersList,
}) => {
  const t = useFormatMessage();
  const locale = getLocale();
  const form = useForm();
  const formState = useFormState();
  const [search, setSearch] = useState('');
  const { isFilled } = useValidations();
  
  const userOptions = usersList?.map((user) => ({
    label: user.fullName,
    value: user._id,
    pictureUrl: user.pictureUrl,
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
      _id: user._id
    }
  })) ?? [];

  const renderOption = (options, opt, { key, active } = {}) => {
    return (
      <SelectUserOption
        opt={opt}
        id={key}
        active={active}
      />
    )
  }
  
  const onChange = (val) => {
    form.change(name, val);
    setSearch('');
  }
  
  const [prefix, user] = name.split('.');
  const value = formState.values?.[prefix]?.[user] ?? formState.values[name];
  
  return (
    <Field
      name={name}
      id={name}
      label={t({ id: 'offer.form.user.placeholder' })}
      value={value}
      initialValue={initialValue ?? ''}
      validate={(value) => isFilled(value)}
    >
      {() => (
        <InputSearch
           autocomplete
           inputValue={search}
           value={value ?? ''}
           onChange={onChange}
           onInputChange={setSearch}
           name={name}
           id={name}
           label={t({ id: 'offer.form.user.placeholder' })}
           required
           className={`${styles.customSearch} ${styles.left}`}
           message={<Body1>
             {t({ id: 'offer.ownerInput.message' })}&nbsp;
             <Link1 target="_blank" href={LINKS.publish_offer[locale]}>{t({ id: 'knowMore' })}</Link1>
           </Body1>
           }
           options={userOptions.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase()) || search === '')}
           leftIcon={<IconUser size={24} />}
           renderOption={(opt, { key, active }) => renderOption(userOptions, opt, { key, active }, true)}
         />
      )}
    </Field>
  )
}

export { OfferSpreadingOwnerInput };

OfferSpreadingOwnerInput.propTypes = {
  name: string,
  initialValue: object,
  usersList: array
}