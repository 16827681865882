import React, { useState } from 'react';
import { getId } from '@/utils/global';
import { map } from 'lodash';
import { Form } from 'react-final-form';
import { useFormatMessage } from 'react-intl-hooks';
import styles from './styles.less';
import { Button, IconTrash2 } from '@seekube-tech/ui-kit';
import {
  OfferHeader
} from '@/scenes/Client/scenes/OffersSpreading/components/Step2/components/form/components/OfferHeader/OfferHeader';
import {
  OfferSpreadingContractsInput
} from '@/scenes/Client/scenes/OffersSpreading/components/Step2/components/form/components/OfferSpreadingContractsInput';
import {
  OfferSpreadingOwnerInput
} from '@/scenes/Client/scenes/OffersSpreading/components/Step2/components/form/components/OfferSpreadingOwnerInput';

const FormStep2 = ({
  offers,
  handleActions,
  sendNotification,
  handleSubmit,
  exponentUsers,
  eventContracts
}) => {
  const t = useFormatMessage();
  const [userFullName, setUserFullName] = useState();
  const users = exponentUsers.map(({ _user }) => _user);
  const usersIds = users.map(({ _id }) => _id);
  const initialValues = {};
  
  const handleRemoveOffer = (offer) => {
    handleActions('setOffersSelected', offer);
    sendNotification({
      message: t({ id: 'recruiter.offers.spread.remove.toaster.success' }),
    });
  }
  
  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ form, handleSubmit }) => {
        const state = form.getState();
        
        return (
          <form onSubmit={handleSubmit} className={styles.offers}>
            {
              map(offers, (offer, i) => {
                
                return (
                  <div className={styles.card} key={i}>
                    <OfferHeader 
                      offer={offer}
                    />
                    <OfferSpreadingOwnerInput
                      initialValue={usersIds.includes(offer?._user?._id) ? offer?._user?._id : null}
                      name={`${getId(offer)}._user`}
                      usersList={users}
                    />
                    <OfferSpreadingContractsInput
                      initialValue={offer?.matching?.CONTRACT}
                      name={`${getId(offer)}._contract`}
                      contractsList={eventContracts}
                    />
                    
                    <div className={styles.deleteAction}>
                      <Button 
                        color="error"
                        variant="tonal"
                        imageComponentLeft={<IconTrash2 size={16} />}
                        onClick={() => handleRemoveOffer(offer)}
                      >
                        {t({ id: 'offer.spreading.deleteSelection' })}
                      </Button>
                    </div>

                  </div>
                )
              })
            }
            <div className={styles.actions}>
              <Button variant="tonal" className="mr-6" onClick={() => handleActions('openBackModal')}>
                {t({ id: 'cancel' })}
              </Button>
              <Button disabled={state.invalid} type="submit">
                {t({ id: 'next' })}
              </Button>
            </div>
          </form>
        )
      }}
    />
  )
}

export { FormStep2 };
