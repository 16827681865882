import React from 'react';
import { object, bool, string, array, func, number, oneOfType } from 'prop-types';
import { debounce, find, isEmpty, isUndefined } from 'lodash';
import GoogleMapLoader from 'react-google-maps-loader';
import { Select as SelectAntd, Spin } from 'antd';
import Select from '@/components/Form/Select';
import { getContinentFromLocation } from '@/utils/analytics';
import { removePlusCode } from '@/utils/removePlusCode';

class InputGoogleMapsSuggest extends React.PureComponent {
  static propTypes = {
    googleMaps: object,
    radius: number,
    placeholder: string,
    onChange: func,
    disabled: bool,
    multiple: bool,
    initialValue: oneOfType([string, array]),
    style: object,
    addOnIcon: string,
    onBlur: func,
    withInputErrorStyle: bool,
    required: bool,
    hasError: bool,
    validations: array,
    placeTypes: array,
    locations: array,
    id: string,
    showHelper: func,
    getPopupContainer: func,
  };

  static defaultProps = {
    radius: 20,
    placeholder: '8 rue de nantes 75019 Paris',
    disabled: false,
    required: false,
    style: {},
    multiple: false,
    placeTypes: []
  };

  /**
   * Constructor
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: props.initialValue || [],
      result: [],
      fetching: false,
      values: props.initialValue || [],
      objects: props.locations || [],
    };
  }

  /**
   * Search place on google maps
   *
   * @param {string} searchTerm
   * @param {Function} cb
   */
  searchOnGoogleMaps = debounce((searchTerm, cb) => {
    const { props: { googleMaps, radius, placeTypes } } = this;

    const autoCompleteService = new googleMaps.places.AutocompleteService();

    autoCompleteService.getPlacePredictions({
      input: searchTerm,
      location: new googleMaps.LatLng(0, 0),
      radius,
      types: placeTypes,
    }, (googleSuggests) => cb(googleSuggests || []));
  }, 300);

  /**
   * Search the location of a place
   * @param {string} placeId
   * @param {string} placeDescription
   * @param {Function} cb
   */
  geocoderOnGoogleMaps = (placeId, placeDescription, cb) => {
    const {
      props: { googleMaps, multiple },
      state: { objects },
    } = this;
    const geocoder = new googleMaps.Geocoder();

    geocoder.geocode({ placeId }, (results, status) => {
      if (status === googleMaps.GeocoderStatus.OK) {
        const result = results.find((r) => r && r.address_components && r.address_components.length > 1);
        const { address_components, geometry: { location } } = result || results[0];

        // Find address sub location from address components return by the google map API
        const findAddressElement = (addressElement, isSearchForCity = false) => {
          const element = find(address_components, (ac) => ac.types[0] === addressElement);

          // The sub location from the API for city is "locality"
          // When we are looking for a place or a region, the API should not return "locality"
          // But for "Gironde" place, the API returned a "locality" and instead of have Gironde, we got Gironde-sur-dropt
          // To bypass the issue, we have to check the if the locality name we are looking for is include in the place description
          if (isSearchForCity && !placeDescription.includes(element)) {
            return find(address_components, (ac) => placeDescription.includes(ac.long_name)) || element;
          }
          return element;
        };
        let city = findAddressElement('locality');
        // locality from api return a city
        // if we are looking for a place, we don't have to use locality because it belong to city.
        // In that case, we have to search an address component name equal to placeDescription to retrieve the place
        if (!placeDescription.includes(city)) {
          city = find(address_components, (ac) => placeDescription.includes(ac.long_name)) || city;
        }

        const department = find(address_components, (ac) => ac.types[0] === 'administrative_area_level_2');
        const region = find(address_components, (ac) => ac.types[0] === 'administrative_area_level_1');
        const country = find(address_components, (ac) => ac.types[0] === 'country');
        const continent = getContinentFromLocation(results[0]);
        
        const formattedPlaceDescription = removePlusCode(placeDescription);

        // Return callback
        const item = {
          name: formattedPlaceDescription,
          formattedAdress: formattedPlaceDescription,
          shortAddress: `${city !== undefined ? city.long_name : null}, ${country !== undefined ? country.long_name : null}`,
          city: city !== undefined && country !== undefined && country.short_name === 'FR' ? city.long_name : null,
          department: department !== undefined && country !== undefined && country.short_name === 'FR' ? department.long_name : null,
          region: region !== undefined && country !== undefined && country.short_name === 'FR' ? region.long_name : null,
          country: country !== undefined ? country.long_name : null,
          countryCode: country !== undefined ? country.short_name : null,
          continent,
          geometry: {
            coordinates: [
              location.lng(),
              location.lat(),
            ],
          },
        };

        if (multiple) {
          objects.push(item);
          this.setState({ objects });

          cb(objects);
        } else {
          cb(item);
        }
      }
    });
  };

  /**
   * Handle search when typing
   *
   * @param {string} searchTerm
   */
  handleSearch = (searchTerm) => {
    this.setState({ searchTerm, fetching: true });

    if (searchTerm !== '') {
      this.searchOnGoogleMaps(searchTerm, (result) => this.setState({
        result: result.filter((location) => !location.types.includes('sublocality') && !location.types.includes('postal_code')),
        fetching: false,
      }));
    } else {
      this.setState({ result: [] });
    }
  };

  /**
   * Handle select place
   *
   * @param {number} index
   */
  handleSelect = (index) => {
    const { state: { result, values }, props: { onChange, multiple } } = this;
    const place = result[index];

    if (multiple) {
      const newValues = values;

      if (!newValues.includes(place.description)) {
        newValues.push(place.description);
        this.geocoderOnGoogleMaps(place.place_id, place.description, onChange);
      }

      this.setState({ values: newValues, result: [] });
    } else {
      this.setState({ searchTerm: place.description });
      this.geocoderOnGoogleMaps(place.place_id, place.description, onChange);
    }
  };

  /**
   * Handle select place
   *
   * @param {string} value
   */
  handleDeselect = (value) => {
    const { state: { values, objects }, props: { onChange, multiple } } = this;

    if (!multiple) {
      return false;
    }

    const filterObjects = objects.filter((item) => item.name !== value);

    this.setState({
      values: values.filter((item) => item !== value),
      objects: filterObjects,
    });

    onChange(filterObjects);

    return true;
  };

  handleOnFocus = () => {
    const { state: { searchTerm } } = this;

    this.handleSearch(!isEmpty(searchTerm) ? searchTerm : 'Paris');
  };

  /**
   * Render
   */
  render() {
    const {
      state: { searchTerm, result, values, fetching },
      props: { getPopupContainer, placeholder, disabled, style, onBlur, required, addOnIcon, withInputErrorStyle, hasError, validations, multiple, id, showHelper },
      handleSearch, handleSelect, handleDeselect, handleOnFocus,
    } = this;
    const { Option } = SelectAntd;
    const placeholderLabel = required || (validations && validations.includes('required')) ?
      <>{placeholder} <span className="mandatory-symbol">*</span></> : placeholder;
    
    return (
      <Select
        value={multiple ? values : searchTerm}
        mode={multiple ? 'multiple' : 'combobox'}
        placeholder={placeholderLabel}
        onSearch={handleSearch}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        disabled={disabled}
        style={style}
        addOnIcon={addOnIcon}
        onBlur={(!isUndefined(onBlur)) ? onBlur : null}
        hasError={hasError}
        withInputErrorStyle={withInputErrorStyle}
        showHelper={showHelper}
        getPopupContainer={getPopupContainer}
        id={id}
        onFocus={handleOnFocus}
      >
        {result.map((place, index) => <Option key={index}>{place.description}</Option>)}
        <Option key="pwgoogle" className="pwdgoogle" disabled>
          <img alt="Powered by Google" src="https://cdn-app-assets.seekube.com/public/powered_by_google_on_white_hdpi.png" />
        </Option>
      </Select>
    );
  }
}

export default GoogleMapLoader(InputGoogleMapsSuggest, {
  libraries: ['places'],
  key: process.env.FRONT_GOOGLE_MAPS_API_KEY,
});
