import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty, toString } from 'lodash';
import { Divider } from '@seekube-tech/ui-kit';
import { getAtsDefaultReviewer } from '@/store/auth/selectors';
import { RightSideCandidateSummary } from '@/components/ModalAtsImport/RightSideCandidateSummary';
import { LeftSideAtsSelection } from '@/components/ModalAtsImport/LeftSideAtsSelection';
import { usePostApplication } from '@/queries/ats/usePostApplication';
import { safeCall } from '@/helpers/safeCall';
import ModalFullSplitedView from '@/components/ModalFullSplitedView';

const ModalAtsImport = ({ syncAts, participant, authUser, visible, onClose, source, onImportFinished, context, participantOffers, offer, interactions }) => {
  const defaultReviewer = useSelector(getAtsDefaultReviewer);

  const [isError, setIsError] = useState(false);
  const interaction = participant && interactions.find((interaction) => participant._user && interaction._candidate && toString(participant._user._id) === toString(interaction._candidate._id) && !isEmpty(interaction._appointment) && (get(interaction, '_recruiter._id') === authUser._id || get(interaction, '_owner._id') === authUser._id)) || null;
  
  const { mutate: postApplication, isLoading } = usePostApplication({
    onSuccess: () => {
      setIsError(false);
      safeCall(onImportFinished);
      handleOnClose();
    },
    onError: () => {
      setIsError(true);
    },
    provider: syncAts?.provider
  });

  const handleOnClose = () => {
    onClose();
  }

  const handleImport = (atsOffer) => {
    if (!participant) {
      return false;
    }

    const requestBody = {
      participantId: participant._id,
      offerId: syncAts?.atsConnectIdField && atsOffer[syncAts.atsConnectIdField] ? atsOffer[syncAts.atsConnectIdField] : atsOffer.offerId,
      offerRef: atsOffer.atsReference || atsOffer.offerRef,
      rating: atsOffer.rating,
      syncAts,
      comment: atsOffer.comment,
      reviewer:  atsOffer.reviewer,
      sourceId: source,
      context,
      origin: window.location.href,
    };
    
    postApplication(requestBody);
  };

  return (
    <ModalFullSplitedView
      visible={visible}
      handleOnClose={handleOnClose}
    >
      <LeftSideAtsSelection
        syncAts={syncAts}
        handleClose={handleOnClose}
        handleSubmit={handleImport}
        isLoading={isLoading}
        isError={isError}
        offer={interaction?._offer}
        defaultReviewer={defaultReviewer}
        authUser={authUser}
      />
      <Divider direction='vertical' size='100%' />
      <RightSideCandidateSummary
        participant={participant}
        authUser={authUser}
        participantOffers={participantOffers}
      />
    </ModalFullSplitedView>
  );
}

export default ModalAtsImport;
