import React from "react";
import { Item } from '@seekube-tech/ui-kit';
import { object, string, func, bool } from 'prop-types';
import Avatar from '@/components/Avatar';

const SelectUserOption = ({
  opt,
  onSelect,
  disabled = false,
  id,
  active = false
}) => (
  <Item
    key={id}
    onClick={() => onSelect?.(opt)}
    disabled={disabled}
    active={active && !disabled}
  >
    <Avatar size={22} user={opt.user} src={opt.pictureUrl} />
    {opt.label}
  </Item>
)

SelectUserOption.propTypes = {
  opt: object,
  id: string,
  onSelect: func,
  disabled: bool,
  active: bool
}

export { SelectUserOption };