import React, { useState } from 'react';
import { bool, object } from 'prop-types';
import { Body1, IconEye, IconLock, IconEyeOff } from '@seekube-tech/ui-kit';
import colors from '@seekube-tech/ui-kit/dist/colors';
import { useFormatMessage } from 'react-intl-hooks';
import { Form } from 'antd';
import { isPasswordPolicyCompliant } from '@/utils/validations/password';
import InputMaterial from '@/components/Input/InputMaterial';
import styles from './styles.less';

const FormItem = Form.Item;

export const PasswordConfirmation = ({ form, withPasswordPolicy = false }) => {
  const t = useFormatMessage();
  const [passwordFieldType, setPasswordFieldType] = useState('password')

  const passwordError = form.isFieldTouched('password') && form.getFieldError('password');


  const handleOnRevealPassword = () => {
    setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password')
  };
  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(t({ id: 'event.recruiter.preparation.password.form.error' }));
    } else {
      callback();
    }
  };
  return (
    <>
      <FormItem>
        <div className={styles.passwordWrapper}>
          <button tabIndex={-1} type="button" className={styles.passwordReveal} onClick={handleOnRevealPassword}>
            {
              passwordFieldType === 'password' ?
                <IconEyeOff color={colors.primary['500']} size={16} />
                : <IconEye color={colors.primary['500']} size={16} />
            }
          </button>
          {form.getFieldDecorator('passwordConfirm', {
            rules: [
              { required: true, message: t({ id: 'form.mandatory' }) },
              { validator: (_, value) => withPasswordPolicy ? isPasswordPolicyCompliant(value) : true, message: ' '},
              {
                validator: compareToFirstPassword,
              }
            ],
            validateTrigger: 'onSubmit',
          })(
            <InputMaterial
              addOnIcon={<IconLock size={24} />}
              label={t({ id: 'form.confirmPassword' })}
              placeholder={t({ id: 'form.confirmPassword' })}
              type={passwordFieldType}
              validations={['required']}
            />
          )}
        </div>
      </FormItem>
      {withPasswordPolicy &&
        <Body1 className="mb-20" color={passwordError ? colors.error['500'] : colors.neutral['400']}>
          {t({ id: 'password.error.policy' }, { countMin: process.env.FRONT_APP_PASSWORD_MIN_LENGTH, countMax: process.env.FRONT_APP_PASSWORD_MAX_LENGTH })}
        </Body1>}
    </>
  )
}

PasswordConfirmation.propTypes = {
  form: object.isRequired,
  withPasswordPolicy: bool,
}
