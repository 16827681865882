import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import { Body1, Button, IconCopy, IconTrash2, Tag} from '@seekube-tech/ui-kit';
import moment from 'moment';
import { FormSpy } from 'react-final-form';
import { sendNotification } from '@/store/notification/actions';
import { STATUS } from '../../utils/constants';
import styles from './styles.less';
import PublishButton from '../PublishButton/PublishButton';

const FooterStand = ({
  isContextual,
  status,
  valid,
  handleOnSubmit,
  disabledFields,
  trueAuthUser,
  lastUpdate,
  setIsPreview,
  setIsModalResetOpen,
  setIsModalVersionAdminOpen,
}) => {
  const t = useFormatMessage();

  return (
    <div className={styles.footerStandContainer}>
      <div className={styles.leftContent}>
        {status === STATUS.published ? (
          <Tag size='large' className={styles.tag} color='success' >
            {t({ id: 'admin.organizations.detail.published.stand' })}
          </Tag>
        ) : (
          <Tag size='large' className={styles.tag} color='warning' >
            {t({ id: 'event.status.draft' })}
          </Tag>
        )}

        <Body1 className={styles.lastUpdateContainer}>
          <span className={styles.restoreBtn} onClick={() => trueAuthUser?.isAdmin && setIsModalVersionAdminOpen(true)}>{t({id: 'last.change.at'})}</span> : {moment(lastUpdate?.updatedAt).format('DD MMMM YYYY')} {lastUpdate?._user && t({id: 'by'})} {lastUpdate?._user?.fullName} {lastUpdate?._user?.isPlanner && `@${lastUpdate?._organization.name}`} <span className={styles.copyClipboard} onClick={() => {navigator.clipboard.writeText(lastUpdate?._user?.username);
            sendNotification({
              message: t({ id: 'copy.to.clipboard' }),
              kind: 'success',
              style: {
                bottom: '7%',
              },
            });}}><IconCopy size={16} /></span>
        </Body1>
      </div>
      <div className={styles.rightContent}>
        {isContextual &&
          <Button className={styles.resetBtn} imageComponentLeft={<IconTrash2 size={12} />} variant="text" onClick={() => setIsModalResetOpen(true)}>
            {t({ id: 'reset' })}
          </Button>
        }
        <Button className={styles.previewButton} color='neutral' variant="outline" onClick={() => setIsPreview(true)}>
          {t({ id: 'preview' })}
        </Button>
        {!valid && (
          <FormSpy
            subscription={{ values: true, modified: true, errors: true }}
            render={({ values, errors }) => (
              <Button
                onClick={() => handleOnSubmit({isPublish: false })(values, errors)}
                type="submit"
                disabled={disabledFields}
              >
                {t({ id: 'save' })}
                </Button>
            )}
          />
        )}
        {valid && (
          <FormSpy
            subscription={{ values: true, pristine: true, modified: true }}
            render={({ modified }) => (
              <PublishButton onClick={() => setModifiedInputs(modified)} disabledFields={disabledFields}/>
            )}
          />
        )}
      </div>
    </div>
  );
}

FooterStand.propTypes = {
  isContextual: bool,
  status: string,
  valid: bool,
  handleOnSubmit: func,
  disabledFields: bool,
  pristine: bool,
  trueAuthUser: object
};

export default FooterStand;
