import React, { useEffect, useState } from 'react';
import { Button as ButtonAntd, Col, Row, Select as SelectAntd } from 'antd';
import { Button, IconDownload } from '@seekube-tech/ui-kit';
import { createStructuredSelector } from 'reselect';
import { map, isEmpty, head, get } from 'lodash';
import { func, object, string } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { replace, push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { authSelectors } from '@/store/auth';
import { exponentActions, exponentSelectors } from '@/store/exponent';
import { appointmentActions } from '@/store/appointment';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { eventSelectors } from '@/store/event';
import { toJS } from '@/utils';
import { getId } from '@/utils/global';
import { dateFormat } from '@/utils/format';
import { useRequest } from '@/utils/hooks';
import { track } from '@/utils/analytics';
import { ANALYTICS_RECRUITER } from '@/utils/constants';
import { getNotification } from '@/utils/text';

import Select from '@/components/Form/Select';
import Wrapper from '@/components/Wrapper';
import { Tab } from '@/components/Tab';

import {
  StatsOffer,
  StatsLive,
  StatsRecruiters,
  StatsSourcing,
  StatsFunnelConversionParticipants,
  StatsAttendees,
  StatsAppointments,
  StatsTeamActivity,
  StatsAppointmentsStatus,
  StatsConversion,
} from '../../components';
import {
  getExportUrl, getRequestUrl,
  getStatsRequest,
} from '../../requests';
import { getInitialType } from '../../helpers';

import styles from '../../styles.less';
import messages from '../../messages';

const { Option } = SelectAntd;

const useRequestState = (requestName, params, mandatoryParams = ['eventId']) => useRequest(
  () => getStatsRequest({ requestName, ...params }),
  getRequestUrl({ requestName, ...params }),
  mandatoryParams
);

const StatsByEventScene = ({
  authUser,
  specificOrganization, // for admin
  downloadAppointments,
  push,
  location,
  intl,
}) => {
  const context = location.pathname.indexOf('/admin') > -1 ? 'admin' : 'client';
  const type = getInitialType(get(authUser, '_currentOrganization.activePlans'), context === 'admin');
  const [stats, setStats] = useState({ events: {} });
  const [eventSelected, setEventsSelected] = useState({});
  const organization = context === 'admin' ? getId(specificOrganization) : getId(authUser._currentOrganization);
  const eventId = getId(eventSelected);
  const requestParams = { organization, eventId };
  const [interview, isInterviewLoading] = useRequestState('interview', requestParams);
  const [recruiterData, isRecruiterLoading] = useRequestState('exponentUsers', requestParams);
  const [offers, isOffersLoading] = useRequestState('offers', requestParams);
  const [conversionLives, isConversionLivesLoading] = useRequestState('conversionLives', requestParams);
  const [conversionOffers, isConversionOffersLoading] = useRequestState('conversionOffers', requestParams);
  const [conversionDistribution, isConversionDistributionLoading] = useRequestState('conversionDistribution', requestParams);
  const [conversion, isConversionLoading] = useRequestState('conversion', requestParams);
  const [activities, isActivitiesLoading] = useRequestState('activities', { ...requestParams, slug: get(eventSelected, 'slug') });
  const [isLoadingAllCv, setIsLoadingAllCv] = useState(false);

  useEffect(() => {
    if (isEmpty(eventId)) {
      getStatsRequest({
        requestName: 'events', organization,
        type,
        period: {
          begin: '2020-09-01',
          end: moment().add(1, 'year').format('YYYY-MM-DD')
        }
      })
        .then((events) => {
          setStats({ events });
          setEventsSelected(head(events.docs));
        });
    }
  }, [get(eventSelected, 'name')]);

  const downloadAllCv = () => {
    setIsLoadingAllCv(true);

    const callback = () => {
      track({
        name: ANALYTICS_RECRUITER.DOWNLOADED_APPOINTMENT_PLANNING,
        user: authUser,
        event: eventSelected,
        properties: {
          owner: false,
        },
      });

      setTimeout(
        () => {
          setIsLoadingAllCv(false);
        },
        3000
      );
    };

    const notificationParams = {
      success: getNotification(<p><span role="img" aria-labelledby="">⏱️</span> {intl.formatMessage(messages.downloadRunning)} <br /> {intl.formatMessage(messages.exportAppointmentsNotif)}</p>, 'info', { emoji: ' ', withHtml: true, intl }),
      error: true,
    };

    downloadAppointments({ eventSlug: get(eventSelected, 'slug'), notificationParams, callback });

    return true;
  };


  return (
    <Wrapper type="scene">
      <div className={styles.statsContainer}>
        <Tab
          className={styles.tabs}
          defaultIndex={1}
          items={[
            { label: <FormattedMessage {...messages.toggleViewByPeriod} />, onClick: () => push('by-period') },
            { label: <FormattedMessage {...messages.toggleViewByEvent} />, onClick: () => { } },
          ]}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px' }}>
          <div style={{ flexBasis: '75%' }}>
            <Select
              defaultValue={!(isEmpty(get(stats, 'events.docs')) && get(stats, '.events.docs.0.name')) || ''}
              value={<span>{get(eventSelected, 'name')} <FormattedMessage {...messages.by} /> {`${map(get(eventSelected, 'organizations'), (orga) => orga.name).join(', ')}`}</span>}
              showSearch
              onChange={(val) => setEventsSelected(stats.events.docs.find((evt) => evt._id === val))}
              placeholder={intl.formatMessage(messages.selectEvent)}
              label={intl.formatMessage(messages.selectEvent)}
              addOnIcon="legal"
              filterOption={(input, option) => option.props.name && option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {map(stats.events.docs, (event) => (
                <Option key={event._id} value={event._id} name={`${event.name} ${map(event.organizations, (orga) => orga.name).join(', ')}`} className={styles.eventOption}>
                  {event.name} ({dateFormat(event.keyDates.jobfair.beginAt, event.keyDates.jobfair.endAt)} {moment(event.keyDates.jobfair.endAt).format('YYYY')})
                  <span className={styles.planners}> <FormattedMessage {...messages.by} /> {`${map(event.organizations, (orga) => orga.name).join(', ')}`}</span>
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <FormattedMessage tagName="h3" {...messages.recapTitle} />
          <Row gutter={20}>
            <Col span={4}>
              <StatsRecruiters
                data={recruiterData}
                loading={!isRecruiterLoading}
                statBlocProperties={{ withList: false, withAction: false, classContainer: styles.recap }}
              />
            </Col>
            <Col span={4}>
              <StatsOffer
                data={offers}
                loading={!isOffersLoading}
                statBlocProperties={{ withList: false, withAction: false, title: <FormattedMessage {...messages.offerPublished} />, classContainer: styles.recap }}
              />
            </Col>
            <Col span={4}>
              <StatsLive
                data={conversionLives}
                loading={!isConversionLivesLoading}
                statBlocProperties={{ withList: false, withAction: false, title: <FormattedMessage {...messages.livePublished} />, classContainer: styles.recap }}
              />
            </Col>
          </Row>
          <FormattedMessage tagName="h3" {...messages.funnelConversionTitle} />
          <Row gutter={20}>
            <Col span={12}>
              <StatsFunnelConversionParticipants
                {...conversion}
                loading={!isConversionLoading}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={4}>
              <StatsAppointmentsStatus
                data={conversionDistribution}
                loading={!isConversionDistributionLoading}
                event={eventSelected}
              />
            </Col>
            <Col span={8}>
              <StatsConversion
                conversionLives={conversionLives}
                conversionOffers={conversionOffers}
                isLoading={!(isConversionLivesLoading && isConversionOffersLoading)}
                onActionClick={(requestName) => getExportUrl({ requestName, organization, eventId })}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <StatsSourcing {...conversion} />
            </Col>
            <Col span={6}>
              <StatsAttendees {...conversionDistribution} />
            </Col>
          </Row>
          <FormattedMessage tagName="h3" {...messages.interviewTitle} values={{ count: 2 }} />
          <Row gutter={20}>
            <Col span={12}>
              <StatsAppointments
                {...interview}
                loading={!isInterviewLoading}
              />
            </Col>
          </Row>
          <div className={styles.statsWithBtn}>
            <FormattedMessage tagName="h3" {...messages.activityTitle} />
            <div className={styles.exportAppointments}>
              <Button
                variant="outline"
                color="neutral"
                imageComponentLeft={<IconDownload size={16} />}
                onClick={downloadAllCv}
                loading={isLoadingAllCv}
              >
                <FormattedMessage id="client.stats.byjobdating.downloadPlanning" />
              </Button>
            </div>
          </div>
          <StatsTeamActivity
            authUser={authUser}
            activities={activities}
            loading={!isActivitiesLoading}
          />
        </div>
      </div>
    </Wrapper>
  );
};

StatsByEventScene.propTypes = {
  authUser: object,
  location: object,
  intl: object,
  specificOrganization: object,
  push: func,
  downloadAppointments: func,
};

const mapStateToProps = createStructuredSelector({
  authUser: authSelectors.getAuthUser,
  exponent: exponentSelectors.getCurrentExponent,
  organization: organizationSelectors.getCurrentOrganization,
  specificOrganization: organizationSelectors.getSpecificOrganization,

  event: eventSelectors.getCurrentEvent,
});

const mapDispatchToProps = {
  patchOrganization: organizationActions.patchOrganization,
  patchExponent: exponentActions.patchExponent,
  getExponentByOrganization: exponentActions.getExponentByOrganization,
  resetAlreadyExist: organizationActions.resetAlreadyExist,
  downloadAppointments: appointmentActions.downloadAppointments,
  replace,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
  toJS,
)(StatsByEventScene);

