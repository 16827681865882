export const MAX_CHARACTER = 35;

export const sliceLongText = (text) => text?.length > MAX_CHARACTER ? `${text.slice(0, MAX_CHARACTER)}...` : text || '';

export const formatOfferTitle = (offer) => {
  if (!offer) {
    return null;
  }
  
  if (offer.contract_type) {
    if (offer.reference) {
      return `[${offer.contract_type}] ${offer.reference} - ${offer.title || offer.name}`;
    }

    return `[${offer.contract_type}] ${offer.title || offer.name}`;
  }

  return offer.name || offer.title;
}