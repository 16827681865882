import React, { useEffect } from 'react';
import { any, func, object } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { toJS } from '@/utils';
import { authSelectors } from '@/store/auth';
import { LOCAL_STORAGE } from '@/utils/localStorage';

const defaultParams = {
  isAdmin: false,
};

export const withAuth = (params) => (WrappedComponent) => {
  const mapStateToProps = createStructuredSelector({
    authUser: authSelectors.getAuthUser
  });

  const authParams = {
    ...defaultParams,
    ...params,
  };

  const mapDispatchToProps = {
    goTo: replace,
  };

  const RequiredAuth = (props) => {
    const { authUser, goTo } = props;

    useEffect(() => {
      const isSessionActive = localStorage.getItem(LOCAL_STORAGE.global.isConnected);

      if (isEmpty(isSessionActive) || isSessionActive === "false") {
        return goTo('/auth/logout');
      }

      if (authParams.isAdmin && !authUser.isAdmin) {
        goTo('/auth/login');
      }
    }, []);

    return (<WrappedComponent {...props} />);
  };

  RequiredAuth.propTypes = {
    authUser: object,
    authParams: object,
    goTo: func,
    location: object,
    component: any,
  };

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  return compose(withConnect)(toJS(RequiredAuth));
};
